import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { PlanningRoute } from 'src/app/core/interfaces/planning-route';
import { RouteBasicCity } from 'src/app/core/interfaces/route-basic-city';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { RouteMessages } from 'src/app/core/messages/route-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CityLocationFormComponent } from '../planning-form/city-location-form/city-location-form.component';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { RouteItinerary } from 'src/app/core/interfaces/route-itinerary';
import { GoogleService } from 'src/app/core/services/google.service';
import { LocationAddress } from 'src/app/core/interfaces/locationAddress';

@Component({
  selector: 'app-route-plan-form',
  templateUrl: './route-plan-form.component.html',
  styleUrls: ['./route-plan-form.component.scss']
})
export class RoutePlanFormComponent implements OnInit {

  originControl: FormControl = new FormControl('', [Validators.required]);
  originSub: Subscription;
  originOptions: OptionsAutocomplete = { title: "Ciudad de origen", appearance: "outline", requireFullCity: true };
  originCity: RouteBasicCity = null;

  destinationControl: FormControl = new FormControl('', [Validators.required]);
  destinationSub: Subscription;
  destinationOptions: OptionsAutocomplete = { title: "Ciudad de destino", appearance: "outline", requireFullCity: true };
  destinationCity: RouteBasicCity = null;

  validate = '';

  constructor(
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<RoutePlanFormComponent>,
    public dialog: MatDialog,
    public utils: Utils,
    private snackBarService: SnackBarService,
    private planningRouteService: PlanningRouteService,
    private googleService: GoogleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.setSubscription();
  }

  private setSubscription() {
    this.originSub = this.originControl.valueChanges.subscribe(city => {
      if (city && city.name && city.id && (!this.originCity || this.originCity.id !== city.id)) {
        this.originCity = city;
        this.checkHasLocation(this.originCity, 'origin');
      } else if (this.originCity && this.originCity.id && (!city || !city.name))
        this.originCity = null;
    })
    this.destinationSub = this.destinationControl.valueChanges.subscribe(city => {
      if (city && city.name && city.id && (!this.destinationCity || this.destinationCity.id !== city.id)) {
        this.destinationCity = city;
        this.checkHasLocation(this.destinationCity, 'destination');
      } else if (this.destinationCity && this.destinationCity.id && (!city || !city.name))
        this.destinationCity = null;
    })
  }

  private async checkHasLocation(city: RouteBasicCity, type: 'origin' | 'destination'): Promise<boolean> {
    if (city && city.id && city.location) return true;
    const config = new MatDialogConfig();
    config.data = { city };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;

    const result = await this.dialog.open(CityLocationFormComponent, config).afterClosed().toPromise();
    if (!result) {
      this.snackBarService.openSnackBar(RouteMessages.MUST_SELECT_CITY, undefined, 'error');
      if (type === 'origin') this.originOptions = { title: "Ciudad de origen", appearance: "outline", initialValue: "", requireFullCity: true };
      else this.destinationOptions = { title: "Ciudad de destino", appearance: "outline", initialValue: "", requireFullCity: true };
      return false;
    }
    try {
      const r: RouteBasicCity = await this.planningRouteService.updateCity(result).toPromise();
      if (!r || !r.location) {
        this.snackBarService.openSnackBar(RouteMessages.ERROR_SELECT_CITY, undefined, 'error');
        return false;
      }
      this.snackBarService.openSnackBar(RouteMessages.SUCCESS_SELECT_CITY);
      if (type === 'origin') this.originCity['location'] = r.location;
      else this.destinationCity['location'] = r.location;
      return true;
    } catch (error) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      return false;
    }
  }

  private async checkExistence(origin: RouteBasicCity, destination: RouteBasicCity): Promise<boolean> {
    try {
      const routes: PlanningRoute[] = await this.planningRouteService.getRoutes(origin.name, destination.name).toPromise();
      if (!routes || !routes.length) return true;
      const routeId = routes[0].id;
      const config = new MatDialogConfig();
      config.data = {
        title: `Ya existe una ruta con origen ${origin.name} y destino ${destination.name}`,
        description: `Seleccione confirmar si desea ser redirigido a esa ruta en específico, de lo contrario cambie sea el origen o el destino`
      };
      config.maxHeight = ModalEnum.MAX_HEIGHT;
      config.width = ModalEnum.SMALL_WIDTH;
      config.maxWidth = ModalEnum.MAX_WIDTH;
      config.autoFocus = false;
      const result = await this.dialog.open(DialogComponent, config).afterClosed().toPromise();
      if (result && result.state) {
        this.router.navigate(['routes', 'planning', 'form', routeId]);
        this.dialogRef.close();
      }
      return false;
    } catch (error) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      return false;
    }
  }

  private async getItinerary(): Promise<RouteItinerary | string> {
    try {
      const locations: LocationAddress[] = [];
      locations.push(this.originCity.location);
      locations.push(this.destinationCity.location);
      const data = await this.googleService.getRouteData(locations, 'DRIVING', true) as { cargoDistancy: number, cargoEstimatedTime: number, cargoRoute: google.maps.DirectionsResult };
      if (!data || !data.cargoDistancy || !data.cargoEstimatedTime || !data.cargoRoute)
        return "No fue posible crear el plan de ruta";

      const render = new google.maps.DirectionsRenderer();
      render.setDirections(data.cargoRoute);

      const itinerary: RouteItinerary = {
        name: `Itinerario base`,
        overviewPolylines: render.getDirections().routes[0].overview_polyline,
        estimatedTime: data.cargoEstimatedTime,
        estimatedDistance: data.cargoDistancy,
        mustSleep: data.cargoEstimatedTime > (14 * 60 * 60),
        originPoint: {
          id: this.originCity.id,
          name: this.originCity.name,
          location: this.originCity.location
        },
        destinationPoint: {
          id: this.destinationCity.id,
          name: this.destinationCity.name,
          location: this.destinationCity.location
        },
        authorizedStops: [],
        controlPoints: []
      };

      return itinerary;
    } catch (e) {
      console.error(e)
      return "No fue posible crear el plan de ruta de este servicio";
    }
  }

  async createRoute() {
    this.validate = 'touched';
    if (!this.originCity || !this.originCity.name || !this.originCity.id)
      return this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'ciudad de origen'), undefined, 'alert');
    const originLocation = await this.checkHasLocation(this.originCity, 'origin');
    if (!originLocation) return;
    const originExistence = await this.checkExistence(this.originCity, this.destinationCity);
    if (!originExistence) return;

    if (!this.destinationCity || !this.destinationCity.name || !this.destinationCity.id)
      return this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'ciudad de destino'), undefined, 'alert');
    const destinationLocation = await this.checkHasLocation(this.destinationCity, 'destination');
    if (!destinationLocation) return;
    const destinationExistence = await this.checkExistence(this.originCity, this.destinationCity);
    if (!destinationExistence) return;

    const itinerary = await this.getItinerary();
    if (typeof itinerary === 'string') return this.snackBarService.openSnackBar(itinerary, undefined, 'error');

    this.spinner.show();
    this.planningRouteService.createRouteItinerary(itinerary).subscribe(
      (result: RouteItinerary) => {
        this.spinner.hide();
        if (result && result.id) {
          this.snackBarService.openSnackBar(RouteMessages.SUCCESS_CREATE_ROUTE);
          this.goToCreatedRoute(this.originCity, this.destinationCity);
        } else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }, (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }

  private async goToCreatedRoute(origin: RouteBasicCity, destination: RouteBasicCity) {
    try {
      const routes: PlanningRoute[] = await this.planningRouteService.getRoutes(origin.name, destination.name).toPromise();
      if (routes && routes.length) {
        const routeId = routes[0].id;
        this.router.navigate(['routes', 'planning', 'form', routeId]);
        this.dialogRef.close();
      }
      else this.dialogRef.close({ state: true });
    } catch (error) {
      this.dialogRef.close({ state: true });
    }
  }

}
