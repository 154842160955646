<div id="mainFreightItinerary" class="col-md-12 mb-md-0 mb-12">
  <div class="row h-100">

    <mat-tab-group class="tab-group" (selectedIndexChange)="onSelectTab($event)" permission-tab
      [permissionModule]="permission.cargo.module" [permissionListName]="permissions">
      <mat-tab [label]="tabs[0].alias">
        <app-upload-download-map-freight></app-upload-download-map-freight>
      </mat-tab>
      <mat-tab [label]="tabs[1].alias">
        <app-create-cargo>
        </app-create-cargo>
      </mat-tab>
      <!--mat-tab [label]="tabs[2].alias">
        <app-create-cargo-from-guides>
        </app-create-cargo-from-guides>
      </mat-tab-->
      <mat-tab [label]="tabs[2].alias">
        <app-mass-creation-cargo></app-mass-creation-cargo>
      </mat-tab>
      <mat-tab [label]="tabs[3].alias">
        <app-upload-route></app-upload-route>
      </mat-tab>
      <mat-tab [label]="tabs[4].alias">
        <app-create-escort-service></app-create-escort-service>
      </mat-tab>
      <!-- <mat-tab label="Últimas solicitudes">
        <app-cargo-list *ngIf="showListLatestRequests" [typeList]="'latestLoads'">
          </app-cargo-list>
      </mat-tab> -->
    </mat-tab-group>

  </div>
</div>