import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AddressCargo } from 'src/app/core/interfaces/addressCargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { Destination } from 'src/app/core/interfaces/destination';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Utils } from 'src/app/core/resources/utils';
import { Driver } from "src/app/core/models/driver";
import { Permission } from 'src/app/core/resources/permission';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
@Component({
  selector: 'app-cargo-tracking-states-history',
  templateUrl: './cargo-tracking-states-history.component.html',
  styleUrls: ['./cargo-tracking-states-history.component.scss']
})
export class CargoTrackingStatesHistoryComponent {
  @Input() driver: Driver;
  @Input() vehicle: Vehicle;
  @Input() cargo: Cargo;
  @Input() historyNoveltyPoints = [];
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  public showDetail: boolean = false;
  typeTrackingValue: FormControl = new FormControl('Todos');
  typesTracking: string[] = ['Todos', 'Gps', 'Manuales'];
  noveltyUsers = [
    { value: "MANUAL_POINTS", alias: "Reportes Manuales", icon: "../../../../assets/svg/icons/icon-correct.svg", checked: true },
    { value: "SATRACK", alias: "Satrack", icon: "../../../../assets/svg/icons/satrack_logo.png", checked: true },
  ]
  permission = Permission;
  filteredHistoryNoveltyPoints = [];
  constructor(
    public utils: Utils
  ) { }

  ngOnChanges(changes: SimpleChanges){
    if (changes && changes.historyNoveltyPoints && changes.historyNoveltyPoints.currentValue && this.historyNoveltyPoints && this.historyNoveltyPoints.length) this.filteredHistoryNoveltyPoints = this.historyNoveltyPoints;
    
  }

  onSelectionChange($event: MatSelectChange){
    if (!$event || !$event.value) return;
    const isGps = $event.value === 'Gps';
    const isManual = $event.value === 'Manuales';
    this.filteredHistoryNoveltyPoints = this.historyNoveltyPoints.filter(historyNoveltyPoint => {
      const userId = this.utils.getNestedValue(historyNoveltyPoint, 'data.fingerprint.userId');
      if (!userId) return false;
      if (isGps) return userId === 'SATRACK';
      if (isManual) return userId !== 'SATRACK';
      return true; 
    });
  }

  public get listOrigins(): AddressCargo[] {
    if (
      this.utils.isDefined(this.cargo) &&
      this.utils.isDefined(this.cargo.cargoFeature) &&
      this.utils.isDefined(this.cargo.cargoFeature.uploadDownload) &&
      this.utils.isDefined(this.cargo.cargoFeature.uploadDownload.origin) &&
      this.utils.isDefined(this.cargo.cargoFeature.uploadDownload.origin.addresses)
    ) {
      return this.cargo.cargoFeature.uploadDownload.origin.addresses;
    } else {
      return [];
    }
  }

  public get listDestinations(): Destination[] {
    if (
      this.utils.isDefined(this.cargo) &&
      this.utils.isDefined(this.cargo.cargoFeature) &&
      this.utils.isDefined(this.cargo.cargoFeature.uploadDownload) &&
      this.utils.isDefined(this.cargo.cargoFeature.uploadDownload.destination)
    ) {
      return this.cargo.cargoFeature.uploadDownload.destination;
    } else {
      return [];
    }
  }

  get isEscortedService(): boolean {
    const serviceType: ServiceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }

  /**
  * This method show the icon according to the state of the address.
  * @param {AddressCargo} address (AddressCargo) is the address to modify.
  * @returns {boolean} (boolean) return if show or hide the icon.
  */
  public showIconByAddress(address: AddressCargo): boolean {
    // llego a recoger - "Arrived to pickup cargo"
    // servicio recogido - "Pickup load"
    // llego a entregar - "Destination arrived"
    // entregó servicio - "Cargo unloaded"
    let showIcon = false;

    switch (address.state) {
      case "Pickup load":
      case "Cargo unloaded":
        showIcon = true;
        break;
    }

    return showIcon;
  }
  /**
  * This method set the status message of the address.
  * @param {AddressCargo} address (AddressCargo) is the address that needs to set the status message.
  * @returns {string} (string) returns the status message of the address.
  */
  public getDataByAddress(address: AddressCargo): string {
    // llego a recoger - "Arrived to pickup cargo"
    // servicio recogido - "Pickup load"
    // llego a entregar - "Destination arrived"
    // entregó servicio - "Cargo unloaded"
    let statusString = this.isEscortedService ? "El escolta está en camino" : "El conductor está en camino";

    switch (address.state) {
      case "Arrived to pickup cargo":
        statusString = this.isEscortedService ? "El escolta llegó al punto de origen" : "El conductor llegó a recoger el servicio";
        break;
      case "Pickup load":
        statusString = this.isEscortedService ? "El escolta salió del punto de origen" : "El conductor ha recogido el servicio";
        break;
      case "Destination arrived":
        statusString = this.isEscortedService ? "El escolta llegó al punto de destino" : "El conductor llegó a entregar el servicio";
        break;
      case "Cargo unloaded":
        statusString = this.isEscortedService ? "El escolta terminó el servicio" : "El conductor ha entregado el servicio";
        break;
    }

    return statusString;
  }
  /**
  * This method emits to parent actions according to the event that arrives in the parameters.
  * @param {Cargo} $event (Cargo) is the information of the associate load.
  */
  public onLoadedEvidences($event: Cargo): void {
    if ($event && $event.id) {
      this.emitToParent.emit({
        name: 'refreshCargoData',
        data: $event
      });
    } else {
      this.emitToParent.emit({
        name: 'getCargoDetail',
        data: this.cargo.consecutive.toString()
      });
    }
  }

  get isSomeNoveltyUserPoint(): boolean {
    let filteredArray = [...this.noveltyUsers];
    filteredArray.shift();
    return this.historyNoveltyPoints && !!this.historyNoveltyPoints.length &&
      this.historyNoveltyPoints.some(novelty =>
        novelty.data && novelty.data.fingerprint && novelty.data.fingerprint.userName
        && filteredArray.some(userName => userName.value === novelty.data.fingerprint.userName)
      )
  }
  /**
  * This method check if the user have the checked option to visualize the checkbox options of tracking.
  * @param {string} userName (string) is the user that wants to consult.
  * @returns {boolean} (boolean) returns if the user is checked or not.
  */
  isUserNameChecked(userName: string): boolean {
    let filteredArray = [...this.noveltyUsers];
    filteredArray.shift();
    if (filteredArray.some(noveltyUser => noveltyUser.value === userName)) {
      let noveltyUser = filteredArray.filter(noveltyUser => noveltyUser.value === userName)
      return !!noveltyUser[0] && noveltyUser[0].checked;
    }
    return this.noveltyUsers[0].checked;
  }
  /**
  * This method set the icon shown according to the match of the username with the novelty user.
  * @param {string} userName (string) is the user to compare.
  * @returns {string} (string) returns the novelty icon.
  */
  getNoveltyIcon(userName: string): string {
    let filteredArray = [...this.noveltyUsers];
    filteredArray.shift();
    if (filteredArray.some(noveltyUser => noveltyUser.value === userName)) {
      let noveltyUser = filteredArray.filter(noveltyUser => noveltyUser.value === userName)
      if (!!noveltyUser[0]) return noveltyUser[0].icon;
    }
    return this.noveltyUsers[0].icon;
  }

  public get enableStateTrackingAddresses() {
    return this.cargo && this.cargo.cargoFeature && this.cargo.cargoFeature.uploadDownload && this.cargo.cargoFeature.uploadDownload.origin && this.cargo.cargoFeature.uploadDownload.origin.addresses && this.cargo.cargoFeature.uploadDownload.origin.addresses.length && this.cargo.cargoFeature.uploadDownload.origin.addresses.every(address => address.state && address.state === 'Pickup load') && !this.cargo.startTripDate;
  }
}
