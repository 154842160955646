import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Guide } from 'src/app/core/interfaces/guide';
import { environment } from './../../../../environments/environment';
import { Utils } from 'src/app/core/resources/utils';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GenerateGuideService {

    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints,
        public utils: Utils
    ) {

    }

    getAllGuidesInCollection() {
        return this._http.get<Guide[]>(
            environment.urlServerTeclogi + this._endpointResources.getGuides + 'state=Created'
        );
    }

    getAllGuidesInDistribution() {
        return this._http.get<Guide[]>(
            environment.urlServerTeclogi + this._endpointResources.getGuides + 'state=In warehouse'
        );
    }

    getGuides(pageKey: number, pageSize: number, guideId?: string, cargoId?: string, companyId?: string): Observable<BasicGuide[]> {
        let params = new HttpParams();
        let url = environment.urlServerTeclogi + this._endpointResources.getGuides;

        if (!this.utils.isEmpty(pageKey))
            params = params.append('pageKey', pageKey.toString());
        if (!this.utils.isEmpty(pageSize))
            params = params.append('pageSize', pageSize.toString());
        if (!this.utils.isEmpty(guideId))
            params = params.append('guideId', guideId);
        if (!this.utils.isEmpty(cargoId))
            params = params.append('cargoConsecutive', cargoId);
        if (!this.utils.isEmpty(companyId))
            params = params.append('companyId', companyId);

        return this._http.get<BasicGuide[]>(url, { params });
    }


    getGuidesSelected(guides) {
        return _.filter(guides, function (g) {
            return g.checked >= true;
        });
    }

}
