import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { VehiclesService } from '../list-vehicles.service';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { gpsType } from 'src/app/core/interfaces/gpsType';
import { SelectGpsService } from 'src/app/shared/select-gps/select-gps.service';

@Component({
  selector: 'app-create-gps-vehicle',
  templateUrl: './create-gps-vehicle.component.html',
  styleUrls: ['./create-gps-vehicle.component.scss']
})

export class CreateGpsVehicleComponent {
  gpsList: gpsType[] = [];
  reactiveForm: ReactiveForm;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  public arrayGPS: { name: string, id: string }[] = [];

  GPSForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    id: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateGpsVehicleComponent>,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private vehiclesService: VehiclesService,
    private snackBarService: SnackBarService,
    public formBuilder: FormBuilder,
    private gpsService: SelectGpsService,
  ) { }

  /**
   * @description Validates if name or id already exists in gpsList
   * @param control Form control to validate
   * @returns ValidationErrors or null
   */
  private validateGpsExists = (validate: 'id' | 'name'): AsyncValidatorFn => {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise(resolve => {
        if (!control.value || !control.value.trim())
          return resolve(null);
        if (!this.gpsList || !this.gpsList.length)
          return resolve(null);

        const value = control.value.toLowerCase().trim();
        const gpsExists = this.gpsList.some(gps => gps && gps[validate] && gps[validate].toLowerCase().trim() === value);
        if (gpsExists) return resolve({ gpsExists: true });
        else return resolve(null);
      });
    };
  }

  ngOnInit() {
    this.getListGpsTypes();
    this.GPSForm.get('name').setAsyncValidators(this.validateGpsExists('name'));
    this.GPSForm.get('id').setAsyncValidators(this.validateGpsExists('id'));
  }

  /**
  * @description Gets the list of GPS types
  */
  private getListGpsTypes() {
    this.gpsService.getAllTypesGps().subscribe(
      (success: any) => {
        if (success && success.catalog && success.catalog.length)
          this.gpsList = success.catalog;
      },
      (error) => { }
    );
  }

  /**
  * @description Verifies if the GPSForm is valid to push a new GPS it into arrayGPS
  */
  public addGPS(): void {
    this.GPSForm.markAllAsTouched();
    if ((this.GPSForm.get('name').errors && this.GPSForm.get('name').errors['gpsExists'])
      || (this.GPSForm.get('id').errors && this.GPSForm.get('id').errors['gpsExists']))
      return this.snackBarService.openSnackBar('El GPS ya existe, no es posible añadirlo nuevamente', undefined, 'alert');

    this.GPSForm.controls['name'].setValue(this.GPSForm.controls['name'].value.trim());
    this.GPSForm.controls['id'].setValue(this.GPSForm.controls['id'].value.trim());
    if (this.utils.errorMessagesCustomized(this.GPSForm.get('name'), 'nombre')) return;
    if (this.utils.errorMessagesCustomized(this.GPSForm.get('id'), 'url')) return;

    this.arrayGPS.push(this.GPSForm.value);
    this.GPSForm.controls['name'].setValue('');
    this.GPSForm.controls['name'].markAsUntouched();
    this.GPSForm.controls['id'].setValue('');
    this.GPSForm.controls['id'].markAsUntouched();
    this.GPSForm.reset();
  }

  /**
  * @param {number} index is the index of the GPS to delete of arrayGPS 
  * @description Deletes a GPS from arrayGPS
  */
  public deleteGPS(index: number) {
    this.arrayGPS.splice(index, 1);
  }

  /**
  * @description Creates the list of GPS added on arrayGPS and closes the dialog if the process is success
  */
  public onSubmit() {
    if (this.arrayGPS.length > 0) {
      this.spinner.show();
      this.vehiclesService.createGPS(this.arrayGPS).toPromise()
        .then((success: BasicResponse) => {
          this.emitToParent.emit();
          this.snackBarService.openSnackBar('GPS creado correctamente');
          this.dialogRef.close({ state: true });
          this.spinner.hide();
        })
        .catch((error) => {
          console.error(error)
          this.snackBarService.openSnackBar('Ocurrió un error al crear el GPS', undefined, 'error');
        })
        .finally(() => {
          this.spinner.hide();
        });
    } else {
      this.snackBarService.openSnackBar('Debe añadir como minimo un GPS', undefined, 'alert');
    }
  }
}
