export class Companies {

  static companies = [
    {
      "businessName": "TECLOGI",
      "commonName": "TECLOGI",
      "nit": "9013312320"
    },
    {
      "businessName": "BULKMATIC DE COLOMBIA S.A.S",
      "commonName": "BULKMATIC",
      "nit": "9009704395"
    },
    {
      "businessName": "INVERTRAICO S.A.S",
      "commonName": "INVERTRAICO",
      "nit": "9011981505"
    },
    {
      "businessName": "JL & RB S.A.S",
      "commonName": "JL & RB",
      "nit": "9007387182"
    },
    {
      "businessName": "GRUPO NUTRESA",
      "commonName": "GRUPO NUTRESA",
      "nit": "9003410860"
    },
    {
      "businessName": "CARGA PÁRAMO S.A.S",
      "commonName": "CARGA PÁRAMO",
      "nit": "9014171480"
    },
    {
      "businessName": "TRANSPORTE LA ESTRELLA SAS",
      "commonName": "TRANSPORTE LA ESTRELLA",
      "nit": "8000130291"
    },
    {
      "businessName": "SEGURIDAD EXTREMA LTDA",
      "commonName": "SEGURIDAD EXTREMA",
      "nit": "9013522401"
    },
    {
      "businessName": "TRANSALUD",
      "commonName": "TRANSALUD",
      "nit": "9012861321"
    },
    {
      "businessName": "LOGISTICA DE TRANSPORTES CARRILLO PAEZ SAS",
      "commonName": "TRANSPORTES CARRILLO",
      "nit": "9011282346"
    },
    {
      "businessName": "OPPERAR COLOMBIA S.A.S.",
      "commonName": "OPPERAR COLOMBIA",
      "nit": "9007435921"
    },
    {
      "businessName": "GRUPO OPERATIVO LOGISTICA Y TRANSPORTE S.A.S.",
      "commonName": "GRUPO GOLTI SAS",
      "nit": "9016235289"
    },
    {
      "businessName": "HURGO TRANSPORTE Y LOGISTICA SAS",
      "commonName": "HURGO TRANSPORTE",
      "nit": "9009583341"
    },
  ];

  static companiesWithDisabledRegistration = [
    "8000130291"
  ];

  static companiesNIT = {
    LOGGIAPP: "default",
    TECLOGI: "9013312320",
    BULKMATIC: "9009704395",
    INVERTRAICO: "9011981505",
    JL_RB: "9007387182",
    NUTRESA: "9003410860",
    PARAMO: "9014171480",
    LA_ESTRELLA: "8000130291",
    SEGURIDAD_EXTREMA: "9013522401",
    TRANSALUD: "9012861321",
    TRANSPORTES_CARRILLO: "9011282346",
    OPPERAR_COLOMBIA: "9007435921",
    GRUPO_GOLTI: "9016235289",
    HURGO_TRANSPORTE: "9009583341",
  };

  static analyticsAccess = (clientNit: string, saasNit: string) => {
    //Si se indica que lo incluye saasNit está disponible para esa SaaS y sus clientes
    //Si se indica que lo incluye clientNit está disponible solo para la SaaS, no sus clientes.
    return {
      analyticsOperations: [
        Companies.companiesNIT.TECLOGI,
        Companies.companiesNIT.BULKMATIC,
        Companies.companiesNIT.INVERTRAICO,
        Companies.companiesNIT.SEGURIDAD_EXTREMA,
        Companies.companiesNIT.LA_ESTRELLA,
        Companies.companiesNIT.TRANSPORTES_CARRILLO,
        Companies.companiesNIT.OPPERAR_COLOMBIA,
        Companies.companiesNIT.HURGO_TRANSPORTE
      ].includes(saasNit),
      vehicleAnalytics: [Companies.companiesNIT.TECLOGI].includes(clientNit),
      dispatch: [Companies.companiesNIT.TECLOGI].includes(clientNit),
      frequentRoutes: [Companies.companiesNIT.TECLOGI, Companies.companiesNIT.BULKMATIC].includes(saasNit),
      stopedCargos: [Companies.companiesNIT.TECLOGI, Companies.companiesNIT.BULKMATIC].includes(saasNit),
      analyticsBilling: [Companies.companiesNIT.TECLOGI, Companies.companiesNIT.BULKMATIC].includes(clientNit),
      operationITR: [Companies.companiesNIT.TECLOGI, Companies.companiesNIT.BULKMATIC].includes(clientNit),
      historicalKPI: [Companies.companiesNIT.TECLOGI, Companies.companiesNIT.BULKMATIC].includes(saasNit),
      comercialTurn: [Companies.companiesNIT.TECLOGI].includes(clientNit),
      operationsTurn: [
        Companies.companiesNIT.TECLOGI,
        Companies.companiesNIT.BULKMATIC,
        Companies.companiesNIT.INVERTRAICO,
        Companies.companiesNIT.SEGURIDAD_EXTREMA,
        Companies.companiesNIT.LA_ESTRELLA,
        Companies.companiesNIT.TRANSPORTES_CARRILLO,
        Companies.companiesNIT.OPPERAR_COLOMBIA,
        Companies.companiesNIT.HURGO_TRANSPORTE
      ].includes(clientNit),
    };
  }

  static onlyTransportServicesPermissions = [
    '/administration/adminTrailers',
    '/administration/modifyAdditionalCostBilled',
    '/administration/modifyAdditionalCostpayed',
    '/administration/addAdditionalServiceCargoPaid',
    '/administration/addAdditionalServiceCargoCashed',
    '/administration/addAdditionalServiceCargoApproved',
    '/cargo/documentManifest',
    '/cargo/documentManifestUrban',
    '/cargo/documentConsignment',
    '/cargo/documentConsignmentUrban',
    '/cargo/readManifest',
    '/cargo/additionalCosts',
    '/cargo/additionalCostCreate',
    '/cargo/additionalCostUpdate',
    '/cargo/additionalCostDelete',
    '/cargo/confirmationAdditionalMandatoryServices',
    '/cargo/additionalServicesWithoutUtilityRestriction',
    '/cargo/uploadExternalManifest',
    '/cargo/createCargoFromRequest',
    '/cargo/createCargoFromRoute',
    '/cargo/createMassiveCargo',
    '/cargo/createCargoByGuides',
    '/cargo/createManualCargo',
    '/cargo/showManifestError',
    '/cargo/editWeightConsignment',
    '/cargo/editCargoAmount',
    '/cargo/createCargosWithoutMinimumUtility',
    '/cargo/approvedBelowSicetac',
    '/cargo/showEditServiceNegotiationModule',
    '/cargo/showEditRequestNegotiationModule',
    '/cargo/createService',
    '/cargo/skipReteicaValidation',
    '/cargo/createServicesWithExpiredPolicy',
    '/cargo/automaticTotalCostCalculation',
    '/cargo/createCargoRequest',
    '/cargo/changeManifestRequirement',
    '/cargo/cargoRequest',
    '/cargo/cargoRequestNegotiation',
    '/cargo/createRequestFromManualCreationCargo',
    '/cargo/serviceRequests',
    '/cargo/createTransportRequests',
    '/cargo/listRequestsAndApplications',
    '/cargo/generateManifest',
    '/setting/termsManifest',
  ]

  static escortServicesPermissions = [
    '/cargo/createEscortService',
    '/administration/useGpsPortable',
    '/administration/alertValidGps',
  ]


  static companiesAccess(route: string, clientNit: string, saasNit: string): boolean {
    if (route && route.includes('/analytics/'))
      return !!this.analyticsAccess(clientNit, saasNit)[route.split("/analytics/")[1]];
    if (route && this.onlyTransportServicesPermissions.includes(route))
      return ![this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);
    if (route && this.escortServicesPermissions.includes(route))
      return [this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);
    switch (route) {
      case '/administration/companies/config':
        //Solo usuarios de las empresas SaaS, no sus clientes
        return this.companies.some(company => company.nit === clientNit);
      default:
        return true;
    }
  }

  static permissionAccess(permission: string, clientNit: string, saasNit: string): boolean {
    if (permission && permission in this.analyticsAccess(clientNit, saasNit))
      return !!this.analyticsAccess(clientNit, saasNit)[permission];
    if (permission && this.onlyTransportServicesPermissions.some(route => route.includes(permission)))
      return ![this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);
    if (permission && this.escortServicesPermissions.some(route => route.includes(permission)))
      return [this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);

    return true;
  }

  static modulesAccess(module: string, clientNit: string, saasNit: string): boolean {
    switch (module) {
      case 'point':
        return false;
      case 'routing':
        //Empresas SaaS que no tengan servicios de escolta
        return ![this.companiesNIT.SEGURIDAD_EXTREMA].includes(saasNit);
      default:
        return true;
    }
  }

}
