import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Guide } from 'src/app/core/interfaces/guide';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';
import { Global } from 'src/app/core/resources/global';

@Component({
  selector: 'app-item-cargo-from-guides',
  templateUrl: './item-cargo-from-guides.component.html',
  styleUrls: ['./item-cargo-from-guides.component.scss'],
  providers: [AuthService]
})
export class ItemCargoFromGuidesComponent {
  @Input() guide: BasicGuide;

  constructor(public global: Global) { }

  getTitleAlias(title: string): string {
    if (this.global.statusGuide[title])
      return this.global.statusGuide[title];
    return title;
  }
}
