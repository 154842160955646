import { Inject, Input } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrackGuide } from 'src/app/core/interfaces/trackGuide';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoEvidenceComponent } from 'src/app/modules/cargo/cargo-evidence/cargo-evidence.component';
import { TrackGuideService } from '../track-guide-services';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from 'src/app/core/resources/global';

@Component({
  selector: 'app-track-guide-detail',
  templateUrl: './track-guide-detail.component.html',
  styleUrls: ['./track-guide-detail.component.scss']
})
export class TrackGuideDetailComponent {

  @Input() guide: BasicGuide;
  @Input() isDialog: boolean;

  constructor(
    public dialog: MatDialog,
    public global: Global,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      guide?: BasicGuide
    },
    public dialogRef: MatDialogRef<TrackGuideDetailComponent>,
    public utils: Utils
  ) {
    if (this.dialogParams && this.dialogParams.guide)
      this.guide = this.dialogParams.guide;
  }

  getTitleAlias(title: string): string {
    if (this.global.statusGuide[title])
      return this.global.statusGuide[title];
    return title;
  }
}
