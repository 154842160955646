import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrackGuide } from 'src/app/core/interfaces/trackGuide';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { TrackGuideDetailComponent } from 'src/app/public/track-guide/track-guide-detail/track-guide-detail.component';
import { TrackGuideService } from 'src/app/public/track-guide/track-guide-services';
import { StateGuideComponent } from '../state-guide/state-guide.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { GuideService } from 'src/app/core/services/guide.service';
import { GuideListDetailsComponent } from 'src/app/public/track-guide/guide-list-details/guide-list-details.component';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';

@Component({
  selector: 'app-change-state-guide',
  templateUrl: './change-state-guide.component.html',
  styleUrls: ['./change-state-guide.component.scss']
})
export class ChangeStateGuideComponent {

  @Input() idCompany: string;
  @Input() guideId: string;
  //@Input() guidesId: string[];
  //@Input() hideTemplate: boolean;
  @Input() creationDate: string;

  constructor(
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private trackGuideService: TrackGuideService,
    public utils: Utils,
    private guideService: GuideService
  ) { }

  /*openDialogChangeStateGuide() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      guideId: this.guideId,
      nit: this.idCompany
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const modalDialog = this.dialog.open(StateGuideComponent, dialogConfig);
    modalDialog.afterClosed().subscribe(result => {
      if (result && result.state) {

      }
    });
  }*/

  /*private get listGuides(): string[] {
    let guides = !this.utils.isEmpty(this.guideId) ? [this.guideId] : !this.utils.isEmpty(this.guidesId) ? this.guidesId : null;

    if (guides) {
      guides = guides.map((guide) => {
        return `${this.idCompany}${this.parseCreationDate}${guide}`;
      });
    }

    return guides;
  }*/

  /*private get parseCreationDate(): string {
    return this.creationDate.replace(/-/g, '').substring(0, 8);
  }*/

  openDialogDetailGuide() {
    if (!this.guideId)
      return;
    this.spinner.show();
    this.trackGuideService.getDetailGuide(this.guideId).toPromise()
      .then((response: BasicGuide) => {
        this.spinner.hide();
        if (!response || !response.id)
          return this.snackBarService.openSnackBar('Ocurrió un error al traer el detalle de la guía', undefined, 'error');
        const dialogConfig = new MatDialogConfig();
        dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
        dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
        dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
        dialogConfig.data = {
          guides: [response]
        };
        this.dialog.open(GuideListDetailsComponent, dialogConfig);
      })
      .catch((error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al traer el detalle de la guía', undefined, 'error');
      })

  }

  /*get showTemplate() {
    let state = true;
    if (!this.utils.isEmpty(this.hideTemplate)) {
      state = !this.hideTemplate;
    }
    return state;
  }*/

}
