<div class="position-relative py-4 px-2 px-md-4">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h2 class="mt-2 mb-0 text-bold text-center">
        Plan de ruta
    </h2>
    <h5 class="mb-4 text-center color-primary-dark">
        {{cargo?.cargoFeature?.uploadDownload?.origin?.name}} -
        {{cargo?.cargoFeature.uploadDownload.destination[cargo?.cargoFeature.uploadDownload.destination.length -
        1]?.name}}
    </h5>

    <p class="pl-3 pb-3" *ngIf="isSomeItinerarySelectable || paramsDialog?.mode ==='detail'">
        <b class="text-bold font-secondary-dark">Índices de cercanía:</b>
        Los índices de cercanía muestran qué tan cerca está la ruta de las direcciones del servicio.
        Un índice del 100% indica la máxima proximidad, mientras que un 0% indica la mínima.
    </p>
    <div *ngIf="isSomeItinerarySelectable || paramsDialog?.mode ==='detail'">
        <mat-accordion class="m-1 mb-2 pl-2" *ngFor="let itinerary of sortedItineraries; let i=index"
            [ngClass]="{'d-none': (!itinerary.active || !isNearnessIndexValid(itinerary.id)) && paramsDialog?.mode ==='edit'}">
            <mat-expansion-panel [expanded]="selectedItinerary === itinerary?.id">
                <mat-expansion-panel-header>
                    <div class="d-flex w-100 justify-content-between flex-wrap align-items-baseline my-2">
                        <div class="d-flex flex-wrap align-items-baseline">
                            <h5 class="text-bold mb-0">
                                <i class="far fa-circle cursor-pointer" *ngIf="selectedItinerary !== itinerary?.id"
                                    (click)="onSelectItinerary(itinerary, $event)"></i>
                                <i class="fas fa-check-circle" *ngIf="selectedItinerary === itinerary?.id"></i>
                                {{itinerary.name}}
                            </h5>
                            <small class="mx-2 color-primary-dark" *ngIf="itinerary.code">Código:
                                {{itinerary.code}}</small>
                        </div>
                        <p class="mb-0 mx-2" *ngIf="itinerary.estimatedTime"><b>Duración:</b>
                            {{getDurationLabel(itinerary.estimatedTime)}}</p>
                    </div>
                </mat-expansion-panel-header>
                <p class="m-0" *ngIf="paramsDialog?.mode ==='detail'">
                    <b class="text-bold">Estado: </b>
                    <span class="text-bold"
                        [ngClass]="{'text-success': itinerary?.active, 'text-danger': !itinerary?.active}">
                        {{itinerary?.active ? 'Activo' : 'Inactivo'}}
                    </span>
                </p>
                <table class="table table-bordered" *ngIf="getNearnessIndexes(itinerary.id)?.length">
                    <tr>
                        <th class="table-header">Dirección</th>
                        <th class="table-header">Índice de cercanía</th>
                    </tr>
                    <tr *ngFor="let index of getNearnessIndexes(itinerary.id)">
                        <td>{{index.address}}</td>
                        <td><span class="text-bold" [ngStyle]="{'color': index.index >= 0.7 ? 'green' : 'red'}">
                                {{index.index * 100}}%
                            </span></td>
                    </tr>
                </table>

                <p class="m-0 text-bold font-secondary-dark">Puntos de control:</p>
                <ol class="d-flex flex-wrap w-100 pl-4 pl-sm-5" *ngIf="itinerary?.controlPoints?.length">
                    <li class="col-md-6 col-12" *ngFor="let point of itinerary?.controlPoints">
                        {{point?.name}}
                    </li>
                </ol>
                <p class="m-0 pl-2 mb-3" *ngIf="!itinerary?.controlPoints?.length">Sin puntos de control registrados</p>

                <p class="m-0 text-bold font-secondary-dark">Paradas autorizadas:</p>
                <ul class="d-flex flex-wrap w-100 pl-4 pl-sm-5" *ngIf="itinerary?.authorizedStops?.length">
                    <li class="col-md-6 col-12" *ngFor="let stop of itinerary?.authorizedStops">
                        <img *ngIf="getAuthorizedStopIcon(stop)" [src]="getAuthorizedStopIcon(stop)"
                            alt="Icono de parada autorizada" class="mr-2">
                        {{stop?.details?.name}}
                    </li>
                </ul>
                <p class="m-0 pl-2 mb-3" *ngIf="!itinerary?.authorizedStops?.length">
                    Sin paradas registradas
                </p>
                <p class="m-0 text-bold font-secondary-dark">Distancia:</p>
                <p class="m-0 pl-2">
                    {{itinerary?.estimatedDistance / 1000 | number: '1.1-1'}} km
                </p>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="button-container" *ngIf="paramsDialog?.mode ==='edit' && routePlan">
            <button mat-raised-button class="button-width-auto bg-color-primary" color="primary"
                (click)="saveItinerary()">
                Guardar
            </button>
        </div>
    </div>

    <p class="alert alert-danger text-center text-bold"
        *ngIf="itineraries?.length && !isSomeItinerarySelectable && paramsDialog?.mode ==='edit'">
        <i class="fas fa-exclamation-triangle mr-2"></i>
        No hay ningún itinerario activo o que cumpla con el índice de cercanía igual o mayor al 70%.
        Por favor cree uno nuevo que cumpla con estas condiciones o active uno existente.
    </p>
    <div class="button-container"
        *ngIf="paramsDialog?.mode ==='edit' && routePlan && hasCreateItineraryPermission && !isSomeItinerarySelectable">
        <button mat-raised-button class="button-width-auto bg-color-primary" color="primary"
            (click)="goToCreateItinerary()">
            Crear itinerario
        </button>
    </div>
</div>