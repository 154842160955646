import { FunctionalitiesModuleRole } from '../interfaces/functionalitiesModuleRole';
import { ModuleRole } from '../interfaces/moduleRole';
import { NavItem } from '../interfaces/navItem';
import { Role } from '../interfaces/role';
import { User } from '../interfaces/user';
import * as _ from 'lodash';
import { UserModel } from '../models/user.model';
import { AuthService } from '../services/authentication.service';
import { RoleService } from '../services/role.service';
import { Injectable } from '@angular/core';
import { Companies } from './companies';

@Injectable()
export class PermissionRole {

    authUser = AuthService;

    constructor(
        private authService: AuthService,
        private roleService: RoleService
    ) {
    }

    hasPermission(module: string, functionality: string) {
        //module me trae la lista de
        //this.roleService.getRoleUser() me permite saber si hay un rol de usuario y me lo retorna o sino me retorna un null
        //this.authService.userSessionIsActive() me permite saber si hay una sesion de usuario activa
        //this.roleService.getRoleUser().modules.filter filtra el rol del usuario y compara con module que trae la lista de usuarios con sus funcionalidades permitidas si encuentra alguna coincidencia devuelve true.
        // de la misma forma en el if (moduleData.length) ahora filtra por funcionalidad si coincide con alguna funcionalidad de la lista de funcionalidades de ese rol devuelve true y en el html si funcionality y module true entonces permite visualizarlo.
        if (this.authService.userSessionIsActive() && this.roleService.getRoleUser()) {
            const moduleData: ModuleRole[] = this.roleService.getRoleUser().modules.filter((obj: FunctionalitiesModuleRole) => {
                return obj.name === module;
            });
            if (moduleData.length) {
                const itemChildren: NavItem = _.find(moduleData[0].functionalities, (obj: FunctionalitiesModuleRole) => {
                    return obj.name === functionality;
                });
                return itemChildren && itemChildren.visible && Companies.permissionAccess(
                    functionality,
                    this.authService.getUserSession().clientCompanyId,
                    sessionStorage.getItem('_companyId')
                );
            }
        }
        return false;
    }

    isRole(roleUser: string) {
        let state = false;
        if (roleUser === this.authUser.prototype.getUserSession().role) {
            state = true;
        }
        return state;
    }

}
