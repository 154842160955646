<section class="main-container px-1 px-sm-4">
    <main class="row">
        <div class="col-md-12 subcontainer">
            <div class="row">
                <div class="col-md-8 title-component">
                    <i class="fas fa-project-diagram"></i>
                    Puntos autorizados
                </div>
                <div class="col-md-4 text-right" permission-display [module]="permission.routes.module"
                    [functionality]="permission.routes.createRouteSites">
                    <button (click)="create()" type="button" mat-raised-button color="accent"
                        matTooltip="Agregar un nuevo punto autorizado">
                        <i class="fas fa-plus"></i>
                        Crear punto
                    </button>
                </div>
            </div>
            <div class="row">
                <app-autocomplete-city [options]="cityOptions" [inputFormControl]="cityControl" class="col-md-4">
                </app-autocomplete-city>
                <mat-form-field class="col-md-4" appearance="outline">
                    <mat-label>Tipo</mat-label>
                    <mat-select [formControl]="currentType" (selectionChange)="loadList()">
                        <mat-option *ngFor="let service of services" [value]="service.id">
                            <img *ngIf="service.icon" [src]="service.icon" [alt]="service.name" class="mr-2">
                            {{service.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="table-container">
                        <table class="w-100">
                            <thead>
                                <th>Tipo</th>
                                <th class="d-none d-sm-table-cell">Ciudad</th>
                                <th>Nombre</th>
                                <th class="d-none d-md-table-cell">Dirección</th>
                                <th *ngIf="hasPermissionDelete || hasPermissionEdit">Acciones</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let site of sites">
                                    <td>
                                        <img [src]="getIcon(site?.details?.pointType?.id)"
                                            [alt]="site?.details?.pointType?.name" class="mr-2">
                                    </td>
                                    <td class="d-none d-sm-table-cell"> {{site?.city?.name}}</td>
                                    <td> {{site?.details?.name}} </td>
                                    <td class="d-none d-md-table-cell"> {{site?.address | titlecase}}</td>
                                    <td class="text-center">
                                        <a type="button" style="color: #584796;" matTooltip="Editar" role="button"
                                            class="table-btn" (click)="edit(site)" *ngIf="hasPermissionEdit">
                                            <i class="fas fa-edit"></i>
                                        </a>
                                        <a type="button" style="color: #ee3344;" role="button" matTooltip="Eliminar"
                                            class="table-btn" (click)="deactive(site)" *ngIf="hasPermissionDelete">
                                            <i class="fas fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr *ngIf="sites.length === 0">
                                    <td colspan="8" class="text-center">
                                        <hr>
                                        <br>
                                        <br>
                                        <img src="../../../../assets/svg/icons/tl_ico__caja_vacia.svg">
                                        <br>
                                        <p style="font-weight: bold;">No hay resultados para ésta búsqueda</p>
                                        <br>
                                        <br>
                                        <br>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</section>