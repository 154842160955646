<aside class="col-md-12 mb-md-0 mb-12 h-100-margin">
  <div id="mainGenerateGuides" class="container-list h-100-margin">
    <div class="container-list-filter">
      <div class="row align-items-baseline" id="filter-container">
        <mat-form-field appearance="outline" class="col-lg-3 col-md-6">
          <mat-label>No. de guía</mat-label>
          <input matInput type="text" [(ngModel)]="searchGuideText">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-3 col-md-6">
          <mat-label>No. ID Servicio</mat-label>
          <input matInput type="text" [(ngModel)]="searchCargoText">
        </mat-form-field>
        <app-select-company class="col-lg-3 col-md-6" [options]="optionsCompany"
          [inputFormControl]="company"></app-select-company>
        <mat-card-actions class="col-lg-3 col-md-6 text-right">
          <button mat-raised-button class="btn-filter" color="primary" (click)="applyFilter()" title="Aplicar Filtros">
            <i class="fas fa-search"></i>
          </button>
          <button mat-raised-button class="primary-secondary" color="primary" (click)="cleanFilter()"
            title="Eliminar Filtros">
            <i class="far fa-trash-alt"></i>
          </button>
          <!--button mat-raised-button color="primary" class="button-create-cargo" (click)="generateMultiplesGuides()"
            *ngIf="itemsSelecteds.length" title="Generar desprendibles">
            <i class="fas fa-print"></i>
          </button-->
        </mat-card-actions>
      </div>
    </div>
    <div class="container-list-body py-2" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()">
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let guide of listGuides; let i = index;">
        <app-item-cargo-from-guides [guide]="guide">
        </app-item-cargo-from-guides>
      </div>
    </div>
    <!--div class="container-list-body" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScrollDown()">
      <div class="d-flex justify-content-end">
        <div class="list-select-all mr-2" select-item-list [list]="listGuides" (onSelectItem)="onSelectItem($event)"
          [active]="true" [type]="'selectAll'"></div>
      </div>
      <div class="col-md-12 mb-md-0 mb-12" *ngFor="let guide of listGuides; let i = index;">
        <app-item-cargo-from-guides select-item-list [active]="true" [item]="guide" [list]="listGuides"
          (onSelectItem)="onSelectItem($event)" [guide]="guide" [print]="true">
        </app-item-cargo-from-guides>
      </div>
    </div-->
  </div>
</aside>