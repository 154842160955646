import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from 'src/app/core/shared.module';
import { CreateGuidesFromExcelComponent } from './create-guides-from-excel/create-guides-from-excel.component';
import { GenerateGuideComponent } from './generate-guide/generate-guide.component';
import { GuideRoutingModule } from './guide-routing.module';
import { UploadGuideComponent } from './upload-guide/upload-guide.component';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    GuideRoutingModule
  ],
  declarations: [
    UploadGuideComponent,
    GenerateGuideComponent,
    CreateGuidesFromExcelComponent,
  ],
  entryComponents: [],
  exports: [
    UploadGuideComponent,
    GenerateGuideComponent,
    CreateGuidesFromExcelComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class GuideModule {

}
