<aside class="wrapper-vehicle-form">
  <div class="col-md-12">
    <div class="row">

      <div class="col-md-12 wrapper-detail px-0 px-sm-3">

        <div class="title-component">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-7">
                <i class="fas fa-id-card"></i>
                {{title}}
              </div>
            </div>
          </div>
        </div>
        <mat-tab-group>
          <mat-tab label="Permisos">
            <form class="form-container" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">

              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="text-strong">
                    Información general
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <!-- NIT  -->
                  <div class="col-md-6">
                    <app-select-company *ngIf="mode ==='create'" [options]="optionsCompany"
                      [inputFormControl]="companySelected" [validate]="validate"></app-select-company>
                    <ng-container *ngIf="mode !== 'create' && roleData">
                      <div class="field__card">
                        <label class="field__card--label">Compañía</label>
                        <span class="field__card--value">
                          {{utils.isDefined(roleData.nit) ? roleData.nit : '-'}}
                        </span>
                      </div>
                    </ng-container>
                  </div>
                  <!-- Name -->
                  <div class="col-md-6" *ngIf="mode === 'create' && form.get('name')">
                    <mat-form-field appearance="standard">
                      <mat-label>Nombre<span class="asterisk">*</span></mat-label>
                      <input matInput type="text" [formControl]="form.get('name')">
                      <mat-error *ngIf="form?.get('name')?.errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
              <span class="separator-horizontal"></span>
              <mat-expansion-panel [expanded]="true"
                *ngIf="(companySelected?.value?.companyId && mode === 'create') || mode !== 'create'">
                <mat-expansion-panel-header>
                  <mat-panel-title class="text-strong">
                    Permisos
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <!-- Modules -->
                  <div class="col-md-12">
                    <div class="row">
                      <div formArrayName="modules" *ngFor="let module of formControlModules?.controls; let i = index;"
                        class="col-md-6 col-lg-4">
                        <div class="row" *ngIf="canEditModule(module.value.name) && canEditSomePermission(i)">
                          <div class="col-md-12 font-primary-light text-strong px-2">
                            {{module.value.name | roleModule}}
                          </div>
                          <ng-container class="col-md-12">
                            <div class="col-md-12 px-0 px-sm-2">
                              <div class="col-md-12">
                                <mat-checkbox [checked]="everyPermissionIsActive(i)" class="text-bold"
                                  (change)="onSelectAll($event, i)" [disabled]="!canEditPermissions">
                                  Seleccionar todo
                                </mat-checkbox>
                              </div>
                            </div>
                            <div
                              *ngFor="let functionality of getFormControlFunctionalities(i).controls; let iF = index;"
                              class="col-md-12 px-0 px-sm-2">
                              <div class="col-md-12" *ngIf="canEditPermission(functionality.value.name)">
                                <mat-checkbox [formControl]="functionality.get('visible')">
                                  {{iF + 1}}. {{functionality.value.name | roleFunctionality}}
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>
                          <hr>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

              <span class="separator-horizontal"></span>
              <mat-card-actions class="mat-card-actions-vehcle text-center">
                <button mat-raised-button color="primary" type="submit" class="button-width-auto"
                  *ngIf="canEditPermissions">
                  Guardar
                </button>
              </mat-card-actions>
            </form>
          </mat-tab>

          <mat-tab label="Notificaciones" *ngIf="canEditNotifications && mode === 'detail'">
            <div class="wrapper-notification-tab">
              <form class="form-container" *ngIf="notificationFormLoaded" [formGroup]="formNotifications"
                (submit)="updateNotification()">
                <div class="row">
                  <div class="col-12">
                    <mat-panel-title class="text-strong">
                      Notificaciones visibles para el rol
                    </mat-panel-title>
                    <br>
                  </div>
                  <div class="col-12" *ngFor="let notificationCatalog of notificationsCatalog; let i = index;">
                    <div class="d-flex px-0 px-sm-3 typo-notifcation">
                      <mat-checkbox [formControlName]="notificationCatalog.id"
                        [value]="formNotifications.get(notificationCatalog.id).value">
                        {{i + 1}}. {{notificationCatalog.name}}
                      </mat-checkbox>
                      &nbsp;
                      <div (click)="copy(notificationCatalog.id)" class="c-pointer"
                        [matTooltip]="'ID: ' + notificationCatalog.id">
                        <svg height="80%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="mask0_3277_974" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="24" height="24">
                            <rect width="24" height="24" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_3277_974)">
                            <path
                              d="M9 18C8.45 18 7.97917 17.8042 7.5875 17.4125C7.19583 17.0208 7 16.55 7 16V4C7 3.45 7.19583 2.97917 7.5875 2.5875C7.97917 2.19583 8.45 2 9 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H9ZM9 16H18V4H9V16ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V7C3 6.71667 3.09583 6.47917 3.2875 6.2875C3.47917 6.09583 3.71667 6 4 6C4.28333 6 4.52083 6.09583 4.7125 6.2875C4.90417 6.47917 5 6.71667 5 7V20H15C15.2833 20 15.5208 20.0958 15.7125 20.2875C15.9042 20.4792 16 20.7167 16 21C16 21.2833 15.9042 21.5208 15.7125 21.7125C15.5208 21.9042 15.2833 22 15 22H5Z"
                              fill="#02D7DC" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <mat-card-actions class="mat-card-actions-vehcle text-center">
                      <button mat-raised-button color="primary" type="submit" class="button-width-auto"
                        *ngIf="canEditPermissions">
                        Guardar
                      </button>
                    </mat-card-actions>
                  </div>
                </div>
              </form>
            </div>
          </mat-tab>
        </mat-tab-group>

        <!-- Button back -->
        <div class="row md-form">
          <label class="label-back-step col-md-2" (click)="goToList()">
            <figure>
              <img src="/assets/svg/icons/icon-back.png" alt="">
            </figure>
            Atrás
          </label>
        </div>

      </div>

    </div>
  </div>

</aside>