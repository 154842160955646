import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import dataTypesMerchandiseJson from 'src/app/core/resources/JsonFile1572315390613.json';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TypeMerchandiseService } from './type-merchandise.service';
import { MatAutocompleteSelectedEvent, MatTooltip } from '@angular/material';
import { ModelCargo } from '../../resources/modelCargo';
import { PackagingType } from 'src/app/core/interfaces/packagingType';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
@Component({
  selector: 'app-type-merchandise',
  templateUrl: './type-merchandise.component.html',
  styleUrls: ['./type-merchandise.component.scss'],
  providers: [AuthService, Global, TypeMerchandiseService, ModelCargo]
})
export class TypeMerchandiseComponent implements OnInit {
  permission = Permission;
  optionsTypeMerchandise: OptionsAutocomplete = {
    initialValue: this.manualCreationCargoService.cargoForm.value.cargoFeature.productType.name
  }
  merchandiseFormCtrl = new FormControl('', Validators.required);
  menchandiseSub: Subscription;
  validate: string = '';
  @ViewChild('tooltipMerchandise', { static: true }) tooltipMerchandise: MatTooltip;
  listMerchandiseJson: any = dataTypesMerchandiseJson;
  listMerchandise: Array<Object> = [];
  public hasDescription: FormControl = new FormControl(false);
  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public snackBarService: SnackBarService,
    private typeMerchandiseService: TypeMerchandiseService,
    public modelCargo: ModelCargo,
    public utils: Utils,
    private permissionRole: PermissionRole
  ) {

  }

  ngOnInit() {
    const thisClass = this;
    if (this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description') && this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').value) this.hasDescription.setValue(true);
    else {
      this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').clearValidators();
      this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').updateValueAndValidity();
      this.hasDescription.setValue(false);
    }
    this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.code').setValidators(Validators.required);
    this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.code').markAsUntouched();
    this.setSubscriptionTypeMerchandise();
  }

  setSubscriptionTypeMerchandise() {
    this.menchandiseSub = this.merchandiseFormCtrl.valueChanges
      .subscribe(value => {
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.code.setValue(
          value && value.id ? value.id : ''
        );
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.name.setValue(
          value && value.description ? value.description : ''
        );
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNature.setValue(
          value && value.cargoNature ? value.cargoNature : ''
        );
        this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNatureId.setValue(
          value && value.cargoNatureId ? value.cargoNatureId : ''
        );
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoNature.controls.code.setValue(value && value.cargoNatureId ? value.cargoNatureId : '');
        this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].cargoNature.controls.description.setValue(value && value.cargoNature ? value.cargoNature : '');
      })
  }

  getDataTypesMerchandise() {
    const objArray = [];

    for (let i = 1; i < this.listMerchandiseJson.length; i++) {
      objArray[i - 1] = {};
      for (let k = 0; k < this.listMerchandiseJson[0].length && this.listMerchandiseJson[i].length; k++) {
        const key = this.listMerchandiseJson[0][k];
        objArray[i - 1][key] = this.listMerchandiseJson[i][k];
      }
    }

    this.listMerchandise = objArray;
    this.spinner.hide();
  }

  nextStep() {
    this.validate = 'touched';
    this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.code').markAsTouched();
    this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').markAsTouched();
    if (this.manualCreationCargoService.isValidProductType() && this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').valid) {
      // Tipo de vehículo
      this.manualCreationCargoService.getComponentActive().setId(2);
      this.manualCreationCargoService.getComponentActive().getStep().setId(5);
      this.router.navigate([this.manualCreationCargoService.steps.optional.url]);
    } else {

      if (this.utils.errorMessagesCustomized(this.manualCreationCargoService.cargoForm.get('cargoModel.packagingType.code'), 'tipo de empaque')) return;
      else if (!this.manualCreationCargoService.cargoForm.value.cargoFeature.productType.name) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'el tipo de mercancía'), undefined, 'alert')
        return;
      }
      else if (this.utils.errorMessagesCustomized(this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description'), 'descripción')) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
    }
  }

  get activeDangerousProductType() {
    return this.manualCreationCargoService && this.manualCreationCargoService.cargoForm && this.manualCreationCargoService.cargoForm.controls.cargoFeature && this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType && this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNature && (this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNature.value === 'Carga Peligrosa' || this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].productType.controls.cargoNature.value === 'Desechos Peligrosos')
  }

  displayFnPackagingType(data: PackagingType): string {
    return data ? data.description : '';
  }

  returnFnPackagingType(data: PackagingType): string | undefined {
    return data ? data.code : undefined;
  }

  onSelectPackagingType($event) {
    const packagingType = this.modelCargo.packagingTypes.filter((obj) => {
      return obj.code === $event.value;
    });
    if (packagingType.length) {
      this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.code.setValue(
        packagingType[0].code
      );
      this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].packagingType.controls.description.setValue(
        packagingType[0].description
      );
    }
  }

  setDescriptionValidators($event) {
    if ($event && $event.checked) {
      this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').setValidators(Validators.required);
      this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').updateValueAndValidity();
      this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').markAsUntouched();
    } else {
      this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').clearValidators();
      this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').updateValueAndValidity();
      this.manualCreationCargoService.cargoForm.get('cargoFeature.productType.description').setValue(null);
    }
  }

  stepBack() {
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.assignLicensePlate)) {
      this.manualCreationCargoService.getComponentActive().setId(2);
      this.manualCreationCargoService.getComponentActive().getStep().setId(3);
      this.router.navigate([this.manualCreationCargoService.steps.assignVehicles.url]);
    } else {
      this.manualCreationCargoService.getComponentActive().setId(2);
      this.manualCreationCargoService.getComponentActive().getStep().setId(2);
      this.router.navigate([this.manualCreationCargoService.steps.quantityVehicles.url]);
    }
  }

  ngOnDestroy() {
    if (this.menchandiseSub) this.menchandiseSub.unsubscribe();
  }
}
