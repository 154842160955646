import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Utils } from 'src/app/core/resources/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VehicleInspect } from 'src/app/core/interfaces/vehicleInspect';
import { CargoItemService } from 'src/app/modules/cargo/cargo-item/cargo-item.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent } from '../dialog/dialog.component';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { AngularFireStorage } from '@angular/fire/storage';
import { VehicleDocuments } from 'src/app/core/interfaces/vehicleDocuments';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import ContentTypes from 'src/app/core/resources/content-type-ext.json';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { CheckLists } from 'src/app/core/interfaces/checkListsInspectVehicle';
import { KeyValue } from '@angular/common';
import { ProtectionElements } from 'src/app/core/interfaces/protectionElementInspectVehicle';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { BasicPerson } from 'src/app/core/interfaces/basicPerson';
import { Global } from 'src/app/core/resources/global';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { InspectionQuestion, InspectionVisualQuestion } from 'src/app/core/interfaces/inspectionVehicle';
import { AuthService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-dialog-survey',
  templateUrl: './dialog-survey.component.html',
  styleUrls: ['./dialog-survey.component.scss'],
})
export class DialogSurveyComponent implements OnInit {
  public options = { "B": "Bueno", "M": "Malo", "N/A": "No Aplica" };
  public motorcycleOptions = { "B": "C", "M": "NC", "N/A": "N/A" };
  public motorcycleOptionsAlias = { "C": "Cumple", "NC": "No cumple", "N/A": "No Aplica" };
  public sections = this.utils.clone(this.global.dialogSurveySections);

  public vehicleStructure: Array<CheckLists> = [];
  public bodyworkElements: Array<CheckLists> = [];
  public elements: Array<ProtectionElements> = [];

  public isMotorcycle = false;
  public inspection: 'motorcycle' | 'cargoVehicle' = 'cargoVehicle';
  public version: number = 0;
  public inspectionQuestions: {
    [key: string]: Array<InspectionVisualQuestion>
  } = {};
  public getQuestionFlag = false;

  public optimal: boolean = false;
  public odors: boolean = false;
  public crossedContamination: boolean = false;

  public user: string;
  public vehicle: Vehicle;
  basePath: string;
  public updatePhoto: boolean = false;
  public objComplete: VehicleInspect;
  minObservation: number = 8;
  @ViewChild('inputLeftLateralPicture', { static: false }) inputLeftLateralPicture: ElementRef;
  @ViewChild('inputRightLateralPicture', { static: false }) inputRightLateralPicture: ElementRef;
  @ViewChild('inputFrontalImage', { static: false }) inputFrontalImage: ElementRef;
  @ViewChild('inputPreventativeMaintenance', { static: false }) inputPreventativeMaintenance: ElementRef;
  @ViewChild('inputCorrectiveMaintenance', { static: false }) inputCorrectiveMaintenance: ElementRef;
  form = new FormGroup({
    observation: new FormControl(''),
    dateMaintenanceCorrective: new FormControl('', Validators.required),
    dateMaintenancePreventive: new FormControl('', Validators.required),
    driverWarranty: new FormControl(false, Validators.required),
  });
  instanceLogo: FileStorage;
  formPicture = new FormGroup({
    frontPicture: new FormControl('', Validators.required),
    rightLateralPicture: new FormControl('', Validators.required),
    leftLateralPicture: new FormControl('', Validators.required),
    preventativeMaintenance: new FormControl(''),
    correctiveMaintenance: new FormControl(''),
  });

  public originalOrder = (a, b): number => 0;


  constructor(
    public dialogRef: MatDialogRef<DialogSurveyComponent>,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private cargoItemService: CargoItemService,
    public dialog: MatDialog,
    private vehiclesService: VehiclesService,
    private spinner: NgxSpinnerService,
    private global: Global,
    private angularFireStorage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: {
      user?: BasicPerson,
      licensePlate?: string,
      inspect?: VehicleInspect,
      disabled?: boolean,
      id?: string,
      cargo?: Cargo
    },
  ) { }

  public userInformation: string;

  async ngOnInit() {

    await this.getInspectionQuestions();

    if (this.data.inspect && this.data.inspect.id) {
      this.form.patchValue(this.data.inspect);
      this.form.get('dateMaintenancePreventive').setValue(DateManager.stringToDate(this.data.inspect.dateMaintenancePreventive, 'YYYY-MM-DD'));
      this.form.get('dateMaintenanceCorrective').setValue(DateManager.stringToDate(this.data.inspect.dateMaintenanceCorrective, 'YYYY-MM-DD'));
      if (this.data.inspect.preventativeMaintenance) {
        this.formPicture.get("preventativeMaintenance").setValue(this.data.inspect.preventativeMaintenance);
        this.formPicture.get("preventativeMaintenance").disable();
      }
      if (this.data.inspect.correctiveMaintenance) {
        this.formPicture.get("correctiveMaintenance").setValue(this.data.inspect.correctiveMaintenance);
        this.formPicture.get("correctiveMaintenance").disable();
      }

      this.bodyworkElements = this.data.inspect.bodyworkElements;
      this.elements = this.data.inspect.elements;
      this.vehicleStructure = this.data.inspect.vehicleStructure;

      if (this.data.inspect.approval)
        this.userInformation = this.data.inspect.confirmedInspectionFingerPrint.userName;
      else
        this.userInformation = this.data.inspect.evaluatorFingerPrint.userName;
      this.form.disable();
    }
    else
      this.userInformation = this.data.user.name;
    this.getVehicle(this.data.licensePlate);
  }

  private async getInspectionQuestions() {
    this.isMotorcycle = this.data.cargo && this.utils.getNestedValue(this.data.cargo, 'cargoFeature.vehicleType.name') === 'MOTOCICLETA';
    const hasMotorcycleInspection = this.data.inspect && this.data.inspect.inspectionType === 'motorcycle';

    this.version = this.isMotorcycle || (this.data.inspect && this.data.inspect.version === 2) ? 2 : 3;
    this.inspection = this.isMotorcycle || hasMotorcycleInspection ? 'motorcycle' : 'cargoVehicle';

    let questions: InspectionQuestion[] = [];
    try {
      questions = await this.cargoItemService.getInspectionQuestions(this.inspection, this.version).toPromise();
    } catch (e) { }

    if (!questions || !questions.length) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      return this.dialogRef.close();
    }

    if (this.inspection === 'motorcycle') //Inspección de moto (v2)
      this.inspectionQuestions = {
        "condiciones del usuario": [],
        "verificacion del sistema electrico": [],
        "estado mecanico": [],
        "estado de funcionamiento del motor": [],
      };
    else if (this.version === 2) //Inspección de vehículo antigua (v2)
      this.inspectionQuestions = {
        "estructura del vehiculo": [],
        "carroceria": [],
        "elementos de proteccion personal": []
      };
    else //Inspección de vehículo nueva (v3)
      this.inspectionQuestions = {
        "estado general y cabina": [],
        "sistema electrico": [],
        "sistema mecanico": [],
        "equipo de seguridad": [],
        "inspeccion a la unidad de carga": [],
        "inspeccion de cisterna": [],
        "elementos de proteccion personal": [],
      };

    const noElementQuestionWithOnlyObservation = ['CEU'];

    questions.forEach(question => {
      let questionToAdd = {};
      const section = question.mobileSection ? question.mobileSection : question.section;
      if (question && question.code && question.inspectionAttribute && section && this[question.inspectionAttribute]) {

        if (question.inspectionAttribute === 'elements') {
          questionToAdd['name'] = question.code;
          questionToAdd['available'] = true;
          questionToAdd['status'] = this.isVehicleInspectionv3 ? 'C' : 'B';
        } else {
          questionToAdd['question'] = question.code;
          if (!noElementQuestionWithOnlyObservation.includes(question.code))
            questionToAdd['response'] = this.isVehicleInspectionv3 ? 'C' : 'B';
          else
            questionToAdd['observation'] = '';
        }
        if (this.inspection === 'motorcycle')
          questionToAdd['observation'] = '';

        this[question.inspectionAttribute].push(questionToAdd);
        if (this.inspectionQuestions[section]) {
          if (question.sectionName || question.componentName)
            this.sections[section] = question.sectionName ? question.sectionName : question.componentName;
          this.inspectionQuestions[section].push({
            code: question.code,
            name: question.question,
            description: question.description,
            inspectionAttribute: question.inspectionAttribute,
            hasResponse: 'response' in questionToAdd,
            hasStatus: 'status' in questionToAdd,
            hasObservation: 'observation' in questionToAdd,
            hasAvailable: 'available' in questionToAdd,
            order: question.order
          });
        }
      }
    });

    Object.keys(this.inspectionQuestions).forEach(key => {
      this.inspectionQuestions[key].sort((a, b) => {
        if (a.order > b.order) return 1;
        else return -1
      })
    });

    this.getQuestionFlag = true;
  }

  public getVehicle(id: string) {
    this.vehiclesService.getVehicle(id).subscribe(
      (success: any) => {
        if (success) {
          this.vehicle = success;
          this.formPicture.patchValue(this.vehicle);
          if (this.data && this.data.inspect && this.data.inspect.id) {
            this.formPicture.disable();
          }
        }
      }
    );
  }

  public confirmRejection() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas rechazar la inspección del vehiculo?`,
    };
    dialogConfig.height = 'auto';
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.approve(false);
      }
    });
  }

  public approve(approval: boolean) {
    let obj = {
      inspectionId: this.data.inspect.id,
      fingerprint: {
        userId: this.data.user.document,
        userName: this.data.user.name,
        date: DateManager.dateToString(new Date())
      }
    };
    this.cargoItemService.approveInspection(obj, approval).subscribe(success => {
      approval ?
        this.snackBarService.openSnackBar('Inspección aprobada correctamente', undefined, "success") :
        this.snackBarService.openSnackBar('Inspección rechazada correctamente', undefined, "success");
      this.dialogRef.close();
    },
      (error: HttpErrorResponse) => {
        if (!this.utils.isEmpty(error) && !this.utils.isEmpty(error.error) && !this.utils.isEmpty(error.error.message)) {
          this.snackBarService.openSnackBar(error.error.message, undefined, 'alert');
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al aprobar inspección', undefined, 'error');
        }
      }
    );
  }

  public setInstanceFileStorage(e: Event, typeFile?: string) {
    const validTypeFiles = ['rightLateralPicture', 'leftLateralPicture', 'frontPicture', 'correctiveMaintenance', 'preventativeMaintenance'];
    if (['rightLateralPicture', 'leftLateralPicture', 'frontPicture'].includes(typeFile))
      this.updatePhoto = true;
    if (validTypeFiles.includes(typeFile)) {
      if (this.utils.isImage(e.target['files'])) {
        this.instanceLogo = {
          fileData: {
            file: e.target['files'][0],
            name: e.target['files'][0]['name'],
            uploaded: true,
            size: this.utils.bytesToSize(e.target['files'][0].size),
            url: null
          },
          storageData: {
            storageRef: null,
            uploadTask: null,
            uploadProgress: null
          }
        };
        this.uploadFileStorage(typeFile);
      } else {
        this.snackBarService.openSnackBar('El archivo no es una imagen', undefined, 'error')
      }
    } else {
      this.instanceLogo = {
        fileData: {
          file: e.target['files'][0],
          name: e.target['files'][0]['name'],
          uploaded: true,
          size: this.utils.bytesToSize(e.target['files'][0].size),
          url: null
        },
        storageData: {
          storageRef: null,
          uploadTask: null,
          uploadProgress: null
        }
      };
      this.uploadFileStorage(typeFile);
    }
  }

  public openWindowFile(typeFile?: string): void {
    if (typeFile == 'leftLateralPicture' && this.formPicture.get('leftLateralPicture').enabled) {
      this.inputLeftLateralPicture.nativeElement.click();
    }
    if (typeFile == 'rightLateralPicture' && this.formPicture.get('rightLateralPicture').enabled) {
      this.inputRightLateralPicture.nativeElement.click();
    }
    if (typeFile == 'frontPicture' && this.formPicture.get('frontPicture').enabled) {
      this.inputFrontalImage.nativeElement.click();
    }
    if (typeFile == 'preventativeMaintenance' && this.formPicture.get('preventativeMaintenance').enabled) {
      this.inputPreventativeMaintenance.nativeElement.click();
    }
    if (typeFile == 'correctiveMaintenance' && this.formPicture.get('correctiveMaintenance').enabled) {
      this.inputCorrectiveMaintenance.nativeElement.click();
    }
  }

  private uploadFileStorage(typeFile?: string): void {
    this.basePath = 'vehicle/' + this.vehicle.id + '/';
    let filePath = this.basePath + this.instanceLogo.fileData.name;
    const fileTypeToField = {
      rightLateralPicture: 'rightLateralPicture',
      leftLateralPicture: 'leftLateralPicture',
      frontPicture: 'frontPicture',
      preventativeMaintenance: 'preventativeMaintenance',
      correctiveMaintenance: 'correctiveMaintenance'
    };
    if (fileTypeToField[typeFile]) {
      this.formPicture.get(fileTypeToField[typeFile]).setValue(filePath);
    }
    this.spinner.show();
    this.instanceLogo.storageData.storageRef = this.angularFireStorage.ref(filePath);
    this.instanceLogo.storageData.uploadTask = this.angularFireStorage.upload(filePath, this.instanceLogo.fileData.file);
    this.instanceLogo.storageData.uploadTask.then(
      (data) => {
        this.instanceLogo.storageData.storageRef.getDownloadURL().subscribe(
          (urlFile) => {
            this.spinner.hide();
          }
        );
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  saveInspection() {
    this.form.markAllAsTouched();
    if (this.utils.errorMessagesCustomized(this.form.get('dateMaintenanceCorrective'), 'fecha de mantenimiento correctivo')) return;
    else if (this.utils.errorMessagesCustomized(this.form.get('dateMaintenancePreventive'), 'fecha de mantenimiento preventivo')) return;
    else if (this.utils.errorMessagesCustomized(this.form.get('observation'), 'observación', 8)) return;
    else if (this.form.invalid) {
      this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    } else {
      let objUser = {
        userId: this.data.user.document,
        userName: this.data.user.name,
        date: DateManager.dateToString(new Date())
      }

      const dateMaintenancePreventive = DateManager.dateToString(this.form.get('dateMaintenancePreventive').value, 'YYYY-MM-DD');
      const dateMaintenanceCorrective = DateManager.dateToString(this.form.get('dateMaintenanceCorrective').value, 'YYYY-MM-DD');
      this.objComplete = {
        version: this.version,
        confirmedInspectionFingerPrint: null,
        rejectionCause: null,
        licensePlate: this.data.licensePlate,
        vehicleStructure: this.vehicleStructure,
        bodyworkElements: this.bodyworkElements,
        elements: this.elements,
        evaluatorFingerPrint: objUser,
        observation: this.form.get('observation').value,
        dateMaintenancePreventive,
        dateMaintenanceCorrective,
        driverWarranty: this.form.get('driverWarranty').value,
        optimal: this.optimal,
        odors: this.odors,
        crossedContamination: this.crossedContamination,
        preventativeMaintenance: this.formPicture.value.preventativeMaintenance,
        correctiveMaintenance: this.formPicture.value.correctiveMaintenance,
        inspectionType: this.isMotorcycle ? 'motorcycle' : 'cargoVehicle',
      }

      if (!this.updatePhoto) {
        this.spinner.show();
        this.save();
      } else {
        //  Aqui va la parte de la subida de las fotos
        if (this.utils.errorMessagesCustomized(this.formPicture.get('frontPicture'), 'foto frontal del vehículo')) return;
        else if (this.utils.errorMessagesCustomized(this.formPicture.get('rightLateralPicture'), 'foto lateral derecha del vehículo')) return;
        else if (this.utils.errorMessagesCustomized(this.formPicture.get('leftLateralPicture'), 'foto lateral izquierda del vehículo')) return;
        else if (this.utils.errorMessagesCustomized(this.formPicture.get('preventativeMaintenance'), 'foto mantenimiento preventivo')) return;
        else if (this.utils.errorMessagesCustomized(this.formPicture.get('correctiveMaintenance'), 'foto mantenimiento correctivo')) return;
        else if (this.formPicture.invalid) {
          this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
          return;
        } else {
          this.spinner.show();
          //Para propertyCard toca buscar esa info para cargarla antes de guardar;
          const data: VehicleDocuments = {
            frontPicture: this.formPicture.value.frontPicture,
            rightLateralPicture: this.formPicture.value.rightLateralPicture,
            leftLateralPicture: this.formPicture.value.leftLateralPicture,
            propertyCard: this.vehicle.propertyCard,
          }
          this.vehiclesService.updateVehicleDocuments(this.vehicle.id, data).subscribe(
            (success: any) => this.save(),
            (error) => {
              this.spinner.hide();
              this.snackBarService.openSnackBar('Ocurrió un error al guardar los datos', undefined, 'error');
            }
          );
        }
      }
    }
  }

  public save() {
    this.cargoItemService.sendInspection(this.objComplete, this.isMotorcycle ? "motorcycle" : "VEHICLE", this.data.id).subscribe(
      (success) => {
        this.spinner.hide();
        if (success) {
          this.snackBarService.openSnackBar('Inspección guardada correctamente');
          this.closeDialog(success);
        } else {
          this.snackBarService.openSnackBar('Existio un error al guardar la inspección', undefined, 'error');
          this.closeDialog('');
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (!this.utils.isEmpty(error) && !this.utils.isEmpty(error.error) && !this.utils.isEmpty(error.error.message)) {
          this.snackBarService.openSnackBar(error.error.message, undefined, 'alert');
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al enviar inspeción', undefined, 'error');
        }
      }
    );
  }
  public downloadInspection() {
    this.spinner.show();
    this.cargoItemService.getInspectionPDF(this.data.id, this.data.licensePlate).subscribe(
      (response) => {
        this.spinner.hide();
        var file = new Blob([response], { type: ContentTypes.pdf });
        var downloadURL = window.URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `_${this.data.licensePlate}_Inspección-preoperacional-vehicular.pdf`;
        link.click();
      }, (error: HttpErrorResponse) => {
        this.spinner.hide()
        this.snackBarService.openSnackBar('Ocurrió un error al descargar la Inspección preoperacional del vehículo', undefined, 'error');
      })
  }

  closeDialog(object) {
    this.dialogRef.close(object);
  }

  getArrayByName(name: "vehicleStructure" | "bodyworkElements" | "elementsProtection") {
    return this[name];
  }
  getQuestionIndex(code: string, list: "vehicleStructure" | "bodyworkElements" | "elementsProtection"): number {
    return this[list].findIndex((question: ProtectionElements | CheckLists) =>
      question && (('name' in question && question.name === code) || ('question' in question && question.question === code))
    );
  }
  get getRealOptions(): Array<string> {
    if (!this.getQuestionFlag) return [];
    if (this.isVehicleInspectionv3) return ["C", "NC", "N/A"];
    return ["B", "M", "N/A"];
  }

  getAliasOption(option: string): string {
    if (this.options[option]) return this.options[option];
    if (this.motorcycleOptionsAlias[option]) return this.motorcycleOptionsAlias[option];
    return option;
  }

  get useQuestionAlias(): boolean {
    return this.inspection === 'motorcycle';
  }
  get isVehicleInspectionv3(): boolean {
    return this.inspection === 'cargoVehicle' && this.version === 3;
  }

  showQuestionLabel(visualQuestion: InspectionVisualQuestion): boolean {
    return !this.isVehicleInspectionv3 || (this.isVehicleInspectionv3 && !visualQuestion.hasAvailable);
  }
  showQuestionAvailable(visualQuestion: InspectionVisualQuestion): boolean {
    return visualQuestion.hasAvailable && !this.isMotorcycle && this.getQuestionIndex(
      visualQuestion.code,
      visualQuestion.inspectionAttribute as "vehicleStructure" | "bodyworkElements" | "elementsProtection"
    ) !== -1;
  }
  showQuestionStatus(visualQuestion: InspectionVisualQuestion): boolean {
    return visualQuestion.hasStatus && !this.isVehicleInspectionv3 && this.getQuestionIndex(
      visualQuestion.code, visualQuestion.inspectionAttribute as "vehicleStructure" | "bodyworkElements" | "elementsProtection") !== -1;
  }
  showQuestionResponse(visualQuestion: InspectionVisualQuestion): boolean {
    return visualQuestion.hasResponse && this.getQuestionIndex(
      visualQuestion.code, visualQuestion.inspectionAttribute as "vehicleStructure" | "bodyworkElements" | "elementsProtection") !== -1;
  }
  showQuestionObservation(visualQuestion: InspectionVisualQuestion): boolean {
    return visualQuestion.hasObservation && this.getQuestionIndex(
      visualQuestion.code, visualQuestion.inspectionAttribute as "vehicleStructure" | "bodyworkElements" | "elementsProtection") !== -1;
  }
  get userName(): string {
    const evaluatorUserName = this.utils.getNestedValue(this.data.inspect, 'evaluatorFingerPrint.userName');
    if (evaluatorUserName) return evaluatorUserName;
    return this.authService.getUserSession().information.name;
  }
  get vehiclePlate(): string {
    return this.data.licensePlate;
  }

}
