<div class="guide">
  <app-change-state-guide [guideId]="guide.guideId" class="icon-detail">
  </app-change-state-guide>
  <div class="row container-date">
    <div class="col-md-4" *ngIf="guide.guideId">
      <p class="title-purple text-break p-0 m-0">
        N° Guía: {{guide.guideId}}
      </p>
    </div>
    <div class="col-md-4" *ngIf="guide.cargoConsecutive">
      <span class="title-purple">
        Servicio: {{guide.cargoConsecutive}}
      </span>
    </div>
    <div class="col-md-4" *ngIf="guide.state">
      <span class="title-purple">
        Estado: {{getTitleAlias(guide.state)}}
      </span>
    </div>
  </div>
  <div class="row container-data">
    <div class="col-md-4">
      <div class="label">
        Ciudad de origen
      </div>
      <div class="text-bold label-field">
        {{guide.originName? guide.originName : '-'}}
      </div>
    </div>
    <div class="col-md-4">
      <div class="label">
        Ciudad de destino
      </div>
      <div class="text-bold label-field">
        <div class="text-bold label-field">
          {{guide.destinationName? guide.destinationName : '-'}}
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="label">
        Operación
      </div>
      <div class="text-bold label-field">
        {{guide?.sender?.nameCompany ? guide.sender.nameCompany : '-'}}
      </div>
    </div>
  </div>
</div>