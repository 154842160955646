import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrackGuide } from 'src/app/core/interfaces/trackGuide';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { TrackGuideService } from '../track-guide-services';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';

@Component({
  selector: 'app-track-guide-search',
  templateUrl: './track-guide-search.component.html',
  styleUrls: ['./track-guide-search.component.scss',],
  //providers:[TrackGuideService]
})
export class TrackGuideSearchComponent implements OnInit {

  @Output() changeView = new EventEmitter<object>();
  guide: BasicGuide;
  formTrackGuide: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private trackGuideService: TrackGuideService,
    private snackBarService: SnackBarService,
    public utils: Utils
  ) { }

  ngOnInit() {
    this.formTrackGuide = this.formBuilder.group({
      guideId: ['', Validators.required]
    });
  }

  onSubmit() {
    const guideId = this.formTrackGuide.controls.guideId.value.trim();
    if (!guideId) return this.snackBarService.openSnackBar("Por favor ingrese un número de guía", undefined, 'alert');
    this.trackGuideService.getDetailGuide(guideId).subscribe(
      (success: BasicGuide) => {
        if (!this.utils.isEmpty(success)) {
          this.guide = success;
          this.changeView.emit({
            guide: this.guide
          });
        } else {
          this.snackBarService.openSnackBar("La guia que desea rastrear no existe", undefined, 'error');
        }
      },
      (error) => {
        this.snackBarService.openSnackBar("La guia que desea rastrear no existe", undefined, 'error');
      }
    )
  }

}
