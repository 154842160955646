import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from 'src/environments/environment';
import { AdditionalCostCargo } from "src/app/core/interfaces/additionalCostCargo";
import { BasicResponse } from "src/app/core/interfaces/basicResponse";

@Injectable({
  providedIn: 'root'
})

export class ItemAdditionalService {
  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
  ){}

  editAdditionalService(body: AdditionalCostCargo,cargoId: string) {
    let url = environment.urlServerTeclogi + this.endpointResources.editAdditionalCost;
    let params = new HttpParams();
    params = params.append('cargoId', cargoId)
    return this.http.post<BasicResponse>(url, body, {params})
  }


  deleteAdditionalService(cargoId: string , additionalCostId: string) {
    let url = environment.urlServerTeclogi + this.endpointResources.deleteAdditionalcost;
    let params = new HttpParams();
    params = params.append('cargoId', cargoId);
    params = params.append('additionalCostId', additionalCostId);
    return this.http.delete( url, { params })
  }


}
