<aside class="col-md-12 mb-md-0 mb-12 h-100 px-0">
  <div id="mainDetailFreight" class="container-list position-relative" [ngClass]="{'m-1': isDialog }">
    <i *ngIf="isDialog" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
      (click)="dialogRef.close()"></i>
    <div class="col-md-12" *ngIf="cargo?.id">
      <!-- Primer panel -->
      <div class="row row-title" *ngIf="!isDialog">
        <div class="col-md-12 col-map">
          <!-- Map -->
          <div *ngIf="tracking && cargo?.state===cargoStateEnum.END_SERVICE" class="messageTracking">
            No se muestra la ruta real del servicio porque no se hizo tracking
          </div>
          <app-standard-map [options]="mapOptions"></app-standard-map>
        </div>
      </div>
      <section *ngIf="errorsFieldsCargo?.length && cargo?.state==='Request'" class="alert alert-danger">
        <h6 class="text-bold text-center">
          La solicitud se ha generado
          <ng-container *ngIf="errorsFieldsCargo.length === 0">
            sin errores.
          </ng-container>
          <ng-container *ngIf="errorsFieldsCargo.length === 1">
            con el siguiente error:
          </ng-container>
          <ng-container *ngIf="errorsFieldsCargo.length > 1">
            con los siguientes {{ errorsFieldsCargo.length }} errores:
          </ng-container>
        </h6>
        <p *ngFor="let errorField of errorsFieldsCargo" class="pl-2 fs-14 mb-0">
          <i class="fas fa-exclamation-circle"></i> {{errorField}}
        </p>
      </section>
      <div class="row">
        <div class="col-md-12 px-0 px-md-3">
          <div class="row">
            <!-- Panel origen -->
            <div class="col-md-6 px-0" permission-display [module]="permission.cargo.module"
              [functionality]="permission.cargo.showOriginAddresses">
              <!-- Titulo Origen -->
              <div class="row">
                <div class="col-md-11 wrapper-label">
                  <label for="" class="title-1 w-auto">
                    Origen
                  </label>
                </div>
              </div>
              <div class="row panel-information">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-lg-6">
                      <!-- Ciudad de origen -->
                      <mat-card-content>
                        <div class="md-form">
                          <label class="title-2">Ciudad de origen</label>
                          <span class="text-bold">
                            {{ cargo?.cargoFeature?.uploadDownload?.origin?.name ?
                            cargo?.cargoFeature.uploadDownload.origin.name : '-' }}
                            {{cargo?.cargoFeature?.uploadDownload?.origin?.country?'('+cargo?.cargoFeature?.uploadDownload?.origin?.country?.name+')':''}}
                          </span>
                        </div>
                      </mat-card-content>
                    </div>
                    <div class="col-lg-6">
                      <mat-card-content>
                        <div class="md-form">
                          <label class="title-2">Fecha {{isEscortedService? 'estimada de llegada al origen': 'de
                            cargue'}}</label>
                          <span class="text-bold">
                            {{cargo?.dateLoad ? (cargo?.dateLoad | dateFormat:'only-date') : '-'}}
                          </span>
                        </div>
                      </mat-card-content>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Dirección de cargue  -->
              <div class="row panel-information panel-border-left"
                *ngFor="let upload of cargo?.cargoFeature.uploadDownload.origin.addresses; let i = index;">

                <div class="col-lg-5">
                  <mat-card-content class="col-md-12 mat-card-border">
                    <div class="md-form">
                      <label class="title-2">Hora {{isEscortedService? 'estimada de llegada al origen': 'de cargue '+
                        (i+1)}}</label>
                      <span class="text-bold">
                        {{ upload.time? (upload.time | dateFormat: 'only-time'): '-'}}
                      </span>
                    </div>
                  </mat-card-content>
                </div>

                <div class="col-lg-7">
                  <mat-card-content class="col-md-12 mat-card-border">
                    <div class="md-form">
                      <label class="title-2">Dirección de {{isEscortedService? 'origen': 'cargue ' + (i+1)}}</label>
                      <span class="text-bold">
                        {{upload.address}}
                      </span>
                    </div>
                  </mat-card-content>
                </div>
              </div>
            </div>
            <!-- Panel de destino -->
            <div class="col-md-6 px-0" permission-display [module]="permission.cargo.module"
              [functionality]="permission.cargo.showDestinationAddresses">
              <div class="row">
                <div class="col-md-11 wrapper-label">
                  <label for="" class="title-1 w-auto">
                    Destino
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12"
                  *ngFor=" let destination of cargo?.cargoFeature.uploadDownload.destination; let index = index;">
                  <!-- Ciudad de destino -->
                  <div class="row panel-information">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-lg-6">
                          <mat-card-content>
                            <div class="md-form">
                              <label class="title-2">Ciudad de destino {{index + 1}}</label>
                              <span class="text-bold">
                                {{destination.name}}{{destination.country?'('+destination.country.name+')':''}}
                              </span>
                            </div>
                          </mat-card-content>
                        </div>
                        <div class="col-lg-6">
                          <mat-card-content>
                            <div class="md-form">
                              <label class="title-2">Fecha {{isEscortedService? 'estimada de llegada al destino':'de
                                descargue'}}</label>
                              <span class="text-bold">
                                {{destination.downloadDate ? (destination.downloadDate | dateFormat:'only-date'): '-'}}
                              </span>
                            </div>
                          </mat-card-content>
                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- Dirección de descargue  -->
                  <div class="row panel-information panel-border-left"
                    *ngFor="let download of destination.addresses; let i = index;">

                    <div class="col-lg-5">
                      <mat-card-content class="col-md-12 mat-card-border">
                        <div class="md-form">
                          <label class="title-2">Hora {{isEscortedService? 'estimada de llegada al destino': 'de
                            descargue ' + (i+1)}}</label>
                          <span class="text-bold">
                            {{ download.time?(download.time | dateFormat: 'only-time'): '-'}}
                          </span>
                        </div>
                      </mat-card-content>
                    </div>


                    <div class="col-lg-7">
                      <mat-card-content class="col-md-12 mat-card-border">
                        <div class="md-form">
                          <label class="title-2">Dirección de {{isEscortedService? 'destino' : 'descargue ' +
                            (i+1)}}</label>
                          <span class="text-bold">
                            {{download.address}}
                          </span>
                        </div>
                      </mat-card-content>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Fecha de creacion de la solicitud -->
          <div class="creation-info-container" permission-display [module]="permission.cargo.module"
            [functionality]="permission.cargo.showCreationDateCargo">
            <div class="row">
              <div class="col-md-6">
                <mat-card-content>
                  <div class="md-form">
                    <label class="title-2">Fecha de creación del servicio</label>
                    <span class="text-bold">
                      {{cargo?.creationDate ? (cargo?.creationDate | dateFormat) : '-'}}
                    </span>
                  </div>
                </mat-card-content>
              </div>
            </div>
          </div>

          <!-- Información - Datos de cargue -->
          <div class="row row-information" permission-display [module]="permission.cargo.module"
            [functionality]="permission.cargo.allowToEditGeneralDataModule">
            <app-general-data-cargo class="col-md-12" [cargo]="cargo" (emitToParent)="getCargoDetailById($event)"
              (cleanCargo)="onChangeCleanCargo($event)" (cleanCostCenterCtrl)="cleanCostCenterCtrl($event)">
            </app-general-data-cargo>
          </div>
        </div>
      </div>

      <!--Etiquetas-->
      <div *ngIf="!isEscortedService" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.showEditTagsModule">
        <div class="row row-title">
          <div class="col-md-12 wrapper-label">
            <label for="" class="title-1">
              Etiquetas
            </label>
          </div>
        </div>
        <div class="row row-information">
          <p *ngIf="!cargoTags?.length">No hay etiquetas atadas a este servicio</p>
          <div class="col-sm-6 col-md-4" *ngFor="let tag of cargoTags; let i = index">
            <div class="tag-card">
              <p class="text-bold">{{tag}}</p>
              <i class="fas fa-times" (click)="clearTag(i)" *ngIf="canEditTags"></i>
            </div>
          </div>
          <mat-form-field class="col-sm-6 col-md-4" appearance="outline" *ngIf="canEditTags">
            <mat-label>Etiqueta (opcional)</mat-label>
            <mat-select [formControl]="tagControl" (selectionChange)="selectTag($event)">
              <mat-option *ngFor="let tag of tags" [value]="tag.name" [disabled]="cargoTags?.includes(tag.name)">
                {{tag.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Tipo de servicio -->
      <div *ngIf="canEditCargo && !isEscortedService" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.editServiceTypeModule">
        <div class="row row-title">
          <div class="col-md-12 wrapper-label">
            <label for="" class="title-1">
              Tipo de servicio
            </label>
            <button type="button" (click)="updateCargoType()" class="btn float-right color-primary">
              <i class="far fa-save"></i> Guardar
            </button>
          </div>
        </div>
        <!-- Información - Tipo de carga -->
        <app-cargo-type [cargo]="cargo" (emitToParent)="getCargoDetailById($event)"
          (cleanCargo)="onChangeCleanCargo($event)"></app-cargo-type>
      </div>

      <div *ngIf="!canEditCargo || isEscortedService" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.showServiceTypeModule">
        <div class="row row-title">
          <div class="col-md-12 wrapper-label">
            <label for="" class="title-1">
              Tipo de servicio
            </label>
          </div>
        </div>
        <!-- Información - Tipo de carga -->
        <div class="row row-information">
          <!-- Tipo de viaje -->
          <div class="col-md-3">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Tipo de viaje</span>
                <label class="label-field">
                  {{cargo?.cargoModel?.tripType?.name ? cargo?.cargoModel?.tripType?.name : '-'}}
                </label>
              </div>
            </mat-card-content>
          </div>
          <div class="col-md-3" *ngIf="cargo?.cargoModel?.tripType?.date && !isEscortedService">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Fecha de vencimiento DUTA/OTM</span>
                <label class="label-field">{{cargo?.cargoModel?.tripType?.date | dateFormat:'only-date'}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Tipo de régimen -->
          <div class="col-md-3" *ngIf="cargo?.cargoModel?.tripType?.regimeType && !isEscortedService">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Tipo de régimen</span>
                <label class="label-field">{{cargo?.cargoModel?.tripType?.regimeType}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Tipo de carga -->
          <div class="col-md-3"
            *ngIf="(cargo?.cargoFeature?.productType?.type ==='FixedCargo' || cargo?.cargoModel?.cargoType?.name) && !isEscortedService">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Tipo de carga</span>
                <label class="label-field">
                  {{ cargo?.cargoFeature?.productType?.type ==='FixedCargo'
                  ? 'Carga fija'
                  : cargo?.cargoModel?.cargoType?.name ?
                  cargo?.cargoModel?.cargoType?.name : '-' }}
                </label>
              </div>
            </mat-card-content>
          </div>
          <!-- Tipo de servicio -->
          <div class="col-md-3" *ngIf="cargo?.cargoModel?.serviceType?.name">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Tipo de servicio</span>
                <label class="label-field">{{cargo?.cargoModel.serviceType.name}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Carta de retiro -->
          <div class="col-md-6" *ngIf="cargo?.cargoModel?.tripType?.name==='Exportación' && !isEscortedService">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Carta de retiro</span>
                <app-file
                  [options]="{title: 'Carta retiro', path: 'Cargo'+ '/' + cargo?.id + '/letterRetirement', storage: true, storageName: 'tripType_letterRetirement', firebasePath: firebasePath ? cargo?.cargoModel?.tripType?.letterRetirement : null, readOnly: true}"
                  [inputFormControl]="letterRetirement">
                </app-file>
              </div>
            </mat-card-content>
          </div>
        </div>
        <div class="row row-information py-0">
          <!-- Detalle -->
          <div class="col-md-8">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Observaciones del servicio</span>
                <div class="label-field">
                  {{utils.isDefined(cargo?.observation) ?
                  (cargo?.cargoFeature?.productType?.type === 'FixedCargo' ? 'Carga Fija: ' : '')
                  + cargo?.observation
                  : (cargo?.cargoFeature?.productType?.type === 'FixedCargo' ? 'Carga Fija: ' : '') + '-'}}
                </div>
              </div>
            </mat-card-content>
          </div>
          <div class="col-md-4" permission-display [module]="permission.cargo.module"
            [functionality]="permission.cargo.addObservation">
            <mat-card-actions class="actions-card my-0 text-right">
              <button mat-raised-button color="primary" (click)="openDialogObservation()">
                Añadir Observación
              </button>
            </mat-card-actions>
          </div>
        </div>
        <div class="row row-information">
          <div class="col-md-12">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Observaciones para el {{isEscortedService? 'escolta': 'conductor'}}</span>
                <div class="label-field">
                  {{utils.isDefined(cargo?.observationDriver) ? cargo?.observationDriver : ''}}
                </div>
              </div>
            </mat-card-content>
          </div>
        </div>
      </div>

      <!-- Características -->
      <div *ngIf="canEditCargo && !isEscortedService" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.editServiceCharacteristicsModule">
        <div class="row row-title">
          <div class="col-md-12 wrapper-label margin">
            <label class="title-1 w-auto">
              Características del servicio
            </label>
            <button type="button" (click)="updatecharacteristic()" class="btn float-right color-primary">
              <i class="far fa-save"></i> Guardar
            </button>
          </div>
        </div>
        <!-- Información - Valor de envío y Forma de pago -->
        <div class="row row-information-address">
          <app-cargo-characteristic [cargo]="cargo" (emitToParent)="getCargoDetailById($event)">
          </app-cargo-characteristic>
        </div>
      </div>
      <div *ngIf="!canEditCargo || isEscortedService" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.showServiceCharacteristicsModule">
        <!-- Información - Características -->
        <div class="row row-title">
          <div class="col-md-12 wrapper-label margin">
            <label class="title-1 w-auto">
              Características del servicio
            </label>
          </div>
        </div>
        <div class="row row-information">
          <!-- Tipo de mercancía -->
          <div class="col-md-3" *ngIf="!isEscortedService">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Tipo de mercancía</span>
                <label class="label-field">{{cargo?.cargoFeature?.productType?.name ?
                  cargo?.cargoFeature?.productType?.name :
                  '-'}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Tipo de vehículo -->
          <div class="col-md-3">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Tipo de vehículo</span>
                <br><br>

                <span class="label-field">
                  {{ cargo?.cargoFeature?.vehicleType?.quantity ? cargo?.cargoFeature?.vehicleType?.quantity :
                  '-'}}
                </span>&nbsp;

                <span>
                  {{ cargo?.cargoFeature?.vehicleType?.name ? cargo?.cargoFeature?.vehicleType?.name : '-'}}
                </span>&nbsp;

                <span>
                  {{ cargo?.cargoFeature?.vehicleType?.bodyWorkType?.name ?
                  cargo?.cargoFeature?.vehicleType?.bodyWorkType?.name : '-'}}
                </span>
                <i permission-display [module]="permission.cargo.module"
                  [functionality]="permission.cargo.editTypeVehicle"
                  *ngIf="[cargoStateEnum.CREATED, cargoStateEnum.ACCEPTED].includes(cargo?.state)"
                  class="fas fa-edit btn-edit" (click)="editTypeVehicle()"></i>
              </div>
            </mat-card-content>
          </div>
          <div class="col-md-6" *ngIf="!isEscortedService">
            <div class="row">
              <div class="col-md-6">
                <mat-card-content>
                  <div class="col-md-12 md-form md-form-reverse">
                    <span>Medidas del servicio</span>
                    <br>
                    <span class="label-field">{{ cargo?.cargoFeature?.cargoMeasure?.totalMeasurement ?
                      cargo?.cargoFeature?.cargoMeasure?.totalMeasurement : '-'}}</span>
                  </div>
                </mat-card-content>
              </div>
              <div class="col-md-6">
                <mat-card-content>
                  <div class="col-md-12 md-form md-form-reverse">
                    <span>Tipo de ambiente</span>
                    <br>
                    <span class="label-field">{{ cargo?.cargoFeature?.vehicleType?.quality ?
                      cargo?.cargoFeature?.vehicleType?.quality : '-'}}</span>
                  </div>
                </mat-card-content>
              </div>
            </div>
            <br>
            <!-- Listado de servicios -->
            <div class="row">
              <div class="col-md-12">
                <!-- Información -->
                <div class="row">
                  <!-- Cantidad -->
                  <div class="col-md-5">
                    <mat-card-content>
                      <div class="md-form md-form-reverse">
                        <span>Cantidad</span>
                        <br>
                        <span class="label-field">{{cargo?.cargoFeature?.cargoMeasure?.quantity}}</span>
                      </div>
                    </mat-card-content>
                  </div>
                  <!-- Medida total -->
                  <div class="col-md-7">
                    <mat-card-content>
                      <div class="md-form md-form-reverse">
                        <span>Medida total del servicio</span>
                        <br>
                        <span class="label-field">{{cargo?.cargoFeature?.cargoMeasure?.totalMeasurement}} metros
                          cubicos</span>
                      </div>
                    </mat-card-content>
                  </div>
                </div>
                <div class="row">
                  <!-- Peso total -->
                  <div class="col-md-5">
                    <mat-card-content>
                      <div class="md-form md-form-reverse">
                        <span>Peso total del servicio</span>
                        <br>
                        <span class="label-field">{{cargo?.cargoFeature?.cargoMeasure?.totalWeigth}}</span>
                      </div>
                    </mat-card-content>
                  </div>
                  <!-- Valor  -->
                  <div class="col-md-7">
                    <mat-card-content>
                      <div class="md-form md-form-reverse">
                        <span>Valor declarado de la mercancia</span>
                        <label class="label-field" *ngIf="!cargoAmountEdit?.editing">
                          {{cargo?.cargoFeature?.cargoMeasure?.amount | currency:'COP':'code'}}
                          <i permission-display [module]="permission.cargo.module"
                            [functionality]="permission.cargo.editCargoAmount" (click)="editCargoValue(true)" *ngIf="!cargoAmountEdit?.editing && [
                              cargoStateEnum.CREATED,
                              cargoStateEnum.ACCEPTED,
                              cargoStateEnum.START_SERVICE
                            ].includes(cargo?.state)" class="fas fa-pencil-alt color-primary cursor-pointer"
                            matTooltip="Modificar Valor" matTooltipPosition="above"></i>
                        </label>
                        <input *ngIf="cargoAmountEdit?.editing" matInput type="number"
                          [(ngModel)]="cargoAmountEdit.value" min="1">
                        <div class="d-flex justify-content-end mt-2" *ngIf="cargoAmountEdit?.editing">
                          <i matTooltip="Confirmar" matTooltipPosition="above"
                            class="mr-3 fas fa-check fa-lg color-primary cursor-pointer"
                            (click)="confirmCargoValue()"></i>
                          <i matTooltip="Cancelar" matTooltipPosition="above"
                            class="mr-3 fas fa-times fa-lg color-primary cursor-pointer"
                            (click)="editCargoValue(false)"></i>
                        </div>
                      </div>
                    </mat-card-content>
                  </div>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="![cargoStateEnum.REQUEST, cargoStateEnum.DELETED, cargoStateEnum.EXPIRED].includes(cargo?.state)
        && (canUpdatePlanningRoute || canCheckPlanningRoute)">
        <div class="alert alert-warning w-100 d-flex flex-wrap justify-content-between align-items-baseline"
          *ngIf="!cargo?.routePlanId || !cargo?.itineraryId">
          <p class="text-bold mr-2 m-0" [ngClass]="{'text-center':!canUpdatePlanningRoute}">
            <i class="fas fa-exclamation-triangle mr-3"></i> Este servicio no tiene un plan de ruta asignado
          </p>
          <button mat-raised-button class="primary-secondary my-2" color="primary" *ngIf="canUpdatePlanningRoute"
            (click)="checkPlanningRoute()">
            Asignar
          </button>
        </div>
        <div *ngIf="cargo?.routePlanId && cargo?.itineraryId" class="alert alert-info w-100">
          <p class="text-center m-0">
            <b class="text-bold">Plan de ruta asignado: </b>
            {{cargo?.cargoFeature?.uploadDownload?.origin?.name}} -
            {{cargo?.cargoFeature.uploadDownload.destination[cargo?.cargoFeature.uploadDownload.destination.length -
            1]?.name}}
          </p>
          <div class="w-100 button-container my-2">
            <button mat-raised-button class="primary-secondary" color="primary" *ngIf="canUpdatePlanningRoute"
              (click)="changeItinerary()">
              Cambiar itinerario
            </button>
            <button mat-raised-button color="primary" class="bg-color-primary" (click)="watchItineraryDetail()">
              Ver itinerario actual
            </button>
          </div>
        </div>
      </div>
      <!-- Cargue y descargue -->
      <div class="row row-title" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.editLoadDownloadModule">
        <div class="col-md-12 wrapper-label margin">
          <label class="title-1 w-auto">
            {{isEscortedService? 'Origen y destino' : 'Cargue y descargue'}}
          </label>
          <button type="button"
            *ngIf="canEditCargo&&(cargo?.state===cargoStateEnum.REQUEST || cargo?.requestState ===cargoStateEnum.REQUEST)"
            (click)="updateUploadDownload()" class="btn float-right color-primary">
            <i class="far fa-save"></i> Guardar
          </button>
        </div>
      </div>
      <div *ngIf="pendingInitialFulfill && isPrincipalCompany" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.showInitialFulfill" class="consignment-error mb-4">
        <i class="fas fa-triangle-exclamation"></i> Pendiente realizar Cumplido Inicial, <span
          class="underline cursor-pointer" (click)="flowInitialFill()">realizar
          ahora</span>
      </div>
      <!-- Información -Cargue y descargue -->
      <div class="row row-information-address border-bottom"
        *ngIf="!canEditCargo||!(cargo?.state===cargoStateEnum.REQUEST || cargo?.requestState ===cargoStateEnum.REQUEST)"
        permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.showLoadDownloadModule">
        <!-- Cargue -->
        <div class="col-md-12"
          *ngFor="let upload of cargo?.cargoFeature?.uploadDownload?.origin?.addresses; let i = index;">
          <!-- Dirección de cargue  -->
          <div class="row">
            <div class="col-md-12">
              <mat-card-content>
                <div class="md-form">
                  <span class="label-field">{{isEscortedService? 'Origen' : 'Cargue ' + (i+1)}}:
                    {{upload.address}}</span>
                  <a class="underline color-secondary-dark text-strong float-right"
                    (click)="showEvidence(upload, upload.id, 'Origin')"
                    *ngIf="cargo?.state === cargoStateEnum.END_SERVICE">
                    Ver evidencias
                  </a>
                </div>
              </mat-card-content>
            </div>
          </div>
          <!-- Ínformación - Dirección de cargue  -->
          <div class="row">
            <!-- Hora de cargue -->
            <div class="col-sm-6 col-lg-3">
              <mat-card-content>
                <div class="md-form md-form-reverse">
                  <span>{{isEscortedService? 'Hora estimada de llegada al origen':'Hora de cargue'}}</span>
                  <label class="label-field">{{utils.isDefined(upload.time) ? upload.time : '-'}}</label>
                </div>
              </mat-card-content>
            </div>
            <!-- Nombre de la empresa -->
            <div class="col-sm-6 col-lg-3" *ngIf="upload?.thirdPartyConsignment?.name">
              <mat-card-content>
                <div class="md-form md-form-reverse">
                  <span>Nombre del remitente</span>
                  <label class="label-field">
                    {{upload.thirdPartyConsignment.name}}
                  </label>
                </div>
              </mat-card-content>
            </div>
            <!-- NIT de la empresa -->
            <div class="col-sm-6 col-lg-3" *ngIf="upload?.thirdPartyConsignment?.documentNumber">
              <mat-card-content>
                <div class="md-form md-form-reverse">
                  <span>NIT del remitente</span>
                  <label class="label-field">{{upload.thirdPartyConsignment.documentNumber}}</label>
                </div>
              </mat-card-content>
            </div>
            <!-- Celular -->
            <div class="col-sm-6 col-lg-3" *ngIf="upload?.cellphone">
              <mat-card-content>
                <div class="md-form md-form-reverse">
                  <span>Celular</span>
                  <label class="label-field">{{upload.cellphone}}</label>
                </div>
              </mat-card-content>
            </div>

            <!-- Duration Time  -->
            <div class="col-sm-6 col-lg-3">
              <mat-card-content>
                <div class="md-form md-form-reverse">
                  <span>{{isEscortedService? 'Inicio del servicio':'Inicio de cargue'}}</span>
                  <label class="label-field">{{ upload.durationTime && utils.isDefined(upload.durationTime.startDate)
                    ?
                    (upload.durationTime.startDate | dateFormat) : '-'}}</label>
                </div>
              </mat-card-content>
            </div>
            <div class="col-sm-6 col-lg-3" *ngIf="!isEscortedService">
              <mat-card-content>
                <div class="md-form md-form-reverse">
                  <span>Finalización de cargue</span>
                  <label class="label-field">{{upload.durationTime && utils.isDefined(upload.durationTime.endDate) ?
                    (upload.durationTime.endDate | dateFormat) : '-'}}</label>
                </div>
              </mat-card-content>
            </div>
            <div class="col-sm-6 col-lg-3" *ngIf="!isEscortedService">
              <mat-card-content>
                <div class="md-form md-form-reverse">
                  <span>Duración de cargue</span>
                  <label class="label-field">{{ upload.durationTime && utils.isDefined(upload.durationTime.duration) ?
                    (upload.durationTime.duration | millisecondsDigitalClock) : '-'}}</label>
                </div>
              </mat-card-content>
            </div>

          </div>
          <!-- Change state guide  -->
          <!-- <div class="row text-right" *ngIf="listStates && listStates.catalog && upload.id">
            <div class="offset-sm-6 col-sm-6 row">
              <div class="col-sm-6">
                <mat-card-content>
                  <div class="md-form">
                    <label class="label-field text-left">Estado</label>
                    <mat-form-field>
                      <mat-select (selectionChange)="onSelectStateGuide($event.value, upload)">
                        <mat-option value="{{state.id}}" *ngFor="let state of listStates.catalog">
                          {{state.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </div>
              <div class="col-sm-6">
                <mat-card-content>
                  <div class="md-form">
                    <label class="label-field">&nbsp;</label>
                    <button mat-raised-button color="primary" class="button-create-cargo" (click)="setStateGuide(upload)">
                      Actualizar estado
                    </button>
                  </div>
                </mat-card-content>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="row"
        *ngIf="!canEditCargo||!(cargo?.state===cargoStateEnum.REQUEST || cargo?.requestState ===cargoStateEnum.REQUEST)"
        permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.showLoadDownloadModule">
        <!-- Descargue -->
        <div class="row" style="width: 100%;"
          *ngFor=" let destination of cargo?.cargoFeature?.uploadDownload?.destination; let index = index;">
          <div class="row-information-address border-bottom col-md-12"
            *ngFor=" let download of destination.addresses; let indexDownload = index;">
            <!-- Dirección de cargue  -->
            <div class="row">
              <div class="col-md-12">
                <mat-card-content>
                  <div class="md-form">
                    <span class="label-field">{{isEscortedService? 'Destino' : 'Descargue ' + (indexDownload+1)}}:
                      {{download.address}}</span>
                    <a class="underline color-secondary-dark text-strong float-right"
                      (click)="showEvidence(download, download.id, 'Destination', destination.id)"
                      *ngIf="cargo?.state === cargoStateEnum.END_SERVICE">
                      Ver evidencias
                    </a>
                  </div>
                </mat-card-content>
              </div>
            </div>
            <!-- Ínformación - Dirección de descargue  -->
            <div class="row">
              <!-- Hora de cargue -->
              <div class="col-sm-6 col-lg-3" *ngIf="!utils.isEmpty(download.time)">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>{{isEscortedService? 'Hora estimada de llegada al destino':'Hora de descargue'}}</span>
                    <label class="label-field">{{ utils.isDefined(download.time )? download.time : '-'}}</label>
                  </div>
                </mat-card-content>
              </div>
              <!-- Inicio del descargue  -->
              <div class="col-md-3" *ngIf="!isEscortedService">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>Inicio de descargue</span>
                    <label class="label-field">{{download.durationTime &&
                      utils.isDefined(download.durationTime.startDate) ? (download.durationTime.startDate |
                      dateFormat)
                      : '-'}}</label>
                  </div>
                </mat-card-content>
              </div>
              <!-- Finalización del descargue  -->
              <div class="col-md-3">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>{{isEscortedService? 'Llegada al destino': 'Finalización de descargue'}}</span>
                    <label class="label-field">{{download.durationTime &&
                      utils.isDefined(download.durationTime.endDate)
                      ? (download.durationTime.endDate | dateFormat) : '-'}}</label>
                  </div>
                </mat-card-content>
              </div>
              <!-- Duración del descargue  -->
              <div class="col-md-3" *ngIf="!isEscortedService">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>Duración de descargue</span>
                    <label class="label-field">{{download.durationTime &&
                      utils.isDefined(download.durationTime.duration) ? (download.durationTime.duration |
                      millisecondsDigitalClock) : '-'}}</label>
                  </div>
                </mat-card-content>
              </div>
              <!-- Nombre de la empresa -->
              <div class="col-sm-6 col-lg-3" *ngIf="download?.thirdPartyConsignment?.name">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>Nombre del destinatario</span>
                    <label class="label-field">{{download.thirdPartyConsignment.name}}</label>
                  </div>
                </mat-card-content>
              </div>
              <!-- NIT de la empresa -->
              <div class="col-sm-6 col-lg-3" *ngIf="download?.thirdPartyConsignment?.documentNumber">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>NIT del destinatario</span>
                    <label class="label-field">{{download.thirdPartyConsignment.documentNumber}}</label>
                  </div>
                </mat-card-content>
              </div>
              <!-- Celular -->
              <div class="col-sm-6 col-lg-3" *ngIf="download?.cellphone">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>Celular</span>
                    <label class="label-field">
                      {{download.cellphone}}
                    </label>
                  </div>
                </mat-card-content>
              </div>
              <!-- Email -->
              <div class="col-sm-6 col-lg-3" *ngIf="download?.email">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>Email</span>
                    <label class="label-field">{{download.email}}</label>
                  </div>
                </mat-card-content>
              </div>
              <div class="col-sm-6 col-lg-3" *ngIf="download?.consignments?.length">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>Remesas</span>
                    <span class="underline font-primary-light" permission-display [module]="permission.cargo.module"
                      [functionality]="permission.cargo.editConsignment"
                      (click)="goToEditConsignment(index, indexDownload)">
                      <i class="fas fa-edit"></i>
                    </span>
                    <label class="label-field">{{getConsignments(index,indexDownload)}}</label>
                  </div>
                </mat-card-content>
              </div>
              <div class="col-sm-6 col-lg-3"
                *ngIf="download?.cargoMeasure?.totalWeigth && download?.cargoMeasure?.unit && !isEscortedService">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>{{download?.consignments?'Peso de la remesa':'Peso'}}</span>
                    <label class="label-field" *ngIf="!showEditWeightIcon(index,indexDownload)">
                      {{download.cargoMeasure.totalWeigth}} {{download.cargoMeasure.unit==='1'?'kilos':'galones'}}
                      <i permission-display [module]="permission.cargo.module"
                        [functionality]="permission.cargo.editWeightConsignment" *ngIf="!showEditWeightIcon(index,indexDownload)&&download.consignments && cargo && (!cargo?.manifest || (cargo?.manifest && !cargo?.manifestAuthorization))
                        && [cargoStateEnum.START_SERVICE, cargoStateEnum.END_SERVICE].includes(cargo?.state)"
                        (click)="setIconEditWeight(index,indexDownload,true)"
                        class="fas fa-pencil-alt color-primary cursor-pointer" matTooltip="Modificar Peso"
                        matTooltipPosition="above"></i>
                    </label>
                    <input *ngIf="showEditWeightIcon(index,indexDownload)&&download.consignments" matInput type="number"
                      [(ngModel)]="destinationsWeights[index][indexDownload].weight" min="1">
                    <div class="d-flex justify-content-end mt-2"
                      *ngIf="showEditWeightIcon(index,indexDownload)&&download.consignments">
                      <i matTooltip="Confirmar" matTooltipPosition="above"
                        class="mr-3 fas fa-check fa-lg color-primary cursor-pointer"
                        (click)="editWeight(index,indexDownload)"></i>
                      <i matTooltip="Cancelar" matTooltipPosition="above"
                        class="mr-3 fas fa-times fa-lg color-primary cursor-pointer"
                        (click)="setIconEditWeight(index,indexDownload,false)"></i>
                    </div>
                  </div>
                </mat-card-content>
                <mat-error class="custom-mat-error" *ngIf="!destinationsWeights[index][indexDownload].weight">
                  {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
              </div>

              <!-- Cumplido inicial -->
              <div class="col-md-3" *ngIf="pendingInitialFulfill || !!getComplimentDate(index, indexDownload)">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span>Cumplido inicial</span>
                    <label class="label-field" *ngIf="!pendingInitialFulfill">
                      Emitido el {{getComplimentDate(index, indexDownload) | dateFormat }}
                    </label>
                    <label class="label-field" *ngIf="pendingInitialFulfill"
                      [ngClass]="{'cursor-pointer underline font-secondary-dark': canEditInitialFulfill}"
                      (click)="flowInitialFill()">
                      Pendiente
                    </label>
                  </div>
                </mat-card-content>
              </div>

              <div class="col-md-3"
                *ngIf="!pendingInitialFulfill && !!getComplimentDate(index, indexDownload) && canEditInitialFulfill">
                <mat-card-content>
                  <div class="md-form md-form-reverse">
                    <span class="label-field" role="button"
                      style="color: #584796; text-decoration: underline; cursor: pointer;"
                      (click)="reverseCompliment(index, indexDownload)">
                      Anular cumplido inicial
                    </span>
                  </div>
                </mat-card-content>
              </div>

              <!-- Change state guide  -->
              <mat-card-content class="col-sm-6 col-lg-3" *ngFor="let guideId of download?.guidesId">
                <div class="md-form md-form-reverse">
                  <span>N° de guía</span>
                  <app-change-state-guide [creationDate]="cargo?.creationDate" [guideId]="guideId"
                    [idCompany]="cargo?.idCompany">
                  </app-change-state-guide>
                  <label class="label-field">{{guideId}}</label>
                </div>
              </mat-card-content>
              <div class="consignment-error" *ngIf="download?.ministryError?.error">
                <i class="fas fa-triangle-exclamation"></i> {{download.ministryError.error}}
              </div>
            </div>
            <br>
            <!--Requisitos de cumplido-->
            <p *ngIf="canCheckCompliments" (click)="openCargoCompliments(index,indexDownload)"
              class="underline color-secondary-dark text-strong text-center cursor-pointer">
              Ver requisitos de cumplido
            </p>
          </div>
        </div>
      </div>

      <!-- Editar Cargue y descargue --- Request -->
      <div
        *ngIf="canEditCargo&&(cargo?.state===cargoStateEnum.REQUEST || cargo?.requestState ===cargoStateEnum.REQUEST)"
        permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.editLoadDownloadModule">
        <div class="row row-information-address">
          <div class="col-12">
            <app-load-download-cargo [cargo]="cargo" (emitToParent)="getCargoDetailById($event)"
              [cleanCargo]="cleanCargo">
            </app-load-download-cargo>
          </div>
        </div>
      </div>

      <!-- Calificacion -->
      <div class="row row-title" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.rateCargo" *ngIf="cargo?.state === cargoStateEnum.END_SERVICE">
        <div class="col-md-12 wrapper-label margin">
          <label class="title-1 w-auto">
            Calificación de servicio
          </label>
        </div>
      </div>
      <!-- Calificacion informacion -->
      <div class="row row-information border-bottom" *ngIf="cargo?.state === cargoStateEnum.END_SERVICE"
        permission-display [module]="permission.cargo?.module" [functionality]="permission.cargo?.rateCargo">
        <div class="col-md-3">
          <mat-card-content>
            <div class="md-form md-form-reverse">
              <span>Puntaje</span>
              <label class="label-field">{{utils.isDefined(cargo?.cargoScore?.creatorScore)?
                cargo?.cargoScore?.creatorScore: '-'}}</label>
            </div>
          </mat-card-content>
        </div>
        <div class="col-md-7" *ngIf="cargo?.cargoScore?.reason">
          <mat-card-content>
            <div class="md-form md-form-reverse">
              <span>Motivo</span>
              <label class="label-field"> {{cargo.cargoScore.reason}}
              </label>
            </div>
          </mat-card-content>
        </div>
        <div class="col-md-2" *ngIf="!cargo?.cargoScore && cargo?.state === cargoStateEnum.END_SERVICE">
          <mat-card-actions class="actions-card">
            <button mat-raised-button color="primary" (click)="rateCargo()">
              Calificar
            </button>
          </mat-card-actions>
        </div>
      </div>

      <!-- Financiera -->
      <mat-card class="container-financiera" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.showFinancialInformationModule">
        <div class="row">
          <div class="container-title-financial col-md-12">
            <span class="text-financial">Información Financiera</span>
          </div>
        </div>
        <div class="row head">
          <!-- Advance -->
          <div class="col-md-4">
            <div class="row container-title-financial">
              <span class="col-md-12">Anticipo</span>
            </div>
            <div class="row content-financial-head">
              <div class="col-md-12 item-financial">
                <div>Valor {{utils.isDefined(cargo?.shippingCost?.advancePercentage)
                  ? (cargo?.shippingCost?.advancePercentage + "%") : ''}}
                  <span class="underline font-primary-light" (click)="openDialogNewAdvancePercentage()"
                    *ngIf="!cargo?.shippingCost?.advanceState || !haveAdvancePayments">
                    <i *ngIf="cargo?.shippingCost" class="fas fa-edit"></i>
                  </span>
                </div>
                <div class="item-price">
                  {{(cargoItemService.getTotalValueAdvance(cargo) | currency:'COP':'code':'0.2')}}
                </div>
              </div>

              <div class="col-md-12 item-financial">
                <div *ngIf="cargo?.shippingCost?.advanceState">Pagado</div>
                <div *ngIf="!cargo?.shippingCost?.advanceState">Por Pagar</div>
                <div class="item-price color-secondary-dark">
                  {{(cargoItemService.getTotalValueAdvance(cargo) | currency:'COP':'code':'0.2')}}
                </div>
              </div>

              <div class="col-md-12 item-financial">
                <div>Forma de pago</div>
                <div class="item-price color-secondary-dark text__color">
                  {{cargo?.shippingCost?.advancePaymentMethod ?
                  (cargo?.shippingCost?.advancePaymentMethod)
                  :
                  '-'}}
                </div>
              </div>

              <div
                class="cursor-pointer item-financial col-md-12 color-secondary-dark d-flex align-items-center justify-content-between"
                (click)="isCollapsedAdvanceInformation = !isCollapsedAdvanceInformation"
                *ngIf="cargo?.shippingCost?.requestedAdvance?.fingerprint">
                <u class="mr-1"><strong>¿Quién hizo la solicitud? </strong></u>
                <mat-icon *ngIf="!isCollapsedAdvanceInformation">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="isCollapsedAdvanceInformation">keyboard_arrow_up</mat-icon>
              </div>
              <div class="items-dropdown row" [ngClass]="{'collapsed': isCollapsedAdvanceInformation}">
                <div class="col-md-12 item-financial" *ngIf="cargo?.shippingCost?.requestedAdvance?.fingerprint">
                  <div>Responsable</div>
                  <div class="item-price color-secondary-dark text__color">
                    {{cargo?.shippingCost?.requestedAdvance.fingerprint.userName}}
                  </div>
                </div>

                <div class="col-md-12 item-financial" *ngIf="cargo?.shippingCost?.requestedAdvance?.fingerprint">
                  <div>ID</div>
                  <div class="item-price color-secondary-dark text__color">
                    {{cargo?.shippingCost?.requestedAdvance?.fingerprint?.userId}}
                  </div>
                </div>

                <div class="col-md-12 item-financial" *ngIf="cargo?.shippingCost?.requestedAdvance?.fingerprint">
                  <div>Fecha</div>
                  <div class="item-price color-secondary-dark text__color">
                    {{ ( cargo?.shippingCost?.requestedAdvance?.fingerprint?.date|dateFormat)}}
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- Balance -->
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="row container-title-financial">
              <span class="col-md-12">Saldo</span>
            </div>
            <div class="row content-financial-head">
              <div class="col-md-12 item-financial">
                <div *ngIf="cargo?.shippingCost?.balanceState">Pagado</div>
                <div *ngIf="!cargo?.shippingCost?.balanceState">Por pagar</div>
                <div class="item-price">
                  {{balance | currency:'COP':'code':'0.2'}}
                </div>
              </div>


              <div class="col-md-12 item-financial">
                <div>Forma de pago</div>
                <div class="item-price color-secondary-dark text__color">
                  {{utils.isDefined(cargo?.shippingCost?.balancePaymentMethod) ?
                  (cargo?.shippingCost?.balancePaymentMethod)
                  :
                  '-'}}
                </div>
              </div>

              <div class="col-md-12 item-financial">
                <div>Tiempo de pago</div>
                <div class="item-price color-secondary-dark text__color">
                  {{utils.isDefined(cargo?.shippingCost?.paymentTime)
                  ?
                  (cargo?.shippingCost?.paymentTime?.toString() + ' días') : '-'}}
                </div>
              </div>

            </div>
          </div>
          <!-- Taxes -->
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="row container-title-financial">
              <span class="col-md-12">Impuesto</span>
            </div>
            <div class="row content-financial-head">
              <div class="col-md-12 item-financial">
                <div>
                  Reteica
                  {{utils.isDefined(cargo?.shippingCost?.reteicaPercentage) ?
                  ((cargo?.shippingCost?.reteicaPercentage | cargoTaxes:isChargeTaxes) + '%') : ''}}
                </div>
                <div class="item-price">
                  {{utils.isDefined(cargo?.shippingCost?.reteicaValue)
                  ?
                  ((cargo?.shippingCost?.reteicaValue | cargoTaxes:isChargeTaxes) | currency:'COP':'code':'0.2') : '-'}}
                </div>
              </div>

              <div class="col-md-12 item-financial">
                <div>
                  Retefuente
                  {{utils.isDefined(cargo?.shippingCost?.reteftePercentage) ?
                  ((cargo?.shippingCost?.reteftePercentage | cargoTaxes:isChargeTaxes) + '%') : ''}}
                </div>
                <div class="item-price">
                  {{utils.isDefined(cargo?.shippingCost?.retefteValue)
                  ?
                  ((cargo?.shippingCost?.retefteValue | cargoTaxes:isChargeTaxes) | currency:'COP':'code':'0.2') :
                  '-'}}
                </div>
              </div>

              <div class="col-md-12 item-financial" *ngIf="authService.getCompany().companyId === environment.rootNit">
                <div>
                  Servicio App SaaS - Plataforma Digital Transporte
                  {{utils.isDefined(cargo?.shippingCost?.totalCost) ?
                  (cargo?.shippingCost?.integralValuesPercentage + '%') : ''}}
                </div>
                <div class="item-price">
                  {{utils.isDefined(cargo?.shippingCost?.totalCost)
                  ?
                  (cargo?.shippingCost?.integralValue | currency:'COP':'code':'0.2') :
                  '-'}}
                </div>
              </div>

            </div>
          </div>
          <!-- Viáticos -->
          <div class="col-lg-4 col-md-4 col-sm-12" permission-display [module]="permission.cargo.module"
            [functionality]="permission.cargo.editTravelExpenses">
            <div class="row container-title-financial">
              <span class="col-md-12">Viáticos</span>
            </div>
            <div class="row content-financial-head">
              <div class="col-md-12 item-financial" *ngFor="let travelExpense of travelExpenses; let i=index;">
                <div>
                  {{travelExpense.travelExpensesType.name}}
                </div>
                <div class="item-price">
                  {{travelExpense.totalPaid | currency:'COP':'code':'0.2'}}
                </div>
              </div>
              <div class="col-md-12 item-financial" *ngIf="travelExpenses?.length">
                <div>
                  {{areTravelExpensesPaid? 'Pagado' : 'Por pagar'}}
                </div>
                <div class="item-price">
                  {{totalTravelExpenses | currency:'COP':'code':'0.2'}}
                </div>
              </div>
              <div class="col-md-12 item-financial"
                *ngIf="![cargoStateEnum.EXPIRED, cargoStateEnum.DELETED].includes(cargo?.state)">
                <div (click)="showTravelExpensesDialog()"
                  class="text-bold underline color-secondary-dark cursor-pointer"
                  *ngIf="!cargo?.shippingCost?.balanceState">
                  Agregar
                </div>
                <div class="item-price underline color-secondary-dark cursor-pointer"
                  (click)="showLegalizeTravelExpensesDialog()" *ngIf="travelExpenses?.length">
                  {{!areTravelExpensesApproved ? 'Legalizar' : 'Ver'}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="cargo?.journalError?.hasError">
          <div class="col-md-12 alert alert-danger" role="alert">
            {{cargo?.journalError?.description}}
          </div>
        </div>
        <div class="row" *ngIf="cargo?.additionalCosts">
          <div class="col-md-12">
            <app-summary-additional-cost-cargo [additionalCosts]="cargo?.additionalCosts">
            </app-summary-additional-cost-cargo>
          </div>
        </div>
        <div class="row">
          <mat-list class="col-md-12 px-0 content-financial">
            <!-- Valor de la tarifa -->
            <mat-list-item class="row h-auto" permission-display [module]="permission.cargo.module"
              [functionality]="permission.cargo.showRateCargo">
              <div class="w-100 item-financial">
                <div>Valor inicial de la tarifa</div>
                <div class="item-price">
                  {{utils.isDefined(cargo?.shippingCost?.rate) ?
                  ((cargo?.shippingCost?.rate - cargoItemService.getTotalBonusesRate(cargo)) | currency:'COP':'code') :
                  '-'}}
                </div>
              </div>

            </mat-list-item>
            <!-- Valor del flete inicial -->
            <mat-list-item class="row h-auto">
              <div class="w-100 item-financial">
                <div>Valor inicial del flete</div>
                <div class="item-price">
                  {{utils.isDefined(cargo?.shippingCost?.totalCost) ?
                  (cargo?.shippingCost?.totalCost | currency:'COP':'code') : '-'}}
                </div>
              </div>

            </mat-list-item>
            <!-- Valor de Bonificaciones tarifa-->
            <mat-list-item class="row h-auto" *ngIf="cargoItemService.getTotalBonusesRate(cargo)" permission-display
              [module]="permission.cargo.module" [functionality]="permission.cargo.showRateCargo">
              <div class="w-100 item-financial">
                <div>Valor de las Bonificaciones a la Tarifa <i
                    class="fas fa-history color-secondary-dark cursor-pointer" matTooltip="Historico de bonificaciones"
                    matTooltipPosition="above" (click)="openHistoryDiscountsAndBonuses(0,'amountRate')"></i></div>
                <div class="item-price text__color color--success">
                  {{cargoItemService.getTotalBonusesRate(cargo)
                  | currency:'COP':'code'}}
                </div>
              </div>

            </mat-list-item>
            <!-- Valor de Bonificaciones flete-->
            <mat-list-item class="row h-auto" *ngIf="cargoItemService.getTotalBonuses(cargo)">
              <div class="w-100 item-financial">
                <div>Valor de las Bonificaciones al Flete <i class="fas fa-history color-secondary-dark cursor-pointer"
                    matTooltip="Historico de bonificaciones" matTooltipPosition="above"
                    (click)="openHistoryDiscountsAndBonuses(0, 'amount')"></i></div>
                <div class="item-price text__color color--success">
                  {{cargoItemService.getTotalBonuses(cargo) |
                  currency:'COP':'code'}}
                </div>
              </div>

            </mat-list-item>
            <!-- Valor de Descuentos tarifa-->
            <mat-list-item class="row h-auto" *ngIf="cargoItemService.getTotalDiscountsRate(cargo)" permission-display
              [module]="permission.cargo.module" [functionality]="permission.cargo.showRateCargo">
              <div class="w-100 item-financial">
                <div>Valor de los Descuentos a la Tarifa <i class="fas fa-history color-secondary-dark cursor-pointer"
                    matTooltip="Historico de descuentos" matTooltipPosition="above"
                    (click)="openHistoryDiscountsAndBonuses(1, 'amountRate')"></i></div>
                <div class="item-price text__color color--error">
                  {{cargoItemService.getTotalDiscountsRate(cargo)
                  | currency:'COP':'code'}}
                </div>
              </div>

            </mat-list-item>
            <!-- Valor de Descuentos flete -->
            <mat-list-item class="row h-auto" *ngIf="cargoItemService.getTotalDiscounts(cargo)">
              <div class="w-100 item-financial">
                <div>Valor de los Descuentos al Flete <i class="fas fa-history color-secondary-dark cursor-pointer"
                    matTooltip="Historico de descuentos" matTooltipPosition="above"
                    (click)="openHistoryDiscountsAndBonuses(1, 'amount')"></i></div>
                <div class="item-price  text__color color--error">
                  {{cargoItemService.getTotalDiscounts(cargo) |
                  currency:'COP':'code'}}
                </div>
              </div>

            </mat-list-item>
            <!-- Valor de la tarifa -->
            <mat-list-item class="row h-auto" permission-display [module]="permission.cargo.module"
              [functionality]="permission.cargo.showRateCargo"
              *ngIf="(cargoItemService.getTotalDiscountsRate(cargo) || cargoItemService.getTotalBonusesRate(cargo))">
              <div class="w-100 item-financial">
                <div>Valor final de la Tarifa (aplicando descuentos y bonificaciones)</div>
                <div class="item-price">
                  {{cargo?.shippingCost?.rate? (cargo?.shippingCost.rate|
                  currency:'COP':'code'):''}}
                </div>
              </div>

            </mat-list-item>
            <!-- Valor del flete total  -->
            <mat-list-item class="row h-auto"
              *ngIf="cargoItemService.getTotalDiscounts(cargo) || cargoItemService.getTotalBonuses(cargo) || cargoItemService.getTotalAdditionalCosts(cargo)">
              <div class="w-100 item-financial">
                <div>Valor final del Flete (aplicando descuentos, bonificaciones y servicios adicionales)</div>
                <div class="item-price">
                  {{cargoItemService.getRealCost(cargo) |
                  currency:'COP':'code'}}
                </div>
              </div>

            </mat-list-item>
          
            <!-- Minimo valor permitido SICETAC -->
            <mat-list-item class="row h-auto" *ngIf="cargo?.minimumApprovedValueSicetac">
              <div class="w-100 item-financial">
                <div>Mínimo valor flete permitido por SICETAC</div>
                <div class="item-price color-secondary-dark">
                  {{minimumApprovedValueSicetacFormatted ? minimumApprovedValueSicetacFormatted : '-'}}
                </div>
              </div>
            </mat-list-item>

            <!--Valor de los servicios adiciones tarifa-->
            <mat-list-item class="row h-auto"
              *ngIf="cargoItemService.getTotalAdditionalRate(cargo) && hasAdditionalCostsPermission" permission-display
              [module]="permission.cargo.module" [functionality]="permission.cargo.showRateCargo">
              <div class="w-100 item-financial">
                <div>Valor de los servicios adicionales tarifa</div>
                <div class="item-price">
                  {{cargoItemService.getTotalAdditionalRate(cargo) |
                  currency:'COP':'code'}}
                </div>
              </div>

            </mat-list-item>
            <!--Valor de los servicios adiciones flete-->
            <mat-list-item class="row h-auto"
              *ngIf="cargoItemService.getTotalAdditionalCosts(cargo) && hasAdditionalCostsPermission" permission-display
              [module]="permission.cargo.module" [functionality]="permission.cargo.showCostCargo">
              <div class="w-100 item-financial">
                <div>Valor de los servicios adicionales flete</div>
                <div class="item-price">
                  {{cargoItemService.getTotalAdditionalCosts(cargo) |
                  currency:'COP':'code'}}
                </div>
              </div>

            </mat-list-item>
            <!-- Valor de los servicios adicionales -->
            <mat-list-item class="row h-auto" *ngIf="hasAdditionalCostsPermission">
              <div class="w-100 item-financial">
                <div>Valor de los servicios adicionales</div>
                <div class="item-price underline color-secondary-dark" (click)="showAdditionalServiceCargo()">
                  {{ cargo?.additionalCosts?.length ?
                  'Ver Servicios Adicionales' :
                  'Agregar Servicios Adicionales'}}
                </div>
              </div>

            </mat-list-item>
            <mat-list-item class="row h-auto" *ngIf="canOpenAdditionalCostDeleted && hasAdditionalCostsPermission">
              <div class="w-100 item-financial">
                <div>Servicios adicionales eliminados</div>
                <div class="item-price underline color-secondary-dark" (click)="openHistoryAdditionalCosts()">
                  Ver listado
                </div>
              </div>

            </mat-list-item>
            <!-- Valor pagado -->
            <mat-list-item class="row h-auto">
              <div class="w-100 item-financial">
                <div>Valor pagado<i class="fas fa-history color-secondary-dark cursor-pointer"
                    *ngIf="!utils.isEmpty(cargo?.shippingCost?.payments)" matTooltip="Historico de Pagos"
                    matTooltipPosition="above" (click)="openHistoryPayments(3)"></i></div>
                <div class="item-price color-secondary-dark">
                  {{cargo?.shippingCost?.totalPaid ? (cargo?.shippingCost?.totalPaid | currency:'COP':'code') : '-'}}
                </div>
              </div>

            </mat-list-item>
          </mat-list>
        </div>
      </mat-card>
      <!-- Aprobación -->
      <mat-card class="container-financiera" *ngIf="!canEditCargo" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.viewApproveInfo">
        <div class="row">
          <div class="container-title-financial col-md-12">
            <span class="text-financial">Aprobación</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 content-financial">
            <div class="row">
              <div class="col-md-12">
                <div class="item-financial">
                  <div>Estado Aprobación: </div>
                  <div class="item-price">
                    {{cargo && utils.isDefined(cargo?.approval) ? (cargo?.approval === 'Approved' ?
                    'Aprobado' : cargo?.approval === 'Rejected' ? 'Rechazado' : 'Pendiente de Aprobación') :
                    ''}}
                  </div>
                </div>

              </div>
              <div class="col-md-12">
                <div class="item-financial">
                  <div>Responsable de la Aprobación: </div>
                  <div class="item-price">
                    {{cargo && utils.isDefined(cargo?.approvalUserName) ? (cargo?.approvalUserName) :
                    ''}}
                  </div>
                </div>

              </div>
              <div class="col-md-12">
                <div class="item-financial">
                  <div>Fecha de Aprobación:</div>
                  <div class="item-price">
                    {{cargo && utils.isDefined(cargo?.approvalDate) ? (cargo?.approvalDate |
                    dateFormat):
                    ''}}
                  </div>
                </div>

              </div>
              <div class="col-md-12" *ngIf="cargo?.approval === 'Rejected'">
                <div class="item-financial">
                  <div>Razón de la No Aprobación: </div>
                  <div class="item-price">
                    {{utils.isDefined(cargo?.reasonApproval) ? (cargo?.reasonApproval) :
                    ''}}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <!-- <br>

      <mat-card class="container-financiera">
        <div class="row">
          <div class="container-title-financial col-md-12">
            <span class="text-financial">Tarifas y costos</span>
          </div>
        </div>
        <div class="row col-md-12 item-price underline color-secondary-dark" (click)="showRateCostAdditionalService()">
          Editar tarifas y costos
        </div>
      </mat-card> -->

      <br>
      <!-- Facturación -->
      <mat-card class="container-financiera mb-3" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.showBillingData"
        *ngIf="!canEditCargo || !uploadDownloadMapService?.getHasSiigoIntegration()">
        <div class="row">
          <div class="container-title-financial col-md-12">
            <span class="text-financial">Facturación</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <ng-container *ngIf="!canEditCargo">
                <div class="col-md-6">
                  <div>Numero de Factura: </div>
                  <div class="item-price">
                    {{utils.isDefined(cargo?.shippingCost?.billId) ?
                    (cargo?.shippingCost?.billId) : '-'}}
                  </div>
                </div>
                <div class="col-md-6">
                  <div>Fecha de Facturación: </div>
                  <div class="item-price">
                    {{cargo?.expeditionDateBill ? cargo?.expeditionDateBill : '-'}}
                  </div>
                </div>
                <div class="col-md-6">
                  <div>Numero de Comprobante de Ingreso: </div>
                  <div class="item-price">
                    {{utils.isDefined(cargo?.shippingCost?.incomeId) ?
                    (cargo?.shippingCost.incomeId) : '-'}}
                  </div>
                </div>
                <div class="col-md-3">
                  <div>
                    Comprobante Contable:
                    <i permission-display [module]="permission.payments.module"
                      [functionality]="permission.payments.downloadJournalCargoPDF" *ngIf="cargo.journalIdSiigo"
                      class="fas fa-file-pdf underline color-secondary-dark cursor-pointer"
                      (click)="getCargoJournalIdPDF()"></i>
                  </div>
                  <div class="item-price">
                    {{cargo && utils.isDefined(cargo?.journalIdSiigo) ?
                    (cargo?.journalIdSiigo) : ''}}
                    <!-- Generate Journal -->
                    <label *ngIf="cargo?.shippingCost?.payments?.length && cargo?.journalError?.hasError"
                      class="underline color-secondary-dark cursor-pointer" (click)="confirmGenerateJournal()"
                      permission-display [module]="permission.payments.module"
                      [functionality]="permission.payments.generateJournalCargo">
                      Generar Comprobante
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>Fecha Expedición Comprobante: </div>
                  <div class="item-price">
                    {{cargo?.expeditionDateJournal ? cargo?.expeditionDateJournal : '-'}}
                  </div>
                </div>
                <div class="col-md-6">
                  <div>Responsable de la facturación: </div>
                  <div class="item-price">
                    {{cargo?.billedBy?.userName ? cargo?.billedBy?.userName : '-'}}
                  </div>
                </div>

                <div class="col-md-6">
                  <div>ID del responsable: </div>
                  <div class="item-price">
                    {{cargo?.billedBy?.userId ? cargo?.billedBy?.userId : '-'}}
                  </div>
                </div>
                <div class="col-md-6">
                  <div>Fecha de Facturación:</div>
                  <div class="item-price">
                    {{cargo?.billedBy?.date ? (cargo?.billedBy.date |
                    dateFormat) :'-'}}
                  </div>
                </div>
              </ng-container>

              <div class="col-md-6" *ngIf="!uploadDownloadMapService?.getHasSiigoIntegration()">
                <div>Centro de costo</div>
                <div class="item-price">
                  {{cargo?.costCenter?.name ? cargo?.costCenter?.name : '-'}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div permission-display [module]="permission.cargo.module"
          [functionality]="permission.cargo.assignUnassignCostCenterToService"
          *ngIf="!uploadDownloadMapService?.getHasSiigoIntegration()">
          <div class="row mt-3">
            <div class="container-title-financial col-md-12 mb-0">
              <span class="text-financial">Centro de costo</span>
            </div>
          </div>
          <div class="row">
            <app-assign-cost-center [company]="ownerCompany" [cargo]="cargo" [initialValue]="cargo?.costCenter"
              (emitResult)="processCostCenter($event)" class="col-md-12"></app-assign-cost-center>
          </div>
        </div>
      </mat-card>

      <!-- </div> -->
      <!-- </ng-container> -->
      <!-- Editar Valor de envío y Forma de pago -->
      <div *ngIf="canEditCargo && cargo.shippingCost" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.showEditFinancialInformationModule">
        <div class="row row-title">
          <div class="col-md-12 wrapper-label margin">
            <label class="title-1 w-auto">
              Valor de envío y forma de pago
            </label>
            <button type="button" (click)="updateShippingCost()" class="btn float-right color-primary">
              <i class="far fa-save"></i> Guardar
            </button>
          </div>
        </div>
        <!-- Información - Valor de envío y Forma de pago -->
        <div class="row border-bottom">
          <app-shipping-cost [cargo]="cargo" (emitToParent)="getCargoDetailById($event)"
            class="w-100 mb-3"></app-shipping-cost>
        </div>
      </div>
      <!-- Resumen de la negociación del servicio -->
      <div *ngIf="isNegotiation && authService.cargoActive.negotiation.driver" permission-display
        [module]="permission.cargo.module" [functionality]="permission.cargo.showEditServiceNegotiationModule">
        <div class="row">
          <div class="col-md-12 wrapper-label margin">
            <label for="" class="title-1 w-auto">
              Resumen de la negociación del servicio
            </label>
          </div>
        </div>
        <!-- Información - Resumen de la negociación del servicio -->
        <div class="row row-information">
          <!-- Flete inicial -->
          <div class="col-md-3" permission-display [module]="permission.cargo.module"
            [functionality]="permission.cargo.showTotalCostCargo">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Flete inicial del servicio</span>
                <label class="label-field">{{utils.isDefined(cargo?.shippingCost?.totalCost) ?
                  (cargo?.shippingCost.totalCost | currency:'COP':'code') : '-'}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Oferta del conductor -->
          <div class="col-md-3">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Oferta del conductor</span>
                <label class="label-field">{{utils.isDefined(authService.cargoActive.negotiation.negotiatedValueDriver)
                  ? (authService.cargoActive.negotiation.negotiatedValueDriver | currency:'COP':'code') :
                  '-'}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Tu contraoferta -->
          <div class="col-md-3">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Tu contraoferta</span>
                <label class="label-field">{{utils.isDefined(authService.cargoActive.negotiation.negotiatedValueCreator)
                  ? (authService.cargoActive.negotiation.negotiatedValueCreator | currency:'COP':'code') :
                  '-'}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Respuesta del conductor -->
          <div class="col-md-3">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Estado</span>
                <label class="label-field">
                  {{getStateNegotiation(authService.cargoActive.negotiation.state)}}
                </label>
              </div>
            </mat-card-content>
          </div>
        </div>
      </div>

      <!-- Resumen de la negociación de la solicitud -->
      <div *ngIf="isNegotiationRequest && authService.cargoActive.negotiationRequest.companyId" permission-display
        [module]="permission.cargo.module" [functionality]="permission.cargo.showEditRequestNegotiationModule">
        <div class="row">
          <div class="col-md-12 wrapper-label margin">
            <label for="" class="title-1 w-auto">
              Resumen de la negociación de la solicitud
            </label>
          </div>
        </div>
        <!-- Información - Resumen de la negociación de la solicitud  -->
        <div class="row row-information">
          <!-- Flete inicial -->
          <div class="col-md-3" permission-display [module]="permission.cargo.module"
            [functionality]="permission.cargo.showTotalCostCargo">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Flete inicial del servicio</span>
                <label class="label-field">{{utils.isDefined(cargo?.shippingCost?.totalCost) ?
                  (cargo?.shippingCost.totalCost | currency:'COP':'code') : '-'}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Oferta de la empresa -->
          <div class="col-md-3">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Oferta de la Empresa: {{authService.cargoActive.negotiationRequest.companyId}}</span>
                <label
                  class="label-field">{{utils.isDefined(authService.cargoActive.negotiationRequest.negotiatedValueCompany)
                  ? (authService.cargoActive.negotiationRequest.negotiatedValueCompany | currency:'COP':'code') :
                  '-'}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Tu contraoferta -->
          <div class="col-md-3">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Tu contraoferta</span>
                <label
                  class="label-field">{{utils.isDefined(authService.cargoActive.negotiationRequest.negotiatedValueCreator)
                  ? (authService.cargoActive.negotiationRequest.negotiatedValueCreator | currency:'COP':'code') :
                  '-'}}</label>
              </div>
            </mat-card-content>
          </div>
          <!-- Respuesta del conductor -->
          <div class="col-md-3">
            <mat-card-content>
              <div class="md-form md-form-reverse">
                <span>Estado</span>
                <label class="label-field">
                  {{getStateNegotiation(authService.cargoActive.negotiationRequest.state)}}
                </label>
              </div>
            </mat-card-content>
          </div>
        </div>
      </div>
      <!-- Finalización -->
      <mat-card class="container-financiera"
        *ngIf="isRootNit && cargo?.state=='End service' && cargo?.finishedByFingerprint" permission-display
        [module]="permission.cargo.module" [functionality]="permission.cargo.showEndServiceInfoModule">
        <div class="row">
          <div class="container-title-financial col-md-12">
            <span class="text-financial">Finalización del servicio</span>
          </div>
        </div>
        <div class="row content-financial">
          <div class="col-12">
            <div class="item-financial">
              <div>Responsable de la Finalización:</div>
              <div class="item-price">
                {{ cargo?.finishedByFingerprint?.userName ? cargo?.finishedByFingerprint?.userName : '-'}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="item-financial">
              <div>ID del responsable:</div>
              <div class="item-price">
                {{ cargo?.finishedByFingerprint?.userId ? cargo?.finishedByFingerprint?.userId : '-'}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="item-financial">
              <div>Fecha de Finalización:</div>
              <div class="item-price">
                {{ cargo?.finishedByFingerprint?.date ? (cargo?.finishedByFingerprint?.date | dateFormat) : '-'}}
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <!-- Eliminación -->
      <mat-card class="container-financiera"
        *ngIf="isRootNit && cargo?.state==cargoStateEnum.DELETED && cargo?.deletedFingerPrint" permission-display
        [module]="permission.cargo.module" [functionality]="permission.cargo.showDeleteServiceInfoModule">
        <div class="row">
          <div class="container-title-financial col-md-12">
            <span class="text-financial">Eliminación del servicio</span>
          </div>
        </div>
        <div class="row content-financial">
          <div class="col-12">
            <div class="item-financial">
              <div>Responsable de la Eliminación</div>
              <div class="item-price">
                {{cargo?.deletedFingerPrint?.fingerPrint?.userName? cargo?.deletedFingerPrint.fingerPrint.userName:"-"}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="item-financial">
              <div>ID del responsable:</div>
              <div class="item-price">
                {{cargo?.deletedFingerPrint?.fingerPrint?.userId? cargo?.deletedFingerPrint.fingerPrint.userId:"-"}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="item-financial">
              <div>Fecha de Eliminación:</div>
              <div class="item-price">
                {{cargo?.deletedFingerPrint?.fingerPrint?.date? (cargo?.deletedFingerPrint.fingerPrint.date |
                dateFormat) :'-'}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="item-financial">
              <div>Observación:</div>
              <div class="item-price">
                {{cargo?.deletedFingerPrint?.observation? cargo?.deletedFingerPrint.observation:"-"}}
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <!--Zona de publicación-->
      <mat-card class="container-financiera">
        <div class="row">
          <div class="container-title-financial col-md-12">
            <span class="text-financial">Zona de publicación</span>
          </div>
        </div>
        <div class="row"
          *ngIf="canEditCargo && !cargo?.confirmedDriver && canEditPublicationZone; else cantEditPublicationZone">
          <div class="col-md-12">
            <mat-radio-group [formControl]="zoneControl" class="flex-column">
              <mat-radio-button value="private"><span class="zone-radio-button">
                  <b>Privada:</b> Visible solo para los conductores
                  directamente asociados a la compañía.
                </span></mat-radio-button>
              <mat-radio-button value="public"><span class="zone-radio-button">
                  <b>Pública:</b> Visible para todos los
                  conductores de la comunidad LoggiApp.
                </span></mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="button-container w-100 justify-content-end">
            <button mat-raised-button color="primary" class="bg-color-primary" (click)="savePublishZone()">
              Guardar
            </button>
          </div>
        </div>
        <ng-template #cantEditPublicationZone>
          <div class="row pl-3">
            <p *ngIf="cargo?.zone==='private'"><b>Privada:</b> Visible solo para los conductores
              directamente asociados a la compañía.</p>
            <p *ngIf="cargo?.zone==='public'"><b>Pública:</b> Visible para todos los
              conductores de la comunidad LoggiApp.</p>
            <p *ngIf="!cargo?.zone">No se ha registrado un tipo de zona de publicación</p>
          </div>
        </ng-template>
      </mat-card>
      <!-- Asignación -->
      <mat-card class="container-financiera" *ngIf="isRootNit && cargo?.assignedVehicleFingerPrint" permission-display
        [module]="permission.cargo.module" [functionality]="permission.cargo.showAssignedServiceInfoModule">
        <div class="row">
          <div class="container-title-financial col-md-12">
            <span class="text-financial">Asignación del {{isEscortedService? 'escolta' : 'vehículo'}}</span>
          </div>
        </div>
        <div class="row content-financial">
          <div class="col-md-12">
            <div class="item-financial">
              <div>Responsable de la Asignación</div>
              <div class="item-price">
                {{cargo?.assignedVehicleFingerPrint?.userName? cargo?.assignedVehicleFingerPrint.userName:"-"}}
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="item-financial">
              <div>ID del responsable:</div>
              <div class="item-price">
                {{cargo?.assignedVehicleFingerPrint?.userId? cargo?.assignedVehicleFingerPrint.userId:"-"}}
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="item-financial">
              <div>Fecha de Asignación:</div>
              <div class="item-price">
                {{cargo?.assignedVehicleFingerPrint?.date? (cargo?.assignedVehicleFingerPrint.date |
                dateFormat) :'-'}}
              </div>
            </div>

          </div>
        </div>
      </mat-card>
      <!-- Confirmación -->
      <mat-card class="container-financiera mb-3" *ngIf="isRootNit && cargo?.confirmedDriver" permission-display
        [module]="permission.cargo.module" [functionality]="permission.cargo.showApprovalServiceInfoModule">
        <div class="row">
          <div class="container-title-financial col-md-12">
            <span class="text-financial">Confirmación del {{isEscortedService? 'escolta': 'vehículo'}}</span>
          </div>
        </div>
        <div class="row content-financial">
          <div class="col-md-12">
            <div class="item-financial">
              <div>Responsable de la Confirmación</div>
              <div class="item-price">
                {{cargo?.confirmedDriverFingerprint?.userName? cargo?.confirmedDriverFingerprint.userName:"-"}}
              </div>
            </div>

          </div>
          <div class="col-md-12">
            <div class="item-financial">
              <div>ID del responsable:</div>
              <div class="item-price">
                {{cargo?.confirmedDriverFingerprint?.userId? cargo?.confirmedDriverFingerprint.userId:"-"}}
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="item-financial">
              <div>Fecha de Confirmación:</div>
              <div class="item-price">
                {{cargo?.confirmedDriverFingerprint?.date?(cargo?.confirmedDriverFingerprint.date |
                dateFormat) :'-'}}
              </div>
            </div>

          </div>
        </div>
      </mat-card>

      <!-- Conductor asignado -->
      <div class="row m-0 p-0" *ngIf="isNegotiation || (driver && driver.phone && vehicle && vehicle.id)"
        permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.showDriverInfoModule">
        <div class="col-md-12 wrapper-label margin mt-4">
          <label for="" class="title-1 w-auto">
            {{isNegotiation ? 'Conductor en negociación' : isEscortedService ? 'Escolta asignado':'Conductor asignado'}}
          </label>
        </div>
      </div>
      <app-driver-cargo class="row row-information" [cargo]="cargo" *ngIf="driver && 
        driver.phone && 
        vehicle && 
        vehicle.id" [driver]="driver" [vehicle]="vehicle" [isNegotiation]="isNegotiation" [secondDriver]="secondDriver"
        (emitDriver)="updateDriver($event)" (emitVehicle)="updateVehicle($event)"
        (containerInspectionSaved)="onRefreshCargo($event)" (refreshLoad)="getCargoDetailById($event)"
        permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.showDriverInfoModule">
      </app-driver-cargo>

      <!-- Vehículo escoltado -->
      <div class="row" *ngIf="cargo?.escortedVehicle" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.showEscortInfoModule">
        <div class="col-md-12 wrapper-label margin">
          <label for="" class="title-1 w-auto">
            Vehículo escoltado
          </label>
        </div>
      </div>
      <app-driver-escorted-vehicle class="row row-information" [cargo]="cargo" *ngIf="cargo?.escortedVehicle"
        permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.showEscortInfoModule">
      </app-driver-escorted-vehicle>
      <!-- Acciones -->
      <div class="row" *ngIf="!isDialog" permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.showActionsServiceModule">
        <div *ngIf="cargo?.manifestError?.error && cargo?.ministryError" class="col-12 m-0 p-0">
          <div class="manifest-alert d-flex d-flex flex-column">
            <p class="m-0 p-0 text-center manifest-alert__title">Errores en el manifiesto</p>
            <span *ngFor="let manifestError of manifestErrors" class="d-flex align-items-center">
              <i class="fas fa-exclamation-circle mr-2"></i>
              <div>{{manifestError}}</div>
            </span>
          </div>
        </div>
        <div class="col-md-12 px-0">
          <mat-card-actions
            class="actions-card d-flex flex-column flex-sm-row justify-content-center justify-content-sm-around align-items-center flex-wrap mx-0 px-0">
            <button mat-raised-button color="primary" class="mx-0 mb-2" (click)="goToList()">
              Ir {{getListName()? 'a ' + getListName() : 'al listado'}}
            </button>
            <app-generate-manifest-action *ngIf="cargo?.ministry && !cargo?.manifestAuthorization" [cargo]="cargo"
              type="button-detail" (refreshLoad)="getCargoDetailById($event)"></app-generate-manifest-action>
            <button mat-raised-button color="primary" *ngIf="canCreateCargoFromRequest" (click)="createCargo()"
              permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.createService">
              {{cargo?.ministry? 'Crear remesas' : 'Crear servicio'}}
            </button>
            <!-- Negotiation Cargo -->
            <button mat-raised-button color="primary" *ngIf="isRequest||isOffer" (click)="acceptOffer()">
              Aceptar oferta
            </button>
            <button mat-raised-button class="primary-secondary" color="primary" *ngIf="isRequest||isOffer"
              (click)="rejectOffer()">
              Rechazar oferta
            </button>
            <!-- Request  -->
            <!--button mat-raised-button color="primary" *ngIf="!isNegotiation && listActive === 'request' && isSubCompany"
              (click)="openModalAcceptRequest()">
              Aceptar oferta
            </button>
            <button mat-raised-button class="primary-secondary mb-2" color="primary"
              *ngIf="!isNegotiation && listActive === 'request' && isSubCompany"
              (click)="openModal('modalNegotiationRequest')">
              Negociar oferta
            </button-->
            <!-- Parent Negotiation Rquest -->
            <app-cargo-options *ngIf="listActive !== 'serviceRequests'" [type]="'button'" [cargo]="cargo"
              (refreshCargo)="onRefreshCargo($event)" (openVehicle)="openVehicle()" (showInspection)="showInspection()"
              class="mb-2">
            </app-cargo-options>
            <div class="cancel-cargo"
              *ngIf="[cargoStateEnum.ACCEPTED, cargoStateEnum.CREATED, cargoStateEnum.REQUEST].includes(cargo?.state) && cargo?.driver"
              permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.cancelCargo">
              <a (click)="cancelCargo()">Desasociar Vehiculo</a>
            </div>
          </mat-card-actions>
        </div>
      </div>
    </div>
  </div>
</aside>

<!-- Modal Refuse Cargo -->
<ng-template #modalRefuseCargo let-modal>
  <div class="modal-body wrapper-modal" *ngIf="cargo?.id">
    <p class="title">
      ¿Quieres enviarle al conductor tu contraoferta?
    </p>
    <mat-form-field appearance="outline" class="px-3">
      <mat-label>Valor<span class="asterisk">*</span></mat-label>
      <input matInput type="text" mask="separator.0" thousandSeparator="." placeholder="Ejm: 1.000.000"
        [formControl]="negotiationValue">
      <mat-error *ngIf="negotiationValue.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>
    <div class="button-container my-3">
      <button mat-raised-button color="primary" class="bg-color-primary mx-2" (click)="negotiateCargo()">Enviar
        contraoferta</button>
      <div class="mt-2 mx-2">
        <a (click)="refuseCargo()">No, mantener flete inicial</a>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal Negotiation Request -->
<ng-template #modalNegotiationRequest let-modal>
  <div class="modal-body wrapper-modal" *ngIf="cargo?.id">
    <p class="title">
      Escribe el valor
    </p>
    <mat-form-field appearance="outline" class="px-3">
      <mat-label>Valor<span class="asterisk">*</span></mat-label>
      <input matInput type="text" mask="separator.0" thousandSeparator="." placeholder="Ejm: 1.000.000"
        [formControl]="negotiationRequestValue">
      <mat-error *ngIf="negotiationRequestValue.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>
    <div class="button-container my-3">
      <button mat-raised-button color="primary" class="bg-color-primary" (click)="negotiateRequest()"
        mdbWavesEffect>Enviar contraoferta</button>
    </div>
  </div>
</ng-template>

<!-- Modal Confirm Refuse Request -->
<app-modal #modalConfirmRefuseRequest [titleModal]="'¿Está seguro que quiere rechazar esta negociación?'"
  [titleCancel]="titles.titleModalNo" [titleConfirm]="titles.titleModalConfirm" (onCancel)="onCancel($event)"
  (onConfirm)="onConfirmRefuseRequest()"></app-modal>

<!-- Modal Confirm Accept Request -->
<app-modal #modalConfirmAcceptRequest
  [titleModal]="'¿Está seguro que quiere aceptar esta ' + (isParentCompany ? 'negociación' : 'oferta' ) +'?'"
  [titleCancel]="titles.titleModalNo" [titleConfirm]="titles.titleModalConfirm" (onCancel)="onCancel($event)"
  (onConfirm)="onConfirmAcceptRequest()"></app-modal>

<!-- Modal Confirm Accept Cargo -->
<app-modal #modalConfirmAcceptCargo [titleModal]="'¿Está seguro que quiere aceptar esta oferta?'"
  [titleCancel]="titles.titleModalNo" [titleConfirm]="titles.titleModalConfirm" (onCancel)="onCancel($event)"
  (onConfirm)="onConfirmAcceptCargo()"></app-modal>

<!-- Modal Confirm Negotiation Cargo -->
<ng-template #modalConfirmNegotiationCargo let-modal>
  <div class="modal-body wrapper-modal" *ngIf="cargo?.id">
    <p>
    <figure class="container-icon-correct">
      <img src="/assets/svg/icons/icon-correct-purple.svg" alt="">
    </figure>
    </p>
    <p class="title">
      Tu contraoferta ha sido enviada
    </p>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="closeModalConfirmNegotiation()" mdbWavesEffect>
        Volver a servicios en negociación
      </button>
    </mat-card-actions>
  </div>
</ng-template>

<!-- Register Payment -->
<app-register-cargo-payment #modalRegisterCargoPayment [cargo]="cargo" (onRegisterPayment)="onRegisterPayment($event)">
</app-register-cargo-payment>

<!-- List Payments -->
<app-list-cargo-payments #modalListCargoPayments *ngIf="cargo?.shippingCost?.payments" [cargo]="cargo"
  [payments]="cargo?.shippingCost?.payments"></app-list-cargo-payments>

<ng-template #modalCargoPDF let-modal>
  <div class="modal-body wrapper-modal modal-content-PDF" *ngIf="cargoPDF">
    <embed [src]="cargoPDF" type="application/pdf">
  </div>
</ng-template>

<!-- Modal Error RNDC Approve Cargo -->
<ng-template #modalErrorRNDCApproveCargo let-modal>
  <div class="modal-body wrapper-modal">
    <p>
    <figure class="container-icon-correct">
      <img src="/assets/svg/icons/icon-error-purple.svg" alt="">
    </figure>
    </p>
    <p class="title">
      ¡Ocurrió un error al aprobar el servicio!
    </p>
    <div>
      <ul>
        <li class="title-2" *ngFor="let error of errorRNDC">
          {{error}}
        </li>
      </ul>
    </div>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="dismissAllModals()">Aceptar</button>
    </mat-card-actions>
  </div>
</ng-template>