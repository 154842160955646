export enum AmountsCargoEnum {
  LOW_START = -Infinity,
  LOW_END = 500000000,
  MEDIUM_START = 500000001,
  MEDIUM_END = 1000000000,
  HIGH_START = 1000000001,
  HIGH_END = Infinity,

  LOW_START_SHORT = 0,
  LOW_END_SHORT = 500,
  MEDIUM_END_SHORT = 1000,
  HIGH_START_SHORT = 1000,

  ESCORT_SERVICE_ALERT_MINS = 40,
  EMPTY_ALERT_MINS = 720,
  LOW_ALERT_MINS = 150,
  MEDIUM_ALERT_MINS = 90,
  HIGH_ALERT_MINS = 45,

  ALARM_YELLOW_MINS_STILL = 60,
  ALARM_ORANGE_MINS_STILL = 120,

  UNCARED_ALARM_MINS = 5,

  MAX_WEIGHT_ALLOWED_KG = 35000,
  MAX_WEIGHT_ALLOWED_GL = 8981,
  MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI = 65,
  MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES = 100,

  MIN_DOCUMENT_LENGTH = 3,
  MAX_DOCUMENT_LENGTH = 15,
  MAX_NIT_LENGTH = 10,
};
