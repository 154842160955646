import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { FormBuilder, FormControl } from '@angular/forms';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Router } from '@angular/router';
import { ModelCargo } from '../../resources/modelCargo';
import { Patterns } from 'src/app/core/resources/patterns';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
@Component({
  selector: 'app-box-seal',
  templateUrl: './box-seal.component.html',
  styleUrls: ['./box-seal.component.scss'],
  providers: [AuthService, Global, BoxSealComponent, ModelCargo]
})
export class BoxSealComponent implements OnInit {
  permission = Permission;
  dateField = new FormControl("");
  todayDate: Date = new Date();
  active: boolean = false;
  listLicensePlate = [];
  containerList = [];
  showDate: boolean = false;
  patternContainer = new RegExp(this.patterns.CONTAINER_FORMAT_FULL.source);
  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public formBuilder: FormBuilder,
    private router: Router,
    private patterns: Patterns,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private permissionRole: PermissionRole
  ) { }


  ngOnInit(): void {
    this.assignContainers();
  }

  public submitContainers() {
    if (this.manualCreationCargoService.isValidContainerData()) {
      this.nextStep();
    } else {
      let invalidContainers = [];
      let invalidDates = [];
      let datePosition;
      let containerPosition;
      this.listLicensePlate.forEach((vehicle, index) => {
        if (vehicle.container) {
          if (!this.patternContainer.test(vehicle.container)) {
            containerPosition = `${(index + 1).toString()} (${vehicle.name})`;
            invalidContainers.push(containerPosition)
          }
          if (!vehicle.containerExpirationDate) {
            datePosition = `${(index + 1).toString()} (${vehicle.name})`;
            invalidDates.push(datePosition);
          }
        }
      })
      invalidDates.length > 0 ? this.snackBarService.openSnackBar(Fmt.string(FormMessages.INVALID_DATE, invalidDates.join(", No. ")), undefined, 'alert') : '';
      invalidContainers.length > 0 ? this.snackBarService.openSnackBar(Fmt.string(FormMessages.INVALID_CONTAINER, invalidContainers.join(", No. ")), undefined, 'alert') : '';
    }
  }

  nextStep() {
    this.manualCreationCargoService.getComponentActive().setId(2);
    this.manualCreationCargoService.getComponentActive().getStep().setId(6);
    this.router.navigate([this.manualCreationCargoService.steps.sizeLoad.url]);
  }


  onKeyUp(event, vehicle, typeInput) {
    if (typeInput === 'container') {
      vehicle.container = event.target.value;
      this.manualCreationCargoService.form.get('container').setValue(vehicle.container);
      if (vehicle && vehicle.container) {
        this.showDate = true;
        this.patternContainer.test(vehicle.container) ? vehicle.valid = false : vehicle.valid = true;
      } else {
        this.showDate = false;
        vehicle.containerExpirationDate = '';
      }
    } else if (typeInput === 'seal') {
      vehicle.seal = event.target.value;
      this.manualCreationCargoService.form.get('seal').setValue(vehicle.seal);
    } else if (typeInput === 'booking'){
      vehicle.booking = event.target.value;
      this.manualCreationCargoService.form.get('booking').setValue(vehicle.booking);
    }
    this.manualCreationCargoService.setVehiclesWithLicensePlate(this.listLicensePlate);
  }

  assignContainers() {
    this.listLicensePlate = this.manualCreationCargoService.getVehiclesWithLicensePlate();
  }


  selectedDate(event, vehicle) {
    if (event.value) {
      vehicle.containerExpirationDate = event.value;
      const expirationDate = DateManager.formatDate(vehicle.containerExpirationDate, 'YYYY-MM-DD HH:mm ZZ', 'MM/DD/YYYY')
      this.manualCreationCargoService.form.get('containerExpirationDate').setValue(expirationDate);
    }
    this.manualCreationCargoService.setVehiclesWithLicensePlate(this.listLicensePlate);
  }

  stepBack() {
    const serviceTypeName = this.manualCreationCargoService.cargoForm.controls.cargoModel['controls'].serviceType.controls.name.value;
    if (serviceTypeName === 'Carga expresa') {
      this.manualCreationCargoService.getComponentActive().setId(2);
      this.manualCreationCargoService.getComponentActive().getStep().setId(4);
      this.router.navigate([this.manualCreationCargoService.steps.typeMerchandise.url]);
    }
    else {

      if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.assignLicensePlate)) {
        this.manualCreationCargoService.getComponentActive().setId(2);
        this.manualCreationCargoService.getComponentActive().getStep().setId(3);
        this.router.navigate([this.manualCreationCargoService.steps.assignVehicles.url]);
      } else {
        this.manualCreationCargoService.getComponentActive().setId(2);
        this.manualCreationCargoService.getComponentActive().getStep().setId(2);
        this.router.navigate([this.manualCreationCargoService.steps.quantityVehicles.url]);
      }
    }
  }
}
