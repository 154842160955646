import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { PlanningRoute } from 'src/app/core/interfaces/planning-route';
import { RouteItinerary } from 'src/app/core/interfaces/route-itinerary';
import { RouteReferenceAuthorizedPoint } from 'src/app/core/interfaces/route-reference-authorized-point';
import { DateManager } from 'src/app/core/managers/date.manager';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Global } from 'src/app/core/resources/global';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Utils } from 'src/app/core/resources/utils';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-cargo-planning-route',
  templateUrl: './cargo-planning-route.component.html',
  styleUrls: ['./cargo-planning-route.component.scss']
})
export class CargoPlanningRouteComponent implements OnInit {
  selectedItinerary: string = '';
  permission = Permission;
  cargo: Cargo;
  routePlan: PlanningRoute;
  itineraries: RouteItinerary[] = [];
  cargoNearnessIndexes: { [key: string]: { address: string, index: number }[] } = {};

  constructor(
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private global: Global,
    private utils: Utils,
    private router: Router,
    private permissionRole: PermissionRole,
    private planningRouteService: PlanningRouteService,
    public dialogRef: MatDialogRef<CargoPlanningRouteComponent>,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: {
      mode: "edit" | "detail",
      itineraryId: string,
      routePlanId?: string,
      cargo?: Cargo
    },
  ) {
    if (this.paramsDialog.itineraryId) this.selectedItinerary = this.paramsDialog.itineraryId;
    if (this.paramsDialog.cargo) this.cargo = this.paramsDialog.cargo;
  }

  ngOnInit() {
    if (this.paramsDialog.routePlanId && this.paramsDialog.mode === 'edit')
      this.getRouteById();
    else if (this.paramsDialog.itineraryId && this.paramsDialog.mode === 'detail')
      this.getItinerary(this.selectedItinerary);
    else {
      this.snackBarService.openSnackBar("Ocurrió un error al acceder al plan de ruta", undefined, 'error');
      this.dialogRef.close();
    }
  }

  private getRouteById() {
    this.spinner.show();
    this.planningRouteService.getRoute(this.paramsDialog.routePlanId).subscribe(
      (response: PlanningRoute) => {
        if (!response || !response.id || !response.itineraries || !response.itineraries.length || !response.active) {
          this.snackBarService.openSnackBar("Ocurrió un error al acceder al plan de ruta", undefined, 'error');
          this.spinner.hide();
          this.dialogRef.close();
          return;
        }
        this.routePlan = response;
        this.routePlan.itineraries.forEach(async (itinerary) => {
          await this.getItinerary(itinerary.id, itinerary);
        })
        this.spinner.hide();
      }, (error) => {
        this.snackBarService.openSnackBar("Ocurrió un error al acceder al plan de ruta", undefined, 'error');
        this.spinner.hide();
        this.dialogRef.close();
      }
    )
  }

  private async getItinerary(id: string, incompleteItinerary?: RouteItinerary) {
    try {
      const itinerary = await this.planningRouteService.getItinerary(id ? id : incompleteItinerary.id).toPromise();
      if (itinerary && itinerary.id) {
        this.itineraries.push(itinerary);
        if (this.cargo) {
          const indexes = this.planningRouteService.getCargoNearnessIndexes(this.cargo, itinerary);
          this.cargoNearnessIndexes[itinerary.id] = indexes;
        }
      }
      else if (incompleteItinerary) {
        this.itineraries.push(incompleteItinerary);
        if (this.cargo) {
          const indexes = this.planningRouteService.getCargoNearnessIndexes(this.cargo, incompleteItinerary);
          this.cargoNearnessIndexes[incompleteItinerary.id] = indexes;
        }
      }
    } catch (e) {
      if (incompleteItinerary) {
        this.itineraries.push(incompleteItinerary);
        if (this.cargo) {
          const indexes = this.planningRouteService.getCargoNearnessIndexes(this.cargo, incompleteItinerary);
          this.cargoNearnessIndexes[incompleteItinerary.id] = indexes;
        }
      }
    }
  }

  onSelectItinerary(itinerary: RouteItinerary, $event: MouseEvent) {
    $event.stopPropagation();
    if (!itinerary.active) return this.snackBarService.openSnackBar("El itinerario no está activo", undefined, 'alert');
    const nearnessIndexes = this.getNearnessIndexes(itinerary.id);
    if (!nearnessIndexes || !nearnessIndexes.length) return this.snackBarService.openSnackBar("No hay índices de cercanía para este itinerario", undefined, 'error');
    if (nearnessIndexes.every(index => index.index >= 0.7)) {
      this.selectedItinerary = itinerary.id;
      return;
    }
    const farIndex = nearnessIndexes.find(index => index && index.index < 0.7);
    this.snackBarService.openSnackBar(farIndex
      ? `No es posible asignar este itinerario, la dirección ${farIndex.address} no supera el umbral de cercanía requerido`
      : ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
  }

  public getDurationLabel(seconds: number): string {
    if (!seconds) return '';
    const durationMsg = [];
    const duration = DateManager.durationFormat(seconds, "seconds");
    if (duration.years > 0) durationMsg.push(`${duration.years} ${duration.years > 1 ? 'años' : 'año'}`);
    if (duration.months > 0) durationMsg.push(`${duration.months} ${duration.months > 1 ? 'meses' : 'mes'}`);
    if (duration.days > 0) durationMsg.push(`${duration.days} d`);
    if (duration.hours > 0) durationMsg.push(`${duration.hours} h`);
    if (duration.minutes > 0) durationMsg.push(`${duration.minutes} min`);

    return durationMsg.join(' ');
  }

  saveItinerary() {
    if (!this.selectedItinerary) return this.snackBarService.openSnackBar("No ha seleccionado un itinerario", undefined, 'alert');
    const body = {
      cargoId: this.paramsDialog.cargo.id,
      routePlanId: this.paramsDialog.routePlanId,
      itineraryId: this.selectedItinerary
    }
    this.spinner.show();
    this.planningRouteService.setCargoRouteAndItinerary([body]).subscribe(
      (response) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Se actualizó correctamente el itinerario");
        this.dialogRef.close({ state: true });
      }, (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )

  }

  goToCreateItinerary() {
    this.router.navigate(['/routes/planning/form/', this.paramsDialog.routePlanId]);
    this.dialogRef.close();
  }

  get isSomeItinerarySelectable(): boolean {
    return this.itineraries && this.itineraries.length && this.itineraries.some(itinerary => itinerary.active && this.isNearnessIndexValid(itinerary.id));
  }

  get sortedItineraries(): RouteItinerary[] {
    return this.itineraries.sort((a, b) => a.name.localeCompare(b.name));
  }

  get hasCreateItineraryPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.routes.module, this.permission.routes.createRouteItinerary);
  }

  getNearnessIndexes(itineraryId: string): { address: string, index: number }[] {
    return this.cargoNearnessIndexes[itineraryId];
  }

  isNearnessIndexValid(itineraryId: string): boolean {
    const indexes = this.cargoNearnessIndexes[itineraryId];
    return indexes && indexes.length && indexes.every(index => index && index.index >= 0.7);
  }
  getAuthorizedStopIcon(stop: RouteReferenceAuthorizedPoint): string {
    if (stop && stop.details && stop.details.pointType && stop.details.pointType.id) {
      const point = this.global.authorizedPoints.find(point => point.value === `point${stop.details.pointType.id}`);
      return point ? point.icon : null;
    }
    return null;
  }

}
