import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AuthorizedSitesFormComponent } from './authorized-sites-form/authorized-sites-form.component';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { RouteAuthorizedPoint } from 'src/app/core/interfaces/route-authorized-point';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { PlanningRoutesMessages } from 'src/app/core/messages/planning-routes-messages.enum';
import { RouteAuthorizedPointSearchParam } from 'src/app/core/interfaces/route-authorized-point-search-param';
import { FormControl } from '@angular/forms';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {
  permission = Permission;
  public readonly services = [
    { id: 0, name: 'Todos', icon: '' },
    { id: 1, name: 'Hospedaje', icon: "assets/svg/icons/tl_ico__pin_google_maps_trip_hotel.svg" },
    { id: 2, name: 'Estación de servicio', icon: "assets/svg/icons/tl_ico__pin_google_maps_trip_gas.svg" },
    { id: 3, name: 'Parqueadero', icon: "assets/svg/icons/tl_ico__pin_google_maps_trip_parking.svg" },
    { id: 4, name: 'Restaurante', icon: "assets/svg/icons/tl_ico__pin_google_maps_trip_restaurant.svg" },
  ];
  currentType: FormControl = new FormControl(0);
  public sites: RouteAuthorizedPoint[] = [];

  cityControl = new FormControl();
  currentCity = null;
  citySub: Subscription;
  cityOptions: OptionsAutocomplete = { title: "Ciudad", appearance: "outline", showXMark: true };
  public sort_order = 0;

  constructor(
    private matDialog: MatDialog,
    private planningRouteService: PlanningRouteService,
    private snackbarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private permissionRole: PermissionRole,
  ) { }

  ngOnInit() {
    this.loadList();
    this.setSubscription();
  }

  setSubscription() {
    this.citySub = this.cityControl.valueChanges.subscribe(city => {
      if ((!city || city === "clean") && this.currentCity) {
        this.currentCity = null;
        this.loadList();
      } else if (city && city.name && (!this.currentCity || this.currentCity !== city.name)) {
        this.currentCity = city;
        this.loadList();
      }
    })
  }

  public create() {
    const config = new MatDialogConfig();
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.LARGE_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.matDialog.open(AuthorizedSitesFormComponent, config).afterClosed().subscribe(result => {
      if (result && result.state) this.loadList();
    });
  }

  public edit(site: any) {
    const config = new MatDialogConfig();
    config.data = { site };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.LARGE_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.matDialog.open(AuthorizedSitesFormComponent, config).afterClosed().subscribe(result => {
      if (result && result.state) this.loadList();
    });
  }

  public async deactive(site: RouteAuthorizedPoint) {
    const config = new MatDialogConfig();
    config.data = {
      title: "Confirmar eliminación de punto autorizado",
      descriptionHTML: `<p>¿Estás seguro de que deseas eliminar el punto autorizado <strong>${site.details.name}</strong>? <span class="text-bold">Esta acción es irreversible</span></p>`,
      labelButton2: "Eliminar punto",
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.EXTRA_SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    const result = await this.matDialog.open(DialogComponent, config).afterClosed().toPromise();
    if (!result || !result.refuse || result.refuse !== "Eliminar punto") return;
    this.spinner.show();
    this.planningRouteService.deactivateAuthorizedPoint(site.id).subscribe(
      () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(PlanningRoutesMessages.DEACTIVE_AUTHORIZED_POINT, undefined, "success");
        this.loadList();
      },
      () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
      }
    );
  }

  loadList() {
    const params: RouteAuthorizedPointSearchParam = { active: "true" };
    if (this.currentType.value)
      params['pointType'] = this.currentType.value;
    if (this.currentCity) {
      params['cityId'] = this.currentCity.id;
      params['cityName'] = this.currentCity.name;
    }

    this.spinner.show();
    this.planningRouteService.getAuthorizedPoints(params).subscribe(
      (result: RouteAuthorizedPoint[]) => {
        this.spinner.hide();
        !!result ? this.sites = result : this.sites = [];
      }, (error) => {
        this.spinner.hide();
        this.sites = [];
      }
    )
  }

  getIcon(id: number) {
    const service = this.services.find(service => service.id == id);
    return service ? service.icon : '';
  }

  get hasPermissionEdit(): boolean {
    return this.permissionRole.hasPermission(this.permission.routes.module, this.permission.routes.editRouteSites);
  }
  get hasPermissionDelete(): boolean {
    return this.permissionRole.hasPermission(this.permission.routes.module, this.permission.routes.deleteRouteSites);
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
  }
}
