import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { AuthService } from "src/app/core/services/authentication.service";
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Global } from "src/app/core/resources/global";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { AdminUsersService } from "../admin-users.service";
import { Utils } from "src/app/core/resources/utils";
import { LocationPoint } from "src/app/core/interfaces/locationPoint";
declare var google: any;
import { User } from "src/app/core/interfaces/user";
import { Role } from "src/app/core/interfaces/role";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserModel } from "src/app/core/models/user.model";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { Patterns } from "src/app/core/resources/patterns";
import { CompanyNamePipe } from "src/app/core/pipe/companyName.pipe";
import { OpenImgComponent } from "src/app/shared/open-img/open-img.component";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { Dialog } from "src/app/core/resources/dialog";
import { Permission } from "src/app/core/resources/permission";
import { StateUserPipe } from "src/app/core/pipe/stateInactive.pipe";
import { ReactiveForm } from "src/app/core/resources/reactive-form";
import { Model } from "src/app/core/interfaces/vehicle";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { UserState } from "src/app/core/enums/user-state.enum";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { ServiceMessages } from "src/app/core/messages/service-messages.enum";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { Roles } from "src/app/core/enums/roles.enum";
import { DatePipe } from "@angular/common";
import { DateManager } from "src/app/core/managers/date.manager";
import { Subscription } from "rxjs";
import { Companies } from "src/app/core/resources/companies";
import { AdditionalCertification } from "src/app/core/interfaces/additionalCertification";
import { AdditionalCertificationDict, AdditionalCertificationEnum } from "src/app/core/enums/additionalCertification.enum";
import { ExtraDocument } from "src/app/core/interfaces/extraDocument";
import { StatusSecurityValidationStatus, StatusSecurityValidationType } from "src/app/core/interfaces/statusSecurityValidation";

@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.scss"],
  providers: [AuthService, Global, CompanyNamePipe, DateFormatPipe, Model, DatePipe],
})
export class UserFormComponent implements OnInit {
  @ViewChild("modalPDFTruora", { static: false }) modalPDFTruora;
  @ViewChild("modalConfirmActivateOrDesactivate", { static: false })
  modalConfirmActivateOrDesactivate;

  public dm = DateManager;
  public userState = UserState;
  permission = Permission;
  formUser: FormGroup;
  formAdditionalCertifications: FormGroup;
  disabledForm: boolean;
  isEnabled: boolean = false;
  historyLocations = [];
  historyLocationSelected: LocationPoint;
  mode: string;
  ExpirationDateDocuments: string = "";
  urlPDFTruora: string = "";
  documenTypes: Array<Object> = [
    {
      id: "1",
      name: "Cédula de ciudadanía",
    },
    {
      id: "2",
      name: "Pasaporte",
    },
    {
      id: "3",
      name: "NIT",
    }
  ];
  roles: Role[] = [];
  additionalCertifications: AdditionalCertification[] = [];
  roleUserToActive: string = "";
  typeLocation = {
    typeLocation: "collectionPoint",
  };
  user = new UserModel();
  originalUser: User;
  reactiveFormDriver: ReactiveForm;
  cannotActivateReason = [];
  formFields: any = {};
  companySelected: FormControl = new FormControl();
  currentValueName: string = "";
  currentValueAddress: string = "";
  currentValueCity: string = "";
  currentValueNitCompany: string = "";
  currentValueSimpleRegimen: boolean = false;
  currentValuePhone: string = '';
  isLicenseChecked = false;
  city = {
    name: "",
    municipalityCode: "",
  };
  company: FormControl = new FormControl({
    nit: "",
    exclusive: ""
  }, Validators.required);
  companySub: Subscription;
  expireArl: boolean = false;
  expireEps: boolean = false;
  approvalByArl: string;
  approvalByEps: string;
  validateCompany: string = 'disable';
  optionsCompany = {
    title: 'Operación'
  };
  userInfoSupport = [];
  optionsCity = {
    title: 'Ciudad'
  };
  cityControl: FormControl = new FormControl('', Validators.required);
  citySub: Subscription;
  validateCity: string = 'disable';
  constructor(
    public adminUsersService: AdminUsersService,
    private router: Router,
    public utils: Utils,
    private dialogUtils: Dialog,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public stateUserPipe: StateUserPipe,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserFormComponent>,
    public patterns: Patterns,
    private authService: AuthService,
    private companyNamePipe: CompanyNamePipe,
    private dateFormatPipe: DateFormatPipe,
    public modelVehicle: Model,
    private permissionRole: PermissionRole,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA)
    public paramsDialog: {
      driver;
      expireArl?;
      expireEps?;
      showDocuments?;
      driverIsSameOwner?;
      ownerIsSameAdmin?;
      validateTruora;
    }
  ) {
    this.mode = window.location.hash.substring(2).split("/")[2];
    this.initDriverForm();
  }

  /**
  * @description Verifies the dialogs params and route params to initialize the user
  */
  ngOnInit() {
    this.setSubscriptionCompany();
    this.formFields = {
      address: false,
      city: false,
      name: false,
      operationId: false,
      simpleRegimen: false,
      phone: false
    };
    if (this.paramsDialog && this.paramsDialog.driver) {
      this.validateDialogParams();
    } else {
      let userDocument = null;
      const params = this.route.snapshot.params;
      if (!this.utils.objIsEmpty(params)) {
        if (this.utils.isDefined(params.id)) {
          userDocument = params.id;
        }
      }
      if (this.mode === "detail") {
        if (this.utils.isDefined(AdminUsersService.userSelected)) {
          this.originalUser = this.utils.clone(AdminUsersService.userSelected);
          this.reactiveFormDriver.form.patchValue(
            AdminUsersService.userSelected
          );
          this.setModificableValues();
          this.user.deserialize(AdminUsersService.userSelected);
          if (this.validateLicenseExpiration(this.user)) {
            this.checkValidationUser();
          }
          this.getRolesCompany(this.user.getOperationId());
          this.getAdditionalCertifications(this.user.getInformation().getDocument());
          this.disabledForm = true;
        } else {
          this.disabledForm = true;
          this.getDetailUserByDocument(userDocument);
        }
      } else if (this.mode === "create") {
        AdminUsersService.userSelected = null;
        this.disabledForm = false;
      } else {
        this.backView();
      }
    }
    if ((this.isDriver || this.isOwner || this.isAdmin) && !this.user.getState().isActive()) {
      this.setCannotActivateReasons();
    }
  }

  /**
  * @description Makes a subscription to company value changes to update the form's nitCompany
  */
  private setSubscriptionCompany() {
    this.companySub = this.company.valueChanges
      .subscribe(value => {
        if (value && value.companyId) this.formUser.get('operationId').setValue(this.companyNamePipe.transform(value.companyId));
        else this.formUser.get('operationId').setValue(null);
      })
  }

  /**
  * @description Initializes the user's form group
  */
  private initDriverForm() {
    this.reactiveFormDriver = new ReactiveForm(
      this.formBuilder,
      this.modelVehicle.modelCreate.driver
    );
  }

  /**
  * @description Validates the paramDialog "driver" to use "setUserInfo" and "setSubscriptionAndUpdateUser" methods
  */
  validateDialogParams() {
    if (this.utils.isDefined(this.paramsDialog.driver)) {
      this.originalUser = this.utils.clone(this.paramsDialog.driver);
      this.reactiveFormDriver.form.patchValue(this.paramsDialog.driver);
      this.setModificableValues();
      this.user.deserialize(this.paramsDialog.driver);
      if (this.validateLicenseExpiration(this.user)) {
        this.checkValidationUser();
      }
      this.getRolesCompany(this.user.getOperationId());
      this.getAdditionalCertifications(this.user.getInformation().getDocument());
      this.disabledForm = true;
    }
  }

  /**
  * @param {UserModel} user is the user to check the license plate expiration
  * @returns {boolean} Returns the validity of the user's license plate expiration
  * @description Validates the user's license plate expiration and updates the flag "isLicenseChecked"
  */
  private validateLicenseExpiration(user: UserModel): boolean {
    if (user.getRole() !== Roles.DRIVER) return false;
    if (this.isLicenseChecked) return false;
    this.isLicenseChecked = true;
    return ((user.getRole() && user.getRole() === "Driver") || !user.getRole())
      && this.user.getState().getDescription() !== UserState.PENDING
      && ((user.getDriverLicense() && !user.getDriverLicense().active)
        || (user.getDriverLicenseCategory()
          && user.getDriverLicenseCategory().some(license => !license.expirationDate)));
  }

  /**
  * @description Assigns to currentValue variables and optionsCompany's initialNit the info of the user form
  */
  private setModificableValues() {
    this.currentValueName = this.reactiveFormDriver.form.value.information.name;
    this.currentValueAddress = this.reactiveFormDriver.form.value.address;
    this.currentValueCity = this.reactiveFormDriver.form.value.city;
    this.currentValueNitCompany = this.reactiveFormDriver.form.value.operationId;
    this.optionsCompany['initialNit'] = this.reactiveFormDriver.form.value.operationId;
    this.currentValueSimpleRegimen = this.reactiveFormDriver.form.value.simpleRegimen;
    this.currentValuePhone = this.reactiveFormDriver.form.value.phone;
  }

  /**
  * @param {string} operationId is the nit of the company to get its roles
  * @description Gets the list of roles and executes the "initFormUser" method
  */
  private getRolesCompany(operationId: string) {
    this.adminUsersService.getRolesCompany(operationId).subscribe(
      (success: Role[]) => {
        this.roles = success;
        this.initFormUser();
      },
      (error) => {
        this.roles = [];
        this.initFormUser();
      }
    );
  }

  /**
  * @param {string} userDocument is the user's document
  * @description Gets the list of user's additional certifications
  */
  private async getAdditionalCertifications(userDocument: string) {
    try {
      const result = await this.adminUsersService.getUserAdditionalCertifications(userDocument).toPromise();
      this.additionalCertifications = result && result.length ? result : [];
    } catch (e) {
      this.additionalCertifications = [];
    }
    this.initFormAdditionalCertifications();
  }

  /**
  * @param {string} userDocument is the document of the user to get
  * @param type is the type of the user
  * @description Gets the detail of the user and updates the local variables with it.
  */
  getDetailUserByDocument(userDocument: string, type?) {
    this.spinner.show();
    this.adminUsersService.getUsersDetailByDocument(userDocument).subscribe(
      (success: User) => {
        if (type) {
          if (success && success.extraDocuments && success.extraDocuments.length) {
            this.userInfoSupport = success.extraDocuments;
          } else this.userInfoSupport = [];
        } else {
          this.user = new UserModel();
          this.originalUser = this.utils.clone(success);
          this.reactiveFormDriver.form.patchValue(success);
          this.setModificableValues();
          this.user.deserialize(success);
          this.setModificableValues();
          this.getRolesCompany(this.user.getOperationId());
          this.getAdditionalCertifications(this.user.getInformation().getDocument());
          if (this.validateLicenseExpiration(this.user)) {
            this.checkValidationUser();
          }
        }
        this.validateDocuments();
        this.spinner.hide();
      },
      (error) => {
        if (type) this.userInfoSupport = [];
        else {
          this.user = new UserModel();
          this.originalUser = null;
          this.spinner.hide();
          this.backView();
        }
      }
    );
  }

  /**
  * @param {Date} date is the date to transform
  * @returns {string} returns the date on expected format
  * @description Transforms a date format into specific string format
  */
  formatDate(date: Date): string {
    return this.datePipe.transform(date, "d MMMM yyyy");
  }

  /**
  * @description Initializes the form group "formUser"
  */
  private initFormUser() {
    this.formUser = new FormGroup({
      uId: new FormControl({
        value: this.user.getUId() || "",
        disabled: this.disabledForm,
      }),
      document: new FormControl({
        value: this.user.getInformation().getDocument() || "",
        disabled: this.disabledForm,
      }),
      expeditionDateId: new FormControl({
        value: this.user.getExpeditionDateId() || "",
        disabled: this.disabledForm,
      }),
      email: new FormControl(
        {
          value: this.user.getEmail() || "",
          disabled: this.disabledForm,
        },
        [
          Validators.required,
          Validators.pattern(this.patterns.EMAIL.source),
          Validators.maxLength(100)
        ]
      ),
      idDocumentType: new FormControl({
        value: this.user.getInformation().getDocumentTypeId() || "1",
        disabled: this.disabledForm,
      }),
      name: new FormControl(
        {
          value: this.user.getInformation().getName() || "",
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      exclusive: new FormControl({
        value: this.user.isExclusive() || false,
        disabled: this.disabledForm,
      }),
      simpleRegimen: new FormControl({
        value: this.user.isSimpleRegimen() || false,
        disabled: this.disabledForm,
      }),
      phone: new FormControl(
        {
          value: this.user.getPhone() || "",
          disabled: this.disabledForm,
        },
        [
          Validators.required,
          Validators.pattern(this.patterns.PHONE.source),
          Validators.minLength(10)
        ]
      ),
      city: new FormControl(
        {
          value: this.user.getCity() || "",
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      address: new FormControl(
        {
          value: this.user.getAddress() || "",
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      ARL: new FormControl(
        {
          value:
            this.stateUserPipe.transform(this.expireArl ? 'Inactive' : this.user.getArl().getDescription()) ||
            "",
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      EPS: new FormControl(
        {
          value:
            this.stateUserPipe.transform(this.user.getEps().getDescription()) ||
            "",
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      License: new FormControl(
        {
          value:
            this.stateUserPipe.transform(
              this.user.getDriverLicense().getDescription()
            ) || "",
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      scoreTruora: new FormControl(
        {
          value: this.scoreTruora,
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      scoreTeclogi: new FormControl(
        {
          value: this.scoreTeclogi,
          disabled: this.disabledForm,
        },
        Validators.required
      ),
      role: new FormControl({
        value: this.user.getRole() || "",
        disabled: this.disabledForm,
      }),
      operationId: new FormControl({
        value: this.companyNamePipe.transform(this.user.getOperationId()),
        disabled: this.disabledForm,
      }),
      companyNit: new FormControl(
        {
          value: this.user.getOperationId() || "",
          disabled: this.disabledForm,
        },
        [Validators.required]
      ),
      roleUserToActive: new FormControl({
        value: "",
      }),
    });
    this.formUser.get("roleUserToActive").setValue(this.user.getRoleId());
    this.getDetailUserByDocument(this.formUser.get('document').value, 'docsInfo');
    this.setSubscriptionCity();
    this.optionsCity['initialValue'] = this.formUser && this.formUser.get('city') && this.formUser.get('city').value ? this.formUser.get('city').value : '';
    this.optionsCity = { ...this.optionsCity };
  }

  private initFormAdditionalCertifications() {
    const operationalAccreditation = this.getAdditionalCertification(AdditionalCertificationEnum.OPERATIONAL_ACCREDITATION);
    this.formAdditionalCertifications = new FormGroup({
      OPERATIONAL_ACCREDITATION: new FormControl(
        {
          value:
            operationalAccreditation
              ? operationalAccreditation.path && operationalAccreditation.active && operationalAccreditation.approvalBy &&
                !this.adminUsersService.isExpiredAdditionalCertification(operationalAccreditation)
                ? 'Activo'
                : 'Inactivo'
              : "",
          disabled: this.disabledForm,
        },
        this.isDriver ? Validators.required : null
      ),
    })
  }

  /**
  * @description Makes a subscription to cityControl value changes to update the form's city form control and city variable
  */
  private setSubscriptionCity() {
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => {
        if (value && value.name && value.id) {
          this.formUser.get('city').setValue(value.name);
          this.city = {
            name: value.name,
            municipalityCode: value.id,
          };
        } else {
          this.formUser.get('city').setValue('');
          this.city = {
            name: '',
            municipalityCode: '',
          };
        }
      })
  }

  /**
  * @returns {{[key:string]: AbstractControl}} returns the controls of the form group "formUser"
  * @description Gets the controls of the form group "formUser"
  */
  private getForm(): { [key: string]: AbstractControl } {
    return this.formUser.controls;
  }

  /**
  * @param {Date} isActivate indicates if the user has to be updated with city,address,email,phone and exclusive or only the role
  * @description Updates a user
  */
  onSubmit(isActivate?: boolean): void {
    if (
      this.utils.isDefined(this.getForm().exclusive.value) &&
      new RegExp("^(?=[0-9])").test(this.getForm().phone.value) &&
      this.getForm().phone.value.length >= 10 &&
      this.utils.isEmailValid(this.getForm().email.value)
    ) {
      let user;
      if (isActivate) {
        user = {
          information: { document: this.getForm().document.value },
          role: this.getForm().roleUserToActive.value,
        };
      } else {
        user = {
          information: { document: this.getForm().document.value },
          email: this.getForm().email.value,
          exclusive: this.getForm().exclusive.value,
          phone: this.getForm().phone.value,
          city: this.getForm().city.value,
          address: this.getForm().address.value,
        };
      }
      this.adminUsersService.updateUser(user).subscribe(
        (success: User) => {
          if (isActivate) {
            this.disableForm();
            this.getDetailUserByDocument(this.getForm().document.value);
            if (success && success.state && success.state.active) {
              this.showMessageActivateUser();
            } else {
              this.hideModals();
              this.adminUsersService.confirmDriverInactive(this.user);
            }
          } else {
            this.snackBarService.openSnackBar(
              "Usuario actualizado correctamente"
            );
            this.disableForm();
            this.getDetailUserByDocument(this.getForm().document.value);
          }
        },
        (error) => {
          this.snackBarService.openSnackBar(
            "Ocurrió un error actualizando al usuario",
            undefined,
            "error"
          );
        }
      );
    } else {
      let fields = "";

      if (
        !new RegExp("^(?=[0-9])").test(this.getForm().phone.value) ||
        this.getForm().phone.value.length < 10
      ) {
        fields = fields + " - Número de celular";
      }

      if (!this.utils.isEmailValid(this.getForm().email.value)) {
        fields = fields + " - Email";
      }

      this.snackBarService.openSnackBar(
        "Verifica los campos:" + fields,
        undefined,
        "alert"
      );
    }
  }

  /**
  * @param {string} state is the type of action to do
  * @param {string} field is the field to edit
  * @description Edits, Updates or cancels the edit of a formUser's field
  */
  onEditButtonClick(state: string, field: string) {
    if (!this.hasPermissionEdit) {
      this.snackBarService.openSnackBar("No tienes permisos de edición de usuarios", undefined, 'alert');
      return;
    }

    switch (state) {
      case "edit":
        if (field === "operationId") this.validateCompany = 'enable';
        if (field === "city") this.validateCity = 'enable';
        this.formUser.get(`${field}`).enable();
        this.formFields[field] = true;
        break;
      case "cancel":
        if (field === "operationId") this.validateCompany = 'disable&untouched';
        if (field === "city") this.validateCity = 'disable&untouched';
        this.modifiedValues(field);
        this.formUser.get(`${field}`).disable();
        this.formFields[field] = false;
        break;
      case "confirm":
        const data = this.setInformationTosend(field);
        if (
          this.formUser.get(`${field}`).value === "" ||
          this.formUser.get(`${field}`).value === null ||
          !this.formUser.get(`${field}`).valid
        ) {
          this.snackBarService.openSnackBar(
            field && !(field === 'operationId' || field === 'city') ?
              "Debe seleccionar una opción de la lista" :
              "Por favor ingrese un valor válido",
            undefined,
            "alert"
          );
          return;
        }

        switch (field) {
          case "operationId":
            this.validateCompany = 'touched';
            break;
          case "city":
            this.validateCity = 'touched';
            break;
        }

        if (
          (this.company.valid && field === "operationId") ||
          (this.cityControl.valid && field === "city") ||
          !["city", "operationId"].includes(field)
        ) {
          this.formUser.get(`${field}`).disable();
          this.formFields[field] = false;
          this.spinner.show();
          this.adminUsersService.updateUser(data).subscribe(
            (success: User) => {
              this.spinner.hide();
              this.modifiedValues(field, success);
              if (field === "operationId") this.validateCompany = 'disable&untouched';
              if (field === "city") this.validateCity = 'disable&untouched';
              this.snackBarService.openSnackBar(
                "Valor actualizado correctamente",
                undefined,
                "success"
              );
            },
            () => {
              this.spinner.hide();
              this.snackBarService.openSnackBar(
                "Hubo un error al actualizar el campo",
                undefined,
                "error"
              );
            }
          );
        }
    }
  }

  /**
  * @param {string} field is the field to edit
  * @returns an object to send to service to update the user
  * @description creates an object to update the user
  */
  private setInformationTosend(field: string) {
    let data = {
      information: {
        document: this.getForm().document.value,
      },
    };

    switch (field) {
      case "name":
        data.information["name"] = this.formUser.get(`${field}`).value;
        return data;
      case "address":
        data["address"] = this.formUser.get(`${field}`).value;
        return data;
      case "city":
        if (this.city && this.city.name && this.city.municipalityCode) {
          data["city"] = this.city.name;
          data["municipalityCode"] = this.city.municipalityCode;
          this.city = {
            name: "",
            municipalityCode: "",
          };
        }
        return data;
      case "operationId":
        if (this.company.value && this.company.value.companyId !== "") {
          data["exclusive"] = this.company.value.exclusive;
          data["operationId"] = this.company.value.companyId;
          // this.company.setValue({
          //   companyId: "",
          //   exclusive: false,
          // });
        }
        return data;
      case "simpleRegimen":
        data["simpleRegimen"] = this.formUser.get(`${field}`).value;
        return data;
      case "phone":
        const indicative = this.formUser.get(`${field}`).value.replace(/\s/g, "").trim().substring(0, 2);
        switch (indicative) {
          case "57":
            data["phone"] = this.formUser.get(`${field}`).value;
            break;
          case "+5":
            data["phone"] = this.formUser.get(`${field}`).value.slice(1)
            break;
          default:
            data["phone"] = `57${this.formUser.get(`${field}`).value}`;
            break;
        }

        if (data["phone"])
          return data;
      default:
        return data;
    }
  }

  /**
  * @param {string} field is the field to edit
  * @param {User} user is a user used to update the formUser
  * @description updates the formUser values with the user param values and updates the currentValues variables too.
  */
  private modifiedValues(field: string, success?: User) {
    switch (field) {
      case "name":
        if (success && success.information && success.information.name) {
          this.currentValueName = success.information.name;
        }
        this.formUser.patchValue({
          name: this.currentValueName,
        });
        break;
      case "address":
        if (success && success.address) {
          this.currentValueAddress = success.address;
        }
        this.formUser.patchValue({
          address: this.currentValueAddress,
        });
        break;
      case "city":
        if (success && success.city) {
          this.currentValueCity = success.city;
          this.optionsCity['initialValue'] = success.city;
        }
        this.formUser.patchValue({
          city: this.currentValueCity,
        });
        this.optionsCity['initialValue'] = this.currentValueCity;
        this.optionsCity = { ...this.optionsCity };
        break;
      case "operationId":
        if (success && success.operationId) {
          this.currentValueNitCompany = success.operationId
          this.optionsCompany['initialNit'] = success.operationId;
        }
        this.formUser.patchValue({
          operationId: this.companyNamePipe.transform(this.currentValueNitCompany),
        });
        this.optionsCompany['initialNit'] = this.currentValueNitCompany;
        this.optionsCompany = { ...this.optionsCompany }
        break;
      case "simpleRegimen":
        if (success && typeof success.simpleRegimen !== "undefined") {
          this.currentValueSimpleRegimen = success.simpleRegimen;
        }
        this.formUser.patchValue({
          simpleRegimen: this.currentValueSimpleRegimen,
        });
      case "phone":
        if (success && success.phone) {
          this.currentValuePhone = success.phone;
        }
        this.formUser.patchValue({
          phone: this.currentValuePhone,
        });
        break;
    }
  }

  /**
  * @returns {boolean} returns true if the user has the modifyBasicFieldUser permission, false in other case
  * @description Verifies if the user has the permission to update user's basic fields
  */
  private get hasPermissionEdit(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.modifyBasicFieldsUser
    );
  }

  /**
  * @param {boolean} isCancel indicates if is not necessary to execute "initFormUser" method
  * @description Disables some userForm fields and executes the "initFormUser" methods if isCancel is true
  */
  private disableForm(isCancel?: boolean): void {
    this.getForm().exclusive.disable();
    this.getForm().email.disable();
    this.getForm().phone.disable();
    this.getForm().city.disable();
    this.getForm().address.disable();
    this.disabledForm = true;
    if (isCancel) {
      this.initFormUser();
    }
  }

  /**
  * @description Navigates to admin-users list
  */
  backView() {
    this.router.navigate(["/administration/admin-users/list"]);
  }

  /**
  * @description Activates a user
  */
  private activateUser() {
    this.spinner.show();
    this.adminUsersService
      .activateUser(this.user.getInformation().getDocument())
      .subscribe(
        (success: User) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(ServiceMessages.USER_ACTIVATED);
          if (this.paramsDialog && this.paramsDialog.driver) this.dialogRef.close({ state: true });
          else this.getDetailUserByDocument(this.getForm().document.value);
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(
            "Ocurrió un error al " +
            (this.user.getState().isActive() ? "desactivar" : "activar") +
            " a " +
            this.user.getInformation().getName(),
            undefined,
            "error"
          );
        }
      );
  }

  /**
  * @description Forces the activation of a user
  */
  forzeActivation() {
    this.spinner.show();
    this.adminUsersService.forceActivationDriver(this.user.getInformation().getDocument()).subscribe(
      (success) => {
        this.showMessageActivateUser();
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al " +
          (this.user.getState().isActive() ? "desactivar" : "activar") +
          " a " +
          this.user.getInformation().getName(),
          undefined,
          "error"
        );
      }
    );
  }

  /**
  * @description Shows a message of the process of activate/deactivate and executes the "getDetailUserByDocument" method
  */
  private showMessageActivateUser(): void {
    this.spinner.hide();
    this.hideModals();
    this.snackBarService.openSnackBar(
      "Se " +
      (this.user.getState().isActive() ? "desactivó" : "activó") +
      " a " +
      this.user.getInformation().getName() +
      " correctamente"
    );
    this.getDetailUserByDocument(this.user.getInformation().getDocument());
  }

  /**
  * @description Gets the user's Truora PDF, saves it into urlPDFTruora variable and executes the "openPreviewPDF" method
  */
  getUrlPDFTruora(): void {
    this.spinner.show();
    this.adminUsersService
      .getPDFTruoraUser(this.user.getInformation().getDocument())
      .subscribe(
        (success) => {
          if (
            this.utils.isDefined(success) &&
            this.utils.isDefined(success["report_url"])
          ) {
            let url;
            try {
              url = new URL(success["report_url"]);
            } catch (e) {
              url = null;
            }
            if (url) {
              this.urlPDFTruora = url;
              this.spinner.hide();
              this.openPreviewPDF();
            } else {
              this.spinner.hide();
              this.urlPDFTruora = "";
              this.snackBarService.openSnackBar(
                "Ocurrió un error al traer el PDF",
                undefined,
                "error"
              );
            }
          } else {
            this.spinner.hide();
            this.urlPDFTruora = "";
            this.snackBarService.openSnackBar(
              "Ocurrió un error al traer el PDF",
              undefined,
              "error"
            );
          }
        },
        (error) => {
          this.spinner.hide();
          this.urlPDFTruora = "";
          this.snackBarService.openSnackBar(
            "Ocurrió un error al traer el PDF",
            undefined,
            "error"
          );
        }
      );
  }

  /**
  * @description Opens a modal to show the Truora's PDF
  */
  private openPreviewPDF() {
    const params: any = {
      title: "Análisis de seguridad",
      src: this.urlPDFTruora,
    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = params;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(OpenImgComponent, dialogConfig);
  }

  /**
  * @description Opens a modal to confirm to force the user's activation
  */
  confirmForceActivation() {
    this.dialogUtils
      .openDialog({
        title: `¿Estás seguro que deseas forzar la activacíon de ${this.user
          .getInformation()
          .getName()}?`,
      })
      .then((response) => {
        this.forzeActivation();
      })
      .catch((error) => error);
  }

  /**
  * @description Opens a modal to confirm the user's activate/deactivate
  */
  confirmActive(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas activar a ${this.user
        .getInformation()
        .getName()}?`,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state)
        this.activateUser();
    });
  }

  /**
  * @returns {number|string} if the user has Truora's score returns it as readable number
  * @description returns a readable number of user's Truora's score
  */
  get scoreTruora(): number | string {
    if (this.user && (this.isDriver || this.isOwner) && this.user.getTruora() && this.user.getTruora().getScoreTruora())
      return this.user.getTruora().getScoreTruora() * 10;
    return "Pendiente de Validacion";
  }

  /**
  * @returns {number|string} if the user has Teclogi's score returns it as readable number
  * @description returns a readable number of user's Teclogi's score
  */
  get scoreTeclogi(): number | string {
    if (this.user && this.isDriver && this.user.getTeclogiScore() && this.user.getTeclogiScore().globalScore)
      return this.user.getTeclogiScore().globalScore;
    return "Pendiente de Valoración";
  }

  /**
  * @description Executes the modalService's "dismissAll" method
  */
  hideModals() {
    this.modalService.dismissAll();
  }

  /**
   * @param {'ARL|EPS'| AdditionalCertificationEnum} type is the type of the document to upload
  * @description Makes the validations and Opens a modal to confirm the documents's upload
  */
  uploadDocument(type: 'ARL' | 'EPS' | AdditionalCertificationEnum): void {
    if (this.paramsDialog && this.paramsDialog.validateTruora) {
      if ((!this.user.getState() ||
        this.utils.isEmpty(this.user.getState().isActive()) ||
        this.utils.isEmpty(this.user.getState().getDescription()) ||
        this.user.getState().getDescription() === UserState.PENDING) ||
        (type === "EPS" && (!this.user.getEps() || this.utils.isEmpty(this.user.getEps().getActive()))) ||
        (type === "ARL" && (!this.user.getArl() || this.utils.isEmpty(this.user.getArl().getActive())))) {
        this.snackBarService.openSnackBar("No es posible agregar el soporte mientras el usuario tiene estudio de seguridad en proceso", undefined, 'error');
        return;
      }
    }
    const path =
      "driver/" + this.user.getInformation().getDocument() + "/" + type;
    const title = type === 'ARL' || type === 'EPS' ? type : AdditionalCertificationDict[type];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Cargue de documento para ${title}`,
      file: true,
      fileOptions: {
        type: "input",
        title,
        path,
        storage: true,
        pattern: this.patterns.ALL_EXPRESSIONS,
        required: true,
        storageName: type
      },
      inputFormControl: new FormControl(null, [Validators.required]),
      date: "ExpirationDateDocuments",
      fromDate: new Date(),
      subtitle: `Ingrese la fecha de vencimiento de ${title}:`,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.userUploadDocument(type, result.file, result.date);
      }
    });
  }

  /**
   * @param {'ARL|EPS'|AdditionalCertificationEnum} type is the type of the document to upload
   * @param path is the file to upload
   * @param date is the expiration date of the file
  * @description Uploads a user's file
  */
  private userUploadDocument(type: 'ARL' | 'EPS' | AdditionalCertificationEnum, path, dueDate: string): void {
    let promise: Promise<Object>;
    if (type === 'ARL' || type === 'EPS')
      promise = this.adminUsersService.userUploadDocument({ type, path, dueDate }, this.user.getInformation().getDocument()).toPromise();
    else
      promise = this.adminUsersService.updateUserAdditionalCertifications(
        this.user.getInformation().getDocument(),
        { id: type, active: false, path, dueDate, description: AdditionalCertificationDict[type] }
      ).toPromise();
    this.spinner.show();
    promise.then((success: any) => {
      this.getDetailUserByDocument(this.originalUser.information.document);
      this.snackBarService.openSnackBar(
        "Soporte cargado correctamente, a la espera de aprobación"
      );
    }).catch((error: any) => {
      this.snackBarService.openSnackBar(
        "Ocurrió un error al cargar el soporte",
        undefined,
        "error"
      );
    }).finally(() => {
      this.dialogRef.close({ state: true });
      this.spinner.hide();
    });
  }

  /**
   * @param {'ARL|EPS'} type is the type of the document to approve
  * @description Approves a user's document
  */
  private userActivateAffiliation(type: 'ARL' | 'EPS' | AdditionalCertificationEnum) {
    this.adminUsersService
      .userActivateAffiliation(
        type,
        type === 'ARL' || type === 'EPS' ? this.authService.getUserSession().information.name : null,
        this.originalUser.information.document
      )
      .toPromise()
      .then((success: any) => {
        this.getDetailUserByDocument(this.originalUser.information.document);
        this.snackBarService.openSnackBar("Soporte aprobado correctamente");
      })
      .catch((error: any) => {
        this.snackBarService.openSnackBar(
          "Ocurrió un error al aprobar el soporte",
          undefined,
          "error"
        );
      })
      .finally(() => {
        if (this.paramsDialog && this.paramsDialog.driver) {
          this.dialogRef.close({ state: true });
        }
        this.spinner.hide();
      });
  }

  /**
  * @param {'ARL|EPS'} type is the type of the document
  * @param {string} url is the url of the document
  * @description Opens a modal to show the document and allows user to approve it
  */
  private openDocumentExtra(type: 'ARL' | 'EPS' | AdditionalCertificationEnum, url: string, dueDate: string): void {
    const dialogConfig = new MatDialogConfig();
    let formatDueDate: Date = DateManager.stringToDate(dueDate);
    dialogConfig.data = {
      title: `Validar soporte - ${type === 'ARL' || type === 'EPS' ? type : AdditionalCertificationDict[type]}`,
      src: url,
      confirmBtn: "Aprobar",
      alertMessage: DateManager.isBefore(formatDueDate, new Date()) ? `No es posible aprobar el documento ya que este se encuentra vencido. Fecha de vencimiento: ${this.formatDate(formatDueDate)}` : ''
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(OpenImgComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.confirmApproveExtraDocument(type);
      }
    });
  }

  /**
  * @param {'ARL'|'EPS'} type is the type of the user's document
  * @param mode defines if the document has to be opened in a window
  * @description Gets the user's document from storage and shows it
  */
  getURLDocument(type: 'ARL' | 'EPS' | AdditionalCertificationEnum, mode?) {
    let document: ExtraDocument | AdditionalCertification;
    if (type === 'ARL' || type === 'EPS')
      document = this.adminUsersService.getExtraDocumentByType(
        type,
        this.originalUser
      );
    else
      document = this.adminUsersService.getAdditionalCertificationByType(type, this.additionalCertifications);
    if (document && document.path) {
      const storage = AuthService.fStorage;
      const pathReference = storage.ref(document.path);
      this.spinner.show();
      pathReference.getDownloadURL().then(
        (url) => {
          this.spinner.hide();
          if (url) {
            if (mode) window.open(url)
            else this.openDocumentExtra(type, url, document.dueDate);
          } else this.snackBarService.openSnackBar(FormMessages.NOT_LOAD_IMAGE, undefined, 'alert');
        },
        (error) => {
          this.spinner.hide();
          if (error && error.code_ && error.code_.includes('object-not-found')) this.snackBarService.openSnackBar(FormMessages.NOT_EXIST_IMAGE, undefined, 'error')
          else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        }
      );
    }
  }

  /**
  * @param {'ARL'|'EPS'|AdditionalCertificationEnum} type is the type of the user's document
  * @description Opens a modal to confirm the document approval
  */
  private confirmApproveExtraDocument(type: 'ARL' | 'EPS' | AdditionalCertificationEnum) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas aprobar la ${type === 'ARL' || type === 'EPS' ? type : AdditionalCertificationDict[type]}?`,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.userActivateAffiliation(type);
      }
    });
  }

  /**
  * @description Makes a Truora's check of the originalUser
  */
  checkValidationUser() {
    const driverDetail: User = this.utils.clone(this.originalUser);
    delete driverDetail.profilePictureUrl;
    if (driverDetail && driverDetail.information && driverDetail.information.document) {
      this.spinner.show();
      const $sub = this.adminUsersService
        .checkValidationUser(driverDetail.information.document).subscribe(
          {
            next: (success: any) => {
              if (success && success.driver && success.driver.state && success.driver.state.active) {
              } else if (success && success.driver) {
                this.adminUsersService.confirmDriverInactive(success.driver);
              } else {
                if (this.paramsDialog && this.paramsDialog.validateTruora) this.adminUsersService.confirmDriverInactive(this.user);
              }
              this.getDetailUserByDocument(this.originalUser.information.document);
              this.spinner.hide();
            },
            error: () => {
              this.snackBarService.openSnackBar('Ocurrió un error con la petición', undefined, 'error');
              this.getDetailUserByDocument(this.originalUser.information.document);
              this.spinner.hide();
            },
            complete: () => {
              $sub.unsubscribe();
            }
          }
        )
    } else {
      this.snackBarService.openSnackBar('No fue posible acceder al documento del usuario', undefined, 'error');
    }

  }

  /**
  * @description Opens a modal to update the user's document expedition date
  */
  openDialogChangeExpeditionDate() {
    this.dialogUtils
      .openDialog({
        title: `Actualizar Fecha de expedición`,
        date: true,
        untilDate: new Date()
      })
      .then((response) => {
        const date = this.dateFormatPipe.transform(response.date, "only-date");
        if (date !== this.originalUser.expeditionDateId) {
          this.confirmValidateUser(date);
        } else {
          this.snackBarService.openSnackBar(
            "La fecha debe ser diferente",
            undefined,
            "alert"
          );
        }
      })
      .catch((error) => error);
  }

  /**
  * @description Opens a modal to confirm the document's expedition date
  */
  private confirmValidateUser(date): void {
    this.dialogUtils
      .openDialog({
        title: `¿Estás seguro que deseas asignar la fecha ${date} como fecha de expedición?`,
      })
      .then((response) => {
        this.validateUser(date);
      })
      .catch((error) => error);
  }

  /**
  * @param {string} expeditionDate is the user's document expedition date
  * @description Updates the user's document expedition date
  */
  validateUser(expeditionDate: string) {
    this.spinner.show();
    const driverDetail = this.utils.clone(this.originalUser);
    driverDetail.expeditionDateId = expeditionDate;
    delete driverDetail.profilePictureUrl;
    this.adminUsersService
      .validateUser(driverDetail, true)
      .toPromise()
      .then((response) => {
        this.getDetailUserByDocument(this.originalUser.information.document);
        this.snackBarService.openSnackBar("Fecha actualizada correctamente");
      })
      .catch((error) => {
        this.snackBarService.openSnackBar(
          "Ocurrió un error al actualizar la fecha",
          undefined,
          "error"
        );
      })
      .finally(() => {
        this.spinner.hide();
        this.dialogRef.close({ state: true });
      });
  }

  /**
  * @returns {boolean} Returns true if the user has role Driver
  * @description Checks if the user has role Driver
  */
  get isDriver(): boolean {
    return this.user.getRole() === Roles.DRIVER;
  }
  /**
  * @returns {boolean} Returns true if the user has role Owner
  * @description Checks if the user has role Owner
  */
  get isOwner(): boolean {
    return this.user.getRole() === Roles.OWNER;
  }
  /**
  * @returns {boolean} Returns true if the user has role Admin
  * @description Checks if the user has role Admin
  */
  get isAdmin(): boolean {
    return this.user.getRole() === Roles.ADMIN;
  }
  /**
  * @returns {boolean} Returns true if the confirmCheckUser's button must to be shown
  * @description Verifies if the confirmCheckUser's button must to be shown
  */
  get showButtonConfirmCheckUser(): boolean {
    return ((this.isDriver || (this.isOwner && this.paramsDialog && (this.paramsDialog.showDocuments ||
      this.paramsDialog.driverIsSameOwner || this.paramsDialog.ownerIsSameAdmin)) || (this.isAdmin && this.paramsDialog.showDocuments)) &&
      !this.utils.isDefined(this.user.getState().isActive())) || (!this.user.getState().isActive() &&
        this.user.getState().getDescription() === UserState.PENDING);
  }

  /**
  * @param {string} address is the address selected
  * @description Updates the formUser's field "address" with the address selected
  */
  onSelectAddress(address: string): void {
    this.formUser.get('address').setValue(address);
  }

  /**
  * @returns {boolean} Returns true if the user has wrong Truora's data
  * @description Verifies if the user has wrong Truora's data
  */
  get isInactiveWrongDriverData(): boolean {
    return this.utils.isDefined(this.user.getState().isActive()) &&
      this.utils.isDefined(this.user.getTruora()) &&
      this.utils.isDefined(this.user.getTruora().getReason()) &&
      !this.user.getArl().getActive() &&
      this.user.getState().getDescription() !== UserState.PENDING &&
      (this.user.getState().getDescription() === UserState.WRONG_DRIVER_DATA ||
        this.user.getTruora().getReason() === UserState.TRUORA_WRONG_DRIVER_DATA);
  }

  /**
  * @returns {boolean} Returns true if the ForceActivation's button must to be shown
  * @description Verifies if the ForceActivation's button must to be shown
  */
  get showButtonForceActivation(): boolean {
    return this.isDriver &&
      this.utils.isDefined(this.user.getState().isActive()) &&
      this.user.getState().getDescription() !== UserState.PENDING_VALIDATE_DOCUMENTS &&
      this.user.getState().getDescription() !== UserState.PENDING &&
      !this.user.getState().isActive() && this.user.getTruora().getScoreTruora() < 0.7;
  }

  /**
  * @returns {boolean} Returns true if the user has role "webUserPendingActivate"
  * @description Verifies if the user has role "webUserPendingActivate"
  */
  private get showPendingAssignRole(): boolean {
    return this.user.getRole() === "webUserPendingActivate";
  }

  /**
  * @description Checks the user to fill the cannotActivateReason array with the respective reasons to can't activate
  */
  private setCannotActivateReasons() {
    this.cannotActivateReason = [];
    if (this.isInactiveEPS)
      this.cannotActivateReason.push(`${this.userHasTruora ? this.getMessageCannotActivate('eps') : 'La EPS está inactiva'}`);

    if (this.isInactiveARL)
      this.cannotActivateReason.push(`${this.userHasTruora ? this.getMessageCannotActivate('arl') : 'La ARL está inactiva'}`);

    if (!this.isInactiveDriverLicense && this.isDriver && this.userHasTruora)
      this.cannotActivateReason.push(this.getMessageCannotActivate('runt'));

    if (!this.utils.isDefined(this.user.getTruora())) {
      this.cannotActivateReason.push(
        `No se puede obtener información de Truora`
      );
    }
    if (
      this.user &&
      this.user.getTruora() &&
      this.user.getTruora().getReason()
    ) {
      this.cannotActivateReason.push(this.user.getTruora().getReason());
    }

    if (this.showPendingAssignRole)
      this.cannotActivateReason.push(`Requiere asignación de Rol`);

    if (this.utils.isEmpty(this.user.getState()))
      this.cannotActivateReason.push(
        `No se puede obtener el estado del usuario`
      );

    if (this.user && this.user.getState() &&
      this.user.getState().getDescription() &&
      ["Inactive", "Active", "Pending"].indexOf(
        this.user.getState().getDescription()
      )
    ) {
      this.cannotActivateReason.push(this.stateUserPipe.transform(this.user.getState().getDescription()));
    }
  }

  getMessageCannotActivate(outServiceDbs: string): string {
    return `La base de datos de ${outServiceDbs} está temporalmente fuera de servicio pero puedes ingresar la informacion manualmente o lanzar un nuevo estudio de seguridad`
  }

  get userHasTruora(): boolean {
    return !!(this.user && this.user.getTruora() && this.user.getTruora().getCheckId());
  }

  /**
  * @returns {boolean} Returns true if the user's SaaS company has escort services
  * @description Verifies if the user's SaaS company has escort services
  */
  get hasEscortServicesCompany(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }

  /**
 * @param {AdditionalCertificationEnum} type is the type of the certification
 * @returns {AdditionalCertification} returns the certification required if exists
 * @description Indicates if the driver has the certification required and returns it.
 */
  getAdditionalCertification(type: AdditionalCertificationEnum): AdditionalCertification {
    if (!this.additionalCertifications || !this.additionalCertifications.length) return null;
    return this.additionalCertifications.find(cert => cert && cert.id === type);
  }

  /**
  * @returns {boolean} Returns true if the user has permission "editSimpleRegimen"
  * @description Verifies if the editSimpleRegimen's button must to be shown
  */
  get showButtonEditSimpleRegimen() {
    return this.permissionRole.hasPermission(
      this.permission.payments.module,
      this.permission.payments.editSimpleRegimen
    );
  }
  /**
  * @returns {boolean} Returns true if the activate/deactivate's button must to be shown
  * @description Verifies if the activate/deactivate's button must to be shown
  */
  get showButtonInactivateActivateUser(): boolean {
    if (!this.utils.isDefined(this.user.getState().isActive())) return false;

    if (this.isDriver) {
      // Is a Driver
      if (
        this.permissionRole.hasPermission(
          this.permission.administration.module,
          this.permission.administration.disableEnableDriver
        ) ||
        this.permissionRole.hasPermission(
          this.permission.administration.module,
          this.permission.administration.disableEnableUsers
        )
      ) {
        return (
          this.user.getState().isActive() ||
          this.cannotActivateReason.length === 0
        );
      }
      return false;
    } else {
      // Other rol
      return this.permissionRole.hasPermission(
        this.permission.administration.module,
        this.permission.administration.disableEnableUsers
      );
    }
  }

  /**
  * @returns {boolean} Returns true if the user's driver license is active
  * @description Verifies if the user has driver license active
  */
  private get isInactiveDriverLicense(): boolean {
    const hasInactiveDriverLicense: boolean =
      !this.utils.isDefined(this.user.getDriverLicense()) ||
      !this.utils.isDefined(this.user.getDriverLicense().getActive()) ||
      !this.user.getDriverLicense().getActive();
    const hasWrongSecurityValidation: boolean =
      this.user.getTruora() &&
      this.user.getTruora().getStatusesSecurityValidation() &&
      this.user.getTruora().getStatusesSecurityValidation().length &&
      !this.user.getTruora().getStatusesSecurityValidation().some(status => status && status.status !== StatusSecurityValidationStatus.FOUND && status.type === StatusSecurityValidationType.DRIVING_LICENSES);
    return this.user.getState().getDescription() !== UserState.PENDING && (hasInactiveDriverLicense || hasWrongSecurityValidation);
  }

  /**
  * @returns {boolean} Returns true if the user has ARL inactive
  * @description Verifies if the user's ARL is inactive
  */
  get isInactiveARL(): boolean {
    const hasInactiveARL: boolean =
      this.utils.isDefined(this.user.getState().isActive()) &&
      !this.user.getArl().getActive() &&
      !this.utils.isEmpty(this.user.getArl().getActive()) &&
      !this.utils.isEmpty(this.user.getState().getDescription());
    const hasWrongSecurityValidation: boolean =
      this.user.getTruora() &&
      this.user.getTruora().getStatusesSecurityValidation() &&
      this.user.getTruora().getStatusesSecurityValidation().length &&
      !this.user.getTruora().getStatusesSecurityValidation().some(status => status && status.status !== StatusSecurityValidationStatus.FOUND && status.type === StatusSecurityValidationType.ARL);
    return this.user.getState().getDescription() !== UserState.PENDING && (hasInactiveARL || hasWrongSecurityValidation);
  }

  /**
* @description Validates the driver's arl and eps dueDate to check its expiration date
*/
  private validateDocuments() {
    if (this.userInfoSupport && this.userInfoSupport.length) {
      this.expireArl = this.adminUsersService.isExpiredDoc(this.originalUser, 'ARL');
      this.expireEps = this.adminUsersService.isExpiredDoc(this.originalUser, 'EPS');
      this.formUser.get('EPS').setValue(this.adminUsersService.getepsState(this.originalUser));
      this.formUser.get('ARL').setValue(this.adminUsersService.getarlState(this.originalUser));
    }
  }

  /**
  * @returns {boolean} Returns true if the user has EPS inactive
  * @description Verifies if the user's EPS is inactive
  */
  private get isInactiveEPS() {
    const hasInactiveEPS: boolean =
      this.utils.isDefined(this.user.getState().isActive()) &&
      !this.user.getEps().getActive() &&
      !this.utils.isEmpty(this.user.getEps().getActive()) &&
      !this.utils.isEmpty(this.user.getState().getDescription())
    const hasWrongSecurityValidation: boolean =
      this.user.getTruora() &&
      this.user.getTruora().getStatusesSecurityValidation() &&
      this.user.getTruora().getStatusesSecurityValidation().length &&
      !this.user.getTruora().getStatusesSecurityValidation().some(status => status && status.status !== StatusSecurityValidationStatus.FOUND && status.type === StatusSecurityValidationType.EPS);
    return this.user.getState().getDescription() !== UserState.PENDING && (hasInactiveEPS || hasWrongSecurityValidation);
  }

  /**
  * @returns {boolean} Returns true if the user is from Teclogi
  * @description Verifies if the user is from Teclogi
  */
  public get isTeclogiUserOrClient(): boolean {
    return this.authService.getCompanySaaS() &&
      Companies.companiesNIT.TECLOGI === this.authService.getCompanySaaS().companyId;
  }

  /**
  * @description Opens a dialog to confirm the user's deactivate
  */
  openDialogDeactivateUser(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Escriba la razón por la cual desea desactivar este usuario`,
      textArea: true,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state && result.message) {
        this.deactivateUser(result.message);
      }
    });
  }

  /**
  * @param {string} reason is the reason to deactivate a user
  * @description Deactivates a user and executes the "getDetailUserByDocument" method
  */
  private deactivateUser(reason: string) {
    const idUser: string = this.getForm().document.value;
    this.spinner.show();
    this.adminUsersService.deactivateUser(idUser, reason).subscribe(
      (success) => {
        this.snackBarService.openSnackBar(ServiceMessages.USER_DEACTIVATED);
        this.spinner.hide();
        this.getDetailUserByDocument(this.originalUser.information.document);
      },
      (error) => {
        if (error.error && error.error.message) {
          this.snackBarService.openSnackBar(
            error.error.message,
            undefined,
            "error"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrio un error al desactivar el usuario",
            undefined,
            "error"
          );
        }
        this.spinner.hide();
      }
    );
  }

  /**
  * @param {'ARL'|'EPS'|AdditionalCertificationEnum} type is the type of the document
  * @returns {boolean} returns true if the userInfoSupport has the document required
  * @description Indicates if the userInfoSupport has the document required
  */
  getDocumentSupport(type: 'ARL' | 'EPS' | AdditionalCertificationEnum): boolean {
    if (type === 'ARL' || type === 'EPS')
      return this.adminUsersService.getDocumentSupport(type, this.userInfoSupport);
    return this.additionalCertifications.some(cert => cert && cert.id === type);
  }

  get additionalCertificationEnum(): typeof AdditionalCertificationEnum {
    return AdditionalCertificationEnum;
  }

  getPendingApproval(driver: User): boolean {
    return driver && ((driver.extraDocuments && driver.extraDocuments.length && driver.extraDocuments.some(document => ['ARL', 'EPS'].includes(document.type) && !document.approvalBy))
      || (this.additionalCertifications && this.additionalCertifications.length && this.additionalCertifications.some(cert => cert && (!cert.approvalBy || !cert.approvalBy.userId))));
    ;
  }
  getPendingApprovalByType(type: string, driver: User): boolean {
    return driver && driver.extraDocuments && driver.extraDocuments.some(document => document.type === type && !document.approvalBy);
  }

  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
    if (this.citySub) this.citySub.unsubscribe();
  }
}
