<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <!--Origin information-->
    <div class="row">
        <div class="col-md-12">
            <span class="label-field">Informacion de origen</span>
        </div>
    </div>
    <div [formGroup]="formOrigin">
        <!--Origin city-->
        <div class="row">
            <div class="col-md-4">
                <app-autocomplete-city [inputFormControl]="cityOriginControl" [validate]="validate"
                    [options]="cityOriginOptions">
                </app-autocomplete-city>
            </div>
            <div class="col-md-4" *ngIf="cargo?.cargoModel?.tripType?.name==='Internacional'">
                <label class="text-strong">¿Es Internacional?&nbsp;&nbsp;</label>
                <mat-button-toggle-group formControlName="isInternational" #group="matButtonToggleGroup">
                    <mat-button-toggle [value]="true" (change)="onChangeInternational('origin')">
                        Si
                    </mat-button-toggle>
                    <mat-button-toggle [value]="false" (change)="onChangeInternational('origin')">
                        No
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="col-md-4" *ngIf="!hiddenFields?.dateLoad">
                <mat-form-field appearance="standard">
                    <mat-label>Fecha de cargue<span class="asterisk">*</span></mat-label>
                    <input type="text" matInput [matDatepickerFilter]="fromToday" [matDatepicker]="datePicker" readonly
                        (click)="datePicker.open()" (dateChange)="onChangeDate($event, 'load')"
                        [formControl]="dateLoad">
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                    <mat-error *ngIf="dateLoad.errors?.required">
                        {{ utils.giveMessageError('MANDATORY_FIELD') }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="alert alert-warning col-12 text-center" role="alert" *ngIf="showErrorReteica">
                <i class="fas fa-exclamation-circle mr-3"></i>
                La ciudad de origen no tiene asignado un ReteIca por favor asocielo o cambie de ciudad
            </div>
        </div>

        <div class="row" formArrayName="addresses"
            *ngFor="let address of this.formOriginAddresses.controls; let indexAddress = index;">
            <!--Origin addresses-->
            <div [formGroupName]='indexAddress' class="row w-100 pl-3">
                <div class="text-bold col-12">Dirección {{indexAddress + 1}}</div>
                <!--Origin cellphone-->
                <div class="col-md-6" *ngIf="!hiddenFields?.cellphone">
                    <mat-form-field appearance="standard">
                        <mat-label>Celular</mat-label>
                        <input matInput type="text" formControlName='cellphone'>
                        <mat-error *ngIf="address.get('cellphone').errors?.minlength">
                            {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'celular', 10) }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--Origin time-->
                <div class="col-md-3">
                    <mat-form-field appearance="standard" class="time__address-list-time">
                        <mat-label>Hora de cargue<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName='time'>
                            <mat-option [value]="time.value" *ngFor="let time of timeList">
                                {{time.title}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="address.get('time').errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--Origin timeType-->
                <div class="col-md-3 pt-2 pl-0">
                    <mat-button-toggle-group appearance="standard" formControlName='timeType'
                        class="time__address-type-time" #group="matButtonToggleGroup">
                        <mat-button-toggle value="AM">
                            AM
                        </mat-button-toggle>
                        <mat-button-toggle value="PM">
                            PM
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <!-- Time pact Origin-->
                <div class="col-md-2">
                    <mat-form-field appearance="standard">
                        <mat-label>Tiempo Pactado(horas):</mat-label>
                        <mat-select formControlName='timePact' [(value)]="address.get('timePact').value">
                            <mat-option *ngFor="let hour of hourList" [value]="hour.value">
                                {{hour.title}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="standard">
                        <mat-label>Tiempo Pactado(minutos):</mat-label>
                        <mat-select formControlName='minutePact' [(value)]="address.get('minutePact').value">
                            <mat-option *ngFor="let minute of minuteList" [value]="minute.value">
                                {{minute.title}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div [formGroupName]='indexAddress' class="row w-100 pl-3 align-items-end">
                <!--Address without ThirdParty-->
                <div class="col-md-6" *ngIf="!cargo?.ministry || formOrigin.get('isInternational')?.value">
                    <app-autocomplete-address [value]="address.value.address" [addLatLong]="true" [title]="'Dirección'"
                        (emitToParent)="onSelectAddress($event,indexAddress)"
                        [internationalCode]="formOrigin.get('country.code').value">
                    </app-autocomplete-address>
                    <span class="pattern pattern--autocomplete font-secondary-dark"
                        *ngIf="address.get('address').value==''">
                        Seleccione una dirección de la lista
                    </span>
                </div>
                <!--Address with ThirdParty-->
                <div class="col-md-6" *ngIf="cargo?.ministry && !formOrigin.get('isInternational')?.value">
                    <app-autocomplete-thirdparty [options]="companyLoadOptions.at(indexAddress).value"
                        [inputFormControl]="companyLoadControls.at(indexAddress)" [validate]="validate">
                    </app-autocomplete-thirdparty>
                </div>
                <div class="col-md-6"
                    *ngIf="cargo?.ministry && !formOrigin.get('isInternational')?.value && address.value?.thirdPartyConsignment?.documentNumber && formOrigin.get('municipalityCode')?.value">
                    <app-autocomplete-thirdparty-address
                        [inputFormControl]="thirdPartyLoadAddressControls.at(indexAddress)" [validate]="validate"
                        [options]="{
                        title: 'Dirección de remitente',
                        companyId: address.value?.thirdPartyConsignment?.documentNumber,
                        municipalityCode: formOrigin.value?.municipalityCode,
                        thirdParty: companyLoadControls.at(indexAddress)?.value,
                        initialValue: address.value?.address
                    }">
                    </app-autocomplete-thirdparty-address>
                </div>
            </div>
        </div>
    </div>
    <mat-card-content class="text-right download__actions" *ngIf="!hideOptionsAddAddress">
        <button type="button" mat-button class="button-width-auto" (click)="addAddressForm()">
            <i class="fas fa-plus-circle"></i>
            <span>Agregar otra dirección</span>
        </button>
    </mat-card-content>

    <hr *ngIf="hideOptionsAddCity">

    <!--Destination infromation-->
    <div class="row">
        <div class="col-md-12">
            <span class="label-field">Informacion de Destino</span>
        </div>
        <div class="alert alert-warning col-12" role="alert" *ngIf="showAlertChanges">
            {{showAlertChanges}}
        </div>
    </div>

    <div [formGroup]="formDestination" class="mt-3">
        <div *ngFor="let city of this.formDestination.controls; let indexDestination = index;"
            [formGroupName]='indexDestination'>
            <div class="text-bold">Destino {{indexDestination+ 1}}</div>
            <!--Destination city-->
            <div class="row">
                <div class="col-md-4">
                    <app-autocomplete-city [options]="cityDestinationOptions.at(indexDestination).value"
                        [inputFormControl]="cityDestinationControls.at(indexDestination)" [validate]="validate">
                    </app-autocomplete-city>
                </div>
                <div class="col-md-4" *ngIf="cargo?.cargoModel?.tripType?.name==='Internacional'">
                    <label class="text-strong">¿Es Internacional?&nbsp;&nbsp;</label>
                    <mat-button-toggle-group formControlName="isInternational" #group="matButtonToggleGroup">
                        <mat-button-toggle [value]="true"
                            (change)="onChangeInternational('destination',indexDestination)">
                            Si
                        </mat-button-toggle>
                        <mat-button-toggle [value]="false"
                            (change)="onChangeInternational('destination',indexDestination)">
                            No
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label>Fecha de descargue<span class="asterisk">*</span></mat-label>
                        <input type="text" matInput [matDatepickerFilter]="fromToday" [matDatepicker]="datePicker"
                            readonly (click)="datePicker.open()"
                            (dateChange)="onChangeDate($event, 'download', indexDestination)"
                            [formControl]="dateDownload.at(indexDestination)">
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-datepicker #datePicker></mat-datepicker>
                        <mat-error *ngIf="dateDownload.at(indexDestination).errors?.required">
                            {{ utils.giveMessageError('MANDATORY_FIELD') }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row" formArrayName="addresses"
                *ngFor="let address of city.get('addresses').controls; let indexAddress = index;">
                <div [formGroupName]='indexAddress' class="row w-100 pl-3">
                    <div class="text-bold col-12">Dirección {{indexAddress + 1}}</div>
                    <div class="col-md-3" *ngIf="!hiddenFields?.cellphone">
                        <mat-form-field appearance="standard">
                            <mat-label>Celular</mat-label>
                            <input matInput type="text" formControlName='cellphone'>
                            <mat-error *ngIf="address.get('cellphone').errors?.minlength">
                                {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'celular', 10) }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--Destination time-->
                    <div class="col-md-3">
                        <mat-form-field appearance="standard" class="time__address-list-time">
                            <mat-label>Hora de descargue<span class="asterisk">*</span></mat-label>
                            <mat-select formControlName='time'>
                                <mat-option [value]="time.value" *ngFor="let time of timeList">
                                    {{time.title}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="address.get('time').errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--Destination timeType-->
                    <div class="col-md-3 pt-2 pl-0">
                        <mat-button-toggle-group appearance="standard" formControlName='timeType'
                            class="time__address-type-time" #group="matButtonToggleGroup">
                            <mat-button-toggle value="AM">
                                AM
                            </mat-button-toggle>
                            <mat-button-toggle value="PM">
                                PM
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="col-md-3" *ngIf="!hiddenFields?.email">
                        <mat-form-field appearance="standard">
                            <mat-label>Correo Electrónico</mat-label>
                            <input matInput type="email" name="emailForm" formControlName="email">
                            <mat-icon matSuffix class="cursor-pointer" color="primary"
                                *ngIf="!utils.isEmailValid(address.get('email').value)"
                                matTooltip="No se permiten caracteres especiales al principio('.test@mail.com'), caracteres especiales seguidos ('te..st@mail.com'), más de un arroba ('te@st@mail.com'), espacios ('te st@mail.com'), caracteres especiales al terminar el dominio ('test.@mail.com'), ni correos de extensión mayor a 100 caracteres">help</mat-icon>
                            <mat-error *ngIf="address.get('email').errors?.pattern">
                                {{ utils.giveMessageError('INCORRECT_FORMAT', 'correo') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <mat-form-field appearance="standard">
                            <mat-label> Cantidad de guías </mat-label>
                            <input matInput type="text" onlyNumbersInt placeholder="Escribe la cantidad"
                                formControlName="numberOfGuides">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-md-4" formGroupName="cargoMeasure">
                        <mat-form-field appearance="standard">
                            <mat-label>Peso<span class="asterisk">*</span></mat-label>
                            <input matInput type="text" currencyMask onlyNumbersInt formControlName="totalWeigth">
                            <mat-error *ngIf="address.get('cargoMeasure.totalWeigth').errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <mat-form-field appearance="standard">
                            <mat-label>Tiempo Pactado(horas):</mat-label>
                            <mat-select formControlName='timePact' [(value)]="address.get('timePact').value">
                                <mat-option *ngFor="let hour of hourList" [value]="hour.value">
                                    {{hour.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <mat-form-field appearance="standard">
                            <mat-label>Tiempo Pactado(minutos):</mat-label>
                            <mat-select formControlName='minutePact' [(value)]="address.get('minutePact').value">
                                <mat-option *ngFor="let minute of minuteList" [value]="minute.value">
                                    {{minute.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div [formGroupName]='indexAddress' class="row w-100 pl-3  align-items-end">
                    <!--Address without ThirdParty-->
                    <div class="col-md-6"
                        *ngIf="!cargo?.ministry || formDestination['controls'][indexDestination]?.get('isInternational')?.value">
                        <!--Destination address-->
                        <app-autocomplete-address [value]="address.value.address" [addLatLong]="true"
                            [title]="'Dirección'"
                            (emitToParent)="onSelectAddressDestination($event,indexDestination,indexAddress)"
                            [internationalCode]="formDestination['controls'][indexDestination]?.get('country.code')?.value">
                        </app-autocomplete-address>
                    </div>
                    <!--Address with ThirdParty-->
                    <div class="col-md-6"
                        *ngIf="cargo?.ministry && !formDestination['controls'][indexDestination]?.get('isInternational')?.value">
                        <app-autocomplete-thirdparty
                            [options]="getItemCompanyDownloadOptions(indexDestination, indexAddress)"
                            [inputFormControl]="getItemCompanyDownloadControls(indexDestination, indexAddress)"
                            [validate]="validate">
                        </app-autocomplete-thirdparty>
                    </div>
                    <div class="col-md-6"
                        *ngIf="cargo?.ministry && !city.get('isInternational')?.value && address.value?.thirdPartyConsignment?.documentNumber && city.get('municipalityCode')?.value">
                        <app-autocomplete-thirdparty-address
                            [inputFormControl]="getItemThirdPartyAddress(indexDestination, indexAddress)"
                            [validate]="validate" [options]="{
                            title: 'Dirección de destinatario',
                            companyId: address.value.thirdPartyConsignment?.documentNumber,
                            municipalityCode: city.value.municipalityCode,
                            thirdParty: getItemCompanyDownloadControls(indexDestination, indexAddress).value,
                            initialValue: address.value?.address
                        }">
                        </app-autocomplete-thirdparty-address>
                    </div>
                </div>

            </div>
            <mat-card-content class="text-right download__actions" *ngIf="!hideOptionsAddAddress">
                <button type="button" mat-button class="button-width-auto"
                    (click)="addAddressFormDestination(indexDestination)">
                    <i class="fas fa-plus-circle"></i>
                    <span>Agregar otra dirección</span>
                </button>
            </mat-card-content>
        </div>

    </div>
    <mat-card-content class="text-left download__actions" *ngIf="!hideOptionsAddCity">
        <button type="button" mat-button class="button-width-auto" (click)="addCityForm()">
            <i class="fas fa-plus-circle"></i>
            <span>Agregar otro destino</span>
        </button>
    </mat-card-content>
</form>