<section class="row m-0 cost-center-item" *ngIf="activeTab && activeTab==='Centros de Costo'">
    <div class="col-10 m-0 p-0">
        <div class="row m-0 cost-center-item__content">
            <div class="col-12 col-md-3 cost-center-item--flex">
                <label class="color-secondary-dark text-bold">Código</label>
                <span>{{costCenter.code}}</span>
            </div>
            <div class="col-12 col-md-4 cost-center-item--flex">
                <label class="color-secondary-dark text-bold">Nombre</label>
                <span>{{costCenter.name}}</span>
            </div>
            <div class="col-12 col-md-3 cost-center-item--flex">
                <label class="color-secondary-dark text-bold">Estado</label>
                <span>{{costCenter.active | basicState}}</span>
            </div>
        </div>
    </div>
    <div 
    class="col-2 m-0 p-0" 
    permission-display 
    [module]="permission.payments.module"
    [functionality]="permission.payments.deleteCostCenter">
        <i class="fas fa-trash cost-center-item__trash--color cursor-pointer text-center" [matTooltip]="deleteCostCenterTooltip" (click)="confirmDelete(costCenter?.id)"></i>
    </div>
</section>