<aside id="mainUploadGuides" class="col-md-12 mb-md-0 mb-12 h-100">
  <!--mat-tab-group (selectedIndexChange)="onSelectTab($event)" permission-tab [permissionModule]="permission.guide.module"
    [permissionListName]="[permission.guide.loadGuides, permission.guide.changeStateGuides, permission.guide.createGuidesFromExcel]">
    <mat-tab label="Cargar guías">
      <div class="row">
        <form class="col-md-12" [formGroup]="uploadGuideForm" (submit)="uploadFile()">
          <div class="row my-3">
            <div class="col-md-3">
              <mat-card-content class="company-select">
                <app-select-company [options]="optionsCompany"
                  [inputFormControl]="companyFormControl"></app-select-company>
              </mat-card-content>
            </div>
            <div class="col-md-3">
              <mat-card-content>
                <app-file [inputFormControl]="uploadGuideForm.get('file')" [options]="{storageName: 'uploadGuide'}"></app-file>
              </mat-card-content>
            </div>
          </div>
          <div class="row" *ngIf="activeTab === 'uploadGuide'">
            <div class="col-md-12 text-center">
              <mat-card-actions>
                <button mat-raised-button color="primary" class="button-load-f12" type="submit">Cargar archivo</button>
              </mat-card-actions>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>
    <mat-tab label="Cambio de estado">
      <div class="row">
        <div class="col-md-12 title-component">
          Cambio de estado de guías
        </div>
      </div>
      <div class="row">
        <form class="col-md-12" [formGroup]="setStateGuidesForm" (submit)="uploadFile()">
          <div class="row">
            <div class="col-md-3">
              <mat-card-content class="company-select">
                <app-select-company [title]="'Compañía'" [inputFormControl]="companyFormControl"></app-select-company>
              </mat-card-content>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="standard">
                <mat-label>Estado<span class="asterisk">*</span></mat-label>
                <span class="spinner-input" *ngIf="stateLoading">
                  <i class="fas fa-circle-notch"></i>
                </span>
                <mat-select formControlName="state" id="setStateGuidesFormState">
                  <mat-option value="{{state.id}}" *ngFor="let state of listStates">
                    {{state.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="setStateGuidesForm.get('state').errors?.required">
                  {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field appearance="standard" class="container-input-date-picker cursor-pointer">
                <mat-label>Fecha<span class="asterisk">*</span></mat-label>
                <input readonly class="cursor-pointer" type="text" id="setStateGuidesFormDate" matInput
                  [matDatepicker]="datePicker" (click)="datePicker.open()" formControlName="date"
                  (dateChange)="onChangeDatePicker($event)">
                <mat-error *ngIf="setStateGuidesForm.get('date').errors?.required">
                  {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field appearance="standard" class="container-input-date-picker">
                <mat-label>Hora<span class="asterisk">*</span></mat-label>
                <input type="time" #timePicker id="setStateGuidesFormTime" matInput formControlName="time"
                  (change)="onChangeTime()" (click)="onChangeTime()">
                <mat-error *ngIf="setStateGuidesForm.get('time').errors?.required">
                  {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-card-content>
                <app-file [inputFormControl]="setStateGuidesForm.get('file')" [options]="{storageName: 'setStateGuides'}"></app-file>
              </mat-card-content>
            </div>
          </div>
          <div class="row" *ngIf="activeTab === 'setStates'">
            <div class="col-md-12 text-center">
              <mat-card-actions>
                <button mat-raised-button color="primary" class="button-load-f12" type="submit">Cargar archivo</button>
              </mat-card-actions>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>
    <mat-tab label="Creación de guías desde Excel">
      <app-create-guides-from-excel></app-create-guides-from-excel>
    </mat-tab>
  </mat-tab-group-->
</aside>