import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { CargoTypeComponent, HiddenFieldsCargoType } from '../cargo-detail/components/cargo-type/cargo-type.component';
import { CargoCharacteristicComponent, HiddenFieldsCargoCharacteristic } from '../cargo-detail/components/cargo-characteristic/cargo-characteristic.component';
import { HiddenFieldsLoadDownloadCargo, LoadDownloadCargoComponent } from '../cargo-detail/components/load-download-cargo/load-download-cargo.component';
import { HiddenFieldsGeneralDataCargo, GeneralDataCargoComponent } from '../cargo-detail/components/general-data-cargo/general-data-cargo.component';
import { CargoConsignment, CargoEditConsignmentAddress, formBasicModel, formModel } from 'src/app/core/interfaces/cargoEditConsignment';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { CargoResources } from '../manual-creation-cargo/resources/cargo';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from 'src/app/core/resources/utils';
import { CargoDetailService } from '../cargo-detail/cargo-detail.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { Permission } from 'src/app/core/resources/permission';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-consignment-form',
  templateUrl: './consignment-form.component.html',
  styleUrls: ['./consignment-form.component.scss'],
  providers: [CargoResources]
})
export class ConsignmentFormComponent implements OnInit {

  @ViewChild(CargoTypeComponent, { static: false })
  CargoTypeComponent: CargoTypeComponent;
  @ViewChild(CargoCharacteristicComponent, { static: false })
  CargoCharacteristicComponent: CargoCharacteristicComponent;
  @ViewChild(LoadDownloadCargoComponent, { static: false })
  LoadDownloadCargoComponent: LoadDownloadCargoComponent;
  @ViewChild(GeneralDataCargoComponent, { static: false })
  GeneralDataCargoComponent: GeneralDataCargoComponent;

  public permission = Permission;
  public reactiveConsignmentForm: ReactiveForm;
  public reactiveBasicConsignmentForm: ReactiveForm;
  public serviceRequest: Cargo;
  public originalServiceRequest: Cargo;
  public hiddenFieldsGeneralDataCargo: HiddenFieldsGeneralDataCargo = {
    actionsForm: true,
    companyId: true,
    branchOfficeCode: true,
    riskProfile: true,
    consecutive: true,
    cargoState: true,
    estimatedTime: true,
    realTime: true,
    distancy: true
  };
  public hiddenFieldsCargoType: HiddenFieldsCargoType = {
    observationDriver: true,
    observation: true,
    tripType: true,
  };
  public hiddenFieldsCargoCharacteristic: HiddenFieldsCargoCharacteristic = {
    totalWeigth: true,
    unit: true,
    amountDeclared: true,
    vehicleType: true,
  };
  public hiddenFieldsLoadDownloadCargo: HiddenFieldsLoadDownloadCargo = {
    cellphone: true,
    email: true
  };
  private consecutive: string;
  public destinationId: number;
  public addressId: number;

  constructor(
    private formBuilder: FormBuilder,
    private cargoResources: CargoResources,
    private route: ActivatedRoute,
    private utils: Utils,
    private cargoDetailService: CargoDetailService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,

  ) {
    this.initConsignmentForm();
    this.initBasicConsignmentForm();
  }

  ngOnInit() {
    this.checkParams();
  }

  private checkParams() {
    const params = this.route.snapshot.params;
    const queryParams = this.route.snapshot.queryParams;
    if (!this.utils.objIsEmpty(params)) {
      if (this.utils.isDefined(params.consecutive)) {
        this.consecutive = params.consecutive;
        this.getDetailRequestService();
      }
      if (this.utils.isDefined(queryParams.d)) {
        this.destinationId = parseInt(queryParams.d);
      }
      if (this.utils.isDefined(queryParams.a)) {
        this.addressId = queryParams.a;
      }
    }
  }

  public setDestinationAddress(cargoResponse: Cargo) {
    let cargo: Cargo = this.utils.clone(cargoResponse);
    try {
      const destination = this.utils.clone(this.originalServiceRequest.cargoFeature.uploadDownload.destination[this.destinationId]);
      destination.addresses = [this.utils.clone(this.originalServiceRequest.cargoFeature.uploadDownload.destination[this.destinationId].addresses[this.addressId])];
      cargo.cargoFeature.uploadDownload.destination = [destination];
    } catch (e) {
      console.log(e)
      cargo = null;
    }
    this.serviceRequest = cargo;
  }

  private getDetailRequestService() {
    this.spinner.show();
    this.cargoDetailService.detailCargoByConsecutive(this.consecutive.toString()).subscribe((cargo: Cargo) => {
      if (!this.utils.isEmpty(cargo.manifestAuthorization)) {
        this.redirectEditConsignment(
          'Las remesas con manifiesto generado no se pueden editar',
          'Puede anular el manifiesto y volver a crear el servicio'
        );
        return;
      }
      if (!this.hasDifferentCities(cargo)) {
        this.redirectEditConsignment(
          'Solo se pueden editar remesas nacionales',
          'Puede anular el manifiesto y volver a crear el servicio'
        );
        return;
      }
      this.originalServiceRequest = this.utils.clone(cargo);
      this.setDestinationAddress(cargo);
      this.spinner.hide();
    });
  }

  hasDifferentCities(cargo: Cargo): boolean {
    try {
      const origin = cargo.cargoFeature.uploadDownload.origin.municipalityCode;
      const destinations = cargo.cargoFeature.uploadDownload.destination;
      return destinations.some(destination => destination.municipalityCode !== origin);
    } catch (error) {
      return false;
    }
  }

  /**
  * @description Initializes the consignmet form group
  */
  private initConsignmentForm() {
    this.reactiveConsignmentForm = new ReactiveForm(
      this.formBuilder,
      formModel
    );
  }

  /**
  * @description Initializes the basic consignment form group
  */
  private initBasicConsignmentForm() {
    this.reactiveBasicConsignmentForm = new ReactiveForm(
      this.formBuilder,
      formBasicModel
    );
  }

  public onEmitToParent($event: any): void {
    console.log("onEmitToParent", $event);
  }

  public onChangeCleanCargo($event: any): void {
    console.log("onChangeCleanCargo", $event);
  }

  public async onSubmit() {
    const isInvalidGeneralDataCargo = await this.GeneralDataCargoComponent.isInvalidFormData();
    if (isInvalidGeneralDataCargo) return;
    const isInvalidCargoType = await this.CargoTypeComponent.isInvalidFormData();
    if (isInvalidCargoType) return;
    const isInvalidCargoCharacteristic = await this.CargoCharacteristicComponent.isInvalidFormDataEditConsignment();
    const cargoCharacteristicComponent = this.CargoCharacteristicComponent.createBodyToSubmit();
    delete cargoCharacteristicComponent.cargoFeature.productType.type;
    if (isInvalidCargoCharacteristic) return;
    const isInvalidLoadDownloadCargo = await this.LoadDownloadCargoComponent.isInvalidForm();
    if (isInvalidLoadDownloadCargo) return;
    const loadDownloadCargoComponent = this.LoadDownloadCargoComponent.createBodyToSubmit();
    if (!this.utils.isDefined(cargoCharacteristicComponent.cargoModel)) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.UNSELECTED_FIELD, 'el tipo de mercancía'), undefined, 'alert');
      return;
    }

    const newDataConsignment: CargoConsignment = await this.creteNewDataConsignment();
    console.log("New Consignment", newDataConsignment)
    const originalDataConsignment: CargoConsignment = await this.createOriginalDataConsignment();
    console.log("Original Consignment", originalDataConsignment);
    const diffDataConsigment = await this.utils.getDeepDifferencesObjs(originalDataConsignment, newDataConsignment) as CargoConsignment;

    if (!this.utils.objIsEmpty(diffDataConsigment)) {
      this.spinner.show();
      const isValidArrival = await this.LoadDownloadCargoComponent.isValidArrivalDatetime(loadDownloadCargoComponent)
        .catch(() => false);

      if (!isValidArrival) {
        this.spinner.hide();
        return;
      }
      this.spinner.show();
      diffDataConsigment.cargoId = this.originalServiceRequest.id;
      // console.log("TO SEND", diffDataConsigment)
      if (diffDataConsigment.sender && !this.utils.isDefined(diffDataConsigment.sender.id)) {
        diffDataConsigment.sender.id = newDataConsignment.sender.id.toString();
      }
      if (diffDataConsigment.recipients && this.utils.isDefined(diffDataConsigment.recipients[this.destinationId])) {
        diffDataConsigment.recipients[this.destinationId].destinationId = parseInt(newDataConsignment.sender.id);
      }
      if (diffDataConsigment.recipients && this.utils.isDefined(diffDataConsigment.recipients[this.destinationId]) && this.utils.isDefined(diffDataConsigment.recipients[this.destinationId].addresses) && !this.utils.objIsEmpty(diffDataConsigment.recipients[this.destinationId].addresses[this.addressId])) {
        diffDataConsigment.recipients[this.destinationId].addresses[this.addressId].id = this.addressId.toString();
      }
      // console.log("Diff", diffDataConsigment);
      this.cargoDetailService.updateCargoConsignments(diffDataConsigment).subscribe(
        (response: any) => {
          //console.log("RESPONSE", response);
          this.spinner.hide();
          this.redirectEditConsignment(`Remesa ${this.getConsignmentToEdit} actualizada correctamente`);
        },
        (error: any) => {
          console.log("ERROR", error);
          this.spinner.hide();
          const errorMessage = error && error.error && error.error.message ? error.error.message : "Error al actualizar la remesa";
          this.snackBarService.openSnackBar(errorMessage, undefined, 'error');
        }
      );
    } else {
      this.snackBarService.openSnackBar('No hay cambios para actualizar', undefined, 'alert');
      return;
    }
  }

  public get getConsignmentToEdit(): string {
    return this.originalServiceRequest.cargoFeature.uploadDownload.destination[this.destinationId].addresses[this.addressId].consignments[0];
  }

  private async creteNewDataConsignment(): Promise<CargoConsignment> {
    const generalDataCargo = this.GeneralDataCargoComponent.createBodyToSubmit();
    // console.log("generalDataCargo", generalDataCargo);
    const cargoTypeBody = this.CargoTypeComponent.createBodyToSubmit();
    const cargoCharacteristicComponent = this.CargoCharacteristicComponent.createBodyToSubmit();
    delete cargoCharacteristicComponent.cargoFeature.productType.type;
    const loadDownloadCargoComponent = this.LoadDownloadCargoComponent.createBodyToSubmit();
    const lastAddressLoad = loadDownloadCargoComponent.cargoFeature.uploadDownload.origin.addresses[loadDownloadCargoComponent.cargoFeature.uploadDownload.origin.addresses.length - 1];
    const cargo: Cargo = {
      cargoOwner: this.serviceRequest.cargoOwner,
      cargoModel: cargoTypeBody.cargoModel,
      cargoFeature: cargoTypeBody.cargoFeature,
    };
    const cargoConsignment: CargoConsignment = {
      cargoId: this.serviceRequest.id,
      cargoOwner: generalDataCargo.cargoOwner,
      packagingType: cargoCharacteristicComponent.cargoModel.packagingType,
      productType: cargoCharacteristicComponent.cargoFeature.productType,
      tripType: cargoTypeBody.cargoModel.tripType,
      cargoType: cargoTypeBody.cargoModel.cargoType,
      serviceType: cargoTypeBody.cargoModel.serviceType,
      loadDate: loadDownloadCargoComponent.dateLoad,
      sender: {
        name: loadDownloadCargoComponent.cargoFeature.uploadDownload.origin.name,
        municipalityCode: loadDownloadCargoComponent.cargoFeature.uploadDownload.origin.municipalityCode,
        country: loadDownloadCargoComponent.cargoFeature.uploadDownload.origin.country,
        // address
        id: lastAddressLoad.id,
        address: lastAddressLoad.address,
        location: lastAddressLoad.location,
        thirdParty: lastAddressLoad.thirdPartyConsignment,
        time: lastAddressLoad.time,
        agreedTime: lastAddressLoad.timePact,
        agreedMinutes: lastAddressLoad.minutePact
      },
      recipients: {}
    };
    cargoConsignment.cargoNature = this.cargoResources.getCargoNature(cargo);
    cargoConsignment.operationType = this.cargoResources.getTypeOperation(cargo);

    const destinationData = loadDownloadCargoComponent.cargoFeature.uploadDownload.destination[0];
    const destinationAddressData = destinationData.addresses[this.addressId];
    const address: CargoEditConsignmentAddress = {
      loadedQuantity: destinationAddressData.cargoMeasure.totalWeigth,
      weightUnit: destinationAddressData.cargoMeasure.unit,
      id: destinationAddressData.id,
      address: destinationAddressData.address,
      location: destinationAddressData.location,
      thirdParty: destinationAddressData.thirdPartyConsignment,
      time: destinationAddressData.time,
      agreedTime: destinationAddressData.timePact,
      agreedMinutes: destinationAddressData.minutePact
    };

    const addresses = { [this.addressId]: address };

    cargoConsignment.recipients[this.destinationId] = {
      destinationId: this.destinationId,
      downloadDate: destinationData.downloadDate,
      name: destinationData.name,
      municipalityCode: destinationData.municipalityCode,
      country: destinationData.country,
      addresses
    }

    return cargoConsignment;
  }

  private createOriginalDataConsignment() {
    const lastAddressLoad = this.originalServiceRequest.cargoFeature.uploadDownload.origin.addresses[this.originalServiceRequest.cargoFeature.uploadDownload.origin.addresses.length - 1];
    const cargoConsignment: CargoConsignment = {
      cargoId: this.serviceRequest.id,
      cargoOwner: this.serviceRequest.cargoOwner,
      packagingType: this.originalServiceRequest.cargoModel.packagingType,
      productType: this.originalServiceRequest.cargoFeature.productType,
      operationType: this.originalServiceRequest.cargoModel.operationType,
      cargoNature: this.originalServiceRequest.cargoModel.cargoNature,
      tripType: this.originalServiceRequest.cargoModel.tripType,
      cargoType: this.originalServiceRequest.cargoModel.cargoType,
      serviceType: this.originalServiceRequest.cargoModel.serviceType,
      loadDate: this.originalServiceRequest.dateLoad,
      sender: {
        name: this.originalServiceRequest.cargoFeature.uploadDownload.origin.name,
        municipalityCode: this.originalServiceRequest.cargoFeature.uploadDownload.origin.municipalityCode,
        country: this.originalServiceRequest.cargoFeature.uploadDownload.origin.country,
        // address
        id: lastAddressLoad.id,
        address: lastAddressLoad.address,
        location: lastAddressLoad.location,
        thirdParty: lastAddressLoad.thirdPartyConsignment,
        time: lastAddressLoad.time,
        agreedTime: lastAddressLoad.timePact,
        agreedMinutes: lastAddressLoad.minutePact
      },
      recipients: {}
    };

    const destinationData = this.originalServiceRequest.cargoFeature.uploadDownload.destination[this.destinationId];
    const destinationAddressData = destinationData.addresses[this.addressId];
    const address: CargoEditConsignmentAddress = {
      loadedQuantity: destinationAddressData.cargoMeasure.totalWeigth,
      weightUnit: destinationAddressData.cargoMeasure.unit,
      id: destinationAddressData.id,
      address: destinationAddressData.address,
      location: destinationAddressData.location,
      thirdParty: destinationAddressData.thirdPartyConsignment,
      time: destinationAddressData.time,
      agreedTime: destinationAddressData.timePact,
      agreedMinutes: destinationAddressData.minutePact
    };

    const addresses = { [this.addressId]: address };

    cargoConsignment.recipients[this.destinationId] = {
      destinationId: this.destinationId,
      downloadDate: destinationData.downloadDate,
      name: destinationData.name,
      municipalityCode: destinationData.municipalityCode,
      country: destinationData.country,
      addresses
    }
    return cargoConsignment;
  }

  public goBack() {
    this.router.navigateByUrl(
      `cargo/detail/${this.consecutive}`
    );
  }

  private redirectEditConsignment(title: string, description?: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title,
      hideBtnCancel: true,
      description: description ? description : null
    };
    dialogConfig.height = "auto";
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialog = this.dialog.open(DialogComponent, dialogConfig);
    dialog.afterClosed().subscribe(() => {
      this.goBack();
    });
  }

}
