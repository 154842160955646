import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';

import { Global } from 'src/app/core/resources/global';
import { GenerateGuideService } from './generate-guide.service';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/resources/utils';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-generate-guide',
  templateUrl: './generate-guide.component.html',
  styleUrls: ['./generate-guide.component.scss'],
  providers: [AuthService, Global]
})
export class GenerateGuideComponent {

  typeGuideActive: string = 'guidesInCollection';
  listGuides: Array<BasicGuide>;
  searchGuideText: string;
  searchCargoText: string;
  stateLoading = false;
  pageKey = 1;
  pageSize = 10;
  scrollDistance = 1;
  enablePagingScroll = true;
  throttle = 300;
  itemsSelecteds = [];
  company: FormControl = new FormControl();
  optionsCompany = {
    title: 'Operación',
    appearance: 'outline'
  };

  constructor(
    public generateGuideService: GenerateGuideService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    public authService: AuthService,
  ) {
    this.applyFilter();
  }

  /*onSelectItem($event) {
    this.itemsSelecteds = $event;
  }*/

  cleanFilter() {
    this.searchGuideText = '';
    this.searchCargoText = '';
    this.optionsCompany['initialNit'] = '';
    this.optionsCompany = { ...this.optionsCompany };
    this.resetValuesPagingScroll();
    this.getGuides();
  }

  applyFilter() {
    this.resetValuesPagingScroll();
    this.getGuides();
  }

  getGuides() {
    this.spinner.show();
    const companyId = this.company.value && this.company.value.companyId ? this.company.value.companyId : null;
    this.generateGuideService.getGuides(this.pageKey, this.pageSize, this.searchGuideText, this.searchCargoText, companyId).subscribe(
      (data) => {
        if (this.utils.isDefined(data)) {
          if (data && data.length < 1)
            this.enablePagingScroll = false;
          else if (this.listGuides && this.listGuides.length)
            this.listGuides = this.listGuides.concat(data);
          else
            this.listGuides = data;
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.resetValuesPagingScroll();
      }
    );
  }

  onScrollDown() {
    if (this.enablePagingScroll) {
      this.pageKey++;
      this.getGuides();
    }
  }

  resetValuesPagingScroll() {
    this.enablePagingScroll = true;
    this.listGuides = [];
    this.itemsSelecteds = [];
    this.pageKey = 1;
  }

  /*generateMultiplesGuides(isGuide?: boolean) {
    let guidesChecked: Array<any> = this.guideService.getGuidesSelected(this.itemsSelecteds, 'guideId');
    if (guidesChecked.length) {
      this.spinner.show();
      this.guideService.getMultipleGuidesPDF(guidesChecked, isGuide).subscribe(
        (response) => {
          this.spinner.hide();
          if (response && response.body) {
            this.snackBarService.openSnackBar('Se descargará el archivo en breve');
            this.utils.downloadFile(response.body, 'Desprendible de Guías');
          }
          else this.snackBarService.openSnackBar("Ocurrió un error generando el archivo", undefined, 'error');
          this.resetValuesPagingScroll();
          this.getGuides();
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Ocurrió un error generando el archivo", undefined, 'error');
        }
      );
    } else {
      this.snackBarService.openSnackBar("Debe seleccionar por lo menos una guía", undefined, 'alert');
    }
  }*/
}


