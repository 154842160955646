import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { Router } from '@angular/router';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GoogleService } from 'src/app/core/services/google.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CargoItemService } from '../../../cargo-item/cargo-item.service';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { environment } from 'src/environments/environment';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { map, timeout } from "rxjs/operators";
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { FormArray, FormGroup } from '@angular/forms';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { Observable } from 'rxjs';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Utils } from 'src/app/core/resources/utils';

@Component({
  selector: 'app-total-value',
  templateUrl: './totalValue.component.html',
  styleUrls: ['./totalValue.component.scss'],
  providers: [AuthService, Global, GoogleService,]
})
export class TotalValueComponent implements OnInit {

  reteicaValue: number;
  retefuenteValue: number;
  freeTaxes: boolean = false;
  errorRNDC: string[] = [];
  permission = Permission;
  automaticConsignmentGeneration: boolean = true;
  constructor(
    private router: Router,
    public manualCreationCargoService: ManualCreationCargoService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public cargoItemService: CargoItemService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    public permissionRole: PermissionRole,
    private vehiclesService: VehiclesService,
    private utils: Utils
  ) { }

  ngOnInit() {
    let settings = this.authService.getSettingsSaasCompany();
    if (settings && settings.length) {
      const foundSetting = settings.find(setting => setting.id === 'automaticConsignmentGeneration');
      this.automaticConsignmentGeneration = foundSetting && this.utils.isDefined(foundSetting.active) ? foundSetting.active : false;
    }
    this.checkFreeTaxes().subscribe(
      (isFreeOfTaxes: boolean) => {
        this.freeTaxes = isFreeOfTaxes;
        if (isFreeOfTaxes) {
          const reteicaControl = this.manualCreationCargoService.cargoForm.get('shippingCost.reteicaPercentage');
          const reteftePercentage = this.manualCreationCargoService.cargoForm.get('shippingCost.reteftePercentage');

          reteicaControl.setValue(0);
          reteftePercentage.setValue(0);
          this.retefuenteValue = 0;
          this.reteicaValue = 0;
        } else {
          this.getTaxesValues();
        }
      },
      () => {
        this.getTaxesValues();
      }
    );
  }

  private getTaxesValues() {
    try {
      // tslint:disable-next-line: max-line-length
      this.reteicaValue = Math.floor(
        this.manualCreationCargoService.cargoForm.value.shippingCost.totalCost *
        this.manualCreationCargoService.formOrigin.get('reteica').value
      ) / 100;
    } catch (error) {
      this.reteicaValue = 0;
    }

    try {
      this.retefuenteValue = Math.floor(
        this.manualCreationCargoService.cargoForm.value.shippingCost.totalCost
      ) * (1 / 100);
    } catch (error) {
      this.retefuenteValue = 0;
    }
  }

  checkFreeTaxes() {
    const chargeOwnFleet = this.authService.getCompany().chargeOwnFleet;
    return new Observable<boolean>(observer => {
      if (chargeOwnFleet) {
        observer.next(false);
        observer.complete();
        return;
      }

      const vehiclesWithLicensePlate = this.manualCreationCargoService.vehiclesSelectedWithLicensePlate
        .filter((vehicleCargo: VehicleCargo) => vehicleCargo.licensePlate);

      if (vehiclesWithLicensePlate.length === 0 || vehiclesWithLicensePlate.length !== this.manualCreationCargoService.vehiclesSelectedWithLicensePlate.length) {
        observer.next(false);
        observer.complete();
        return;
      }

      let pendingVehicles = vehiclesWithLicensePlate.length;
      let free = true;

      vehiclesWithLicensePlate.forEach((vehicleCargo: VehicleCargo) => {
        if (vehicleCargo.licensePlate) {
          this.vehiclesService.getVehicle(vehicleCargo.licensePlate).subscribe(
            (vehicle: Vehicle) => {
              free = free && vehicle && vehicle.ownFleet;
              pendingVehicles--;
              if (pendingVehicles <= 0) {
                observer.next(free);
                observer.complete();
              }
            },
            () => {
              pendingVehicles--;
              if (pendingVehicles <= 0) {
                observer.next(false);
                observer.complete();
              }
            }
          );
        } else {
          pendingVehicles--;
          if (pendingVehicles <= 0) {
            observer.next(false);
            observer.complete();
          }
        }
      });
    });
  }

  nextStep() {
    let path = '/cargo/list/scheduledLoads';
    this.spinner.show();
    this.manualCreationCargoService.processCargo()
      .then((data: Cargo[]) => {
        if (data.every(num => num.id === '0' || num.consecutive === 0)) {
          this.manualCreationCargoService.showResponseCreateCargo(
            'Ocurrió un error al crear el servicio',
            (undefined)
          );
        } else if (data.some(num => num.id === '0' || num.consecutive === 0)) {
          const cargosCreated = data.filter(num => num.id !== '0' && num.consecutive !== 0);
          this.manualCreationCargoService.showResponseCreateCargo(
            'Solo se crearon exitosamente los servicios con los consecutivos',
            undefined,
            this.manualCreationCargoService.getConsecutive(cargosCreated), path
          );
          this.manualCreationCargoService.applyTravelExpenses(cargosCreated);
        } else {
          this.belowSicetac(data, path);
          this.manualCreationCargoService.applyTravelExpenses(data);
        }
      })
      .catch((error) => {
        this.manualCreationCargoService.showResponseCreateCargo('Ocurrió un error al crear el servicio',
          (Array.isArray(error) ? error : undefined))
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  get isTeclogiUser(): boolean {
    return this.authService.getCompany().companyId === environment.rootNit;
  }

  stepBack() {
    this.manualCreationCargoService.getComponentActive().setId(4);
    this.manualCreationCargoService.getComponentActive().getStep().setId(2);
    this.router.navigate([this.manualCreationCargoService.steps.methodPayment.url]);
  }

  get hasManifest(): boolean {
    return !!this.manualCreationCargoService.cargoForm.value.ministry;
  }

  public belowSicetac(cargo: Cargo[], path: string) {
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approvedBelowSicetac)) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = true;
      if (cargo[0].belowSicetac && !cargo[0].freightValueApprovedBelowSicetac) {
        const min = formatter.format(cargo[0].minimumApprovedValueSicetac);
        dialogConfig.data = {
          title: '¿Desea aprobar el flete por debajo del SICETAC?',
          descriptionHTML: `El flete mínimo permitido por SICETAC es de <strong>${min}</strong>, si desea ajustarlo por favor realice una bonificación para ajustarse al valor mínimo permitido.`,
          btnDetailCargo: true,
          closeModal: true,
          path: `cargo/detail/${cargo[0].consecutive}`,
        };
        const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.state) {
            this.manualCreationCargoService.approvedBelowSiceTac(cargo[0].id).subscribe(
              () => {
                this.openConfirmBelowSicetac(path);
              },
              (error) => {
                this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
              }
            );
            timeout(5000)
          }
        })
      }
    } else {
      if (cargo[0].belowSicetac && !cargo[0].freightValueApprovedBelowSicetac) {
        const min = formatter.format(cargo[0].minimumApprovedValueSicetac);
        this.snackBarService.openSnackBar(`El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`, undefined, 'alert');
      }
    }
    this.manualCreationCargoService.showResponseCreateCargo(
      'Tu servicio ha sido creado exitosamente con los consecutivos',
      undefined,
      this.manualCreationCargoService.getConsecutive(cargo), path
    );
  }

  openConfirmBelowSicetac(path) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: `Aprobación exitosa`,
      icon: true,
      description: `Se ha aprobado el servicio por un flete que está por debajo del valor mínimo permitido por el SICETAC`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate([path]);
    })
  }
}
