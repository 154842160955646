import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GoogleService } from 'src/app/core/services/google.service';
import { LatLngLiteral } from '@agm/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AuthorizedSitesFormComponent } from '../sites/authorized-sites-form/authorized-sites-form.component';
import { RouteAuthorizedPoint } from 'src/app/core/interfaces/route-authorized-point';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { RouteItinerary } from 'src/app/core/interfaces/route-itinerary';
import { RouteControlPoint } from 'src/app/core/interfaces/route-control-point';
import { RouteReferenceAuthorizedPoint } from 'src/app/core/interfaces/route-reference-authorized-point';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { PlanningRoute } from 'src/app/core/interfaces/planning-route';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { RouteMessages } from 'src/app/core/messages/route-messages.enum';
import { RouteBasicCity } from 'src/app/core/interfaces/route-basic-city';
import { ConfirmRouteCreationComponent } from './confirm-route-creation/confirm-route-creation.component';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Global } from 'src/app/core/resources/global';
import { RoutesMapComponent } from '../routes-map/routes-map.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';

@Component({
  selector: 'app-planning-form',
  templateUrl: './planning-form.component.html',
  styleUrls: ['./planning-form.component.scss']
})
export class PlanningFormComponent implements AfterViewInit {
  permission = Permission;
  mode: 'RUTE' | 'itinerary' | 'point1' | 'point2' | 'point3' | 'point4' = 'RUTE';
  private params: { id?: string } = {};
  //Route
  currentRoute: PlanningRoute;
  originCity: RouteBasicCity = null;
  destinationCity: RouteBasicCity = null;
  itineraries: RouteItinerary[] = [];
  bestItinerary: RouteItinerary;
  //Itinerary
  currentItinerary: RouteItinerary;
  sites: Array<RouteAuthorizedPoint> = [];
  locations = [];
  locationNames: FormControl[] = [];
  itineraryTitle: string = "";
  itineraryActive: boolean = true;
  itineraryCode: string = "";
  //Authorized Points
  readonly pointsOptions = this.global.authorizedPoints;
  searchControl: FormControl = new FormControl('')
  searchResult: Array<RouteAuthorizedPoint> = [];
  //Map
  private renderer: google.maps.DirectionsRenderer = null;
  mapOptions: OptionsAutocomplete = {
    clickMap: true,
    clickMapIcon: this.global.pathMarkerDestination
  };

  constructor(
    private googleService: GoogleService,
    private planningRouteService: PlanningRouteService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackBarService,
    public global: Global,
    public spinner: NgxSpinnerService,
    private permissionRole: PermissionRole,
  ) {
    this.activatedRoute.params.subscribe((params) => this.params = params);
  }

  ngAfterViewInit() {
    document.getElementById(this.mapComponent.mapId).style.height = (document.getElementById('section-complete-height').clientHeight - 30) + 'px';
    this.mapComponent.map.setOptions({
      styles: [
        {
          "featureType": "poi",
          "stylers": [
            { "visibility": "off" }
          ]
        }
      ],
      disableDefaultUI: true
    });
    this.mapComponent.map.setZoom(5);
    if (!!this.params.id)
      this.getRoutePlan(this.params.id)
    else {
      this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      this.goBack();
    }
  }

  private getRoutePlan(id: string) {
    this.itineraries = [];
    this.locations = [];
    this.locationNames = [];
    this.sites = [];
    this.bestItinerary = null;
    this.spinner.show();
    this.planningRouteService.getRoute(id).subscribe(
      (route: PlanningRoute) => {
        this.spinner.hide();
        this.currentRoute = route;
        if (route.origin && route.origin.name)
          this.originCity = route.origin;
        if (route.destination && route.destination.name)
          this.destinationCity = route.destination;
        this.showRoutePlanBase();

        if (route.itineraries && route.itineraries.length) {
          const sortedItineraries = route.itineraries.sort((a, b) => a.estimatedTime - b.estimatedTime);
          this.bestItinerary = sortedItineraries[0];
          this.itineraries = route.itineraries;
        }
      }, (error) => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        this.goBack();
      }
    )
  }

  private showRoutePlanBase() {
    this.locations = [];
    this.locationNames = [];
    this.sites = [];
    if (this.originCity && this.originCity.location)
      this.updateFullLocations(0);
    if (this.destinationCity && this.destinationCity.location)
      this.updateFullLocations(this.locations.length + 1);
    this.updateMap();
  }

  @ViewChild('map', { static: false }) mapComponent: RoutesMapComponent;
  public drop = (e: CdkDragDrop<string[]>) => {
    moveItemInArray(this.locationNames, e.previousIndex, e.currentIndex);
    moveItemInArray(this.locations, e.previousIndex, e.currentIndex);
    this.updateFullLocations(e.currentIndex);
  }


  public appendLocation = (value?: { address: { name: string }, location: LatLngLiteral }) => {
    const locationName = new FormControl(value && value.address ? value.address.name : '', [Validators.required]);
    const location = new FormControl(value ? value : null, [Validators.required]);
    if (!this.hasEditPermission) {
      locationName.disable();
      location.disable();
    }
    this.locationNames.push(locationName);
    this.locations.push(location);
  }

  public deleteLocation = (i: number) => {
    this.locationNames = [
      ...this.locationNames.slice(0, i),
      ...this.locationNames.slice(i + 1)
    ];
    this.locations = [
      ...this.locations.slice(0, i),
      ...this.locations.slice(i + 1)
    ];
    this.updateFullLocations(i);
  }

  cleanMap = () => {
    this.mapComponent.clearRoutes();
  }



  getFullRouteLocations(): google.maps.LatLngLiteral[] {
    const locations = [];
    if (this.originCity && this.originCity.location)
      locations[0] = this.originCity.location;
    else
      locations[0] = null;

    locations.push(...this.locations.filter(v => v && v.value && v.value.location).map(v => v.value.location));

    if (this.destinationCity && this.destinationCity.location)
      locations.push(this.destinationCity.location)
    else
      locations.push(null);
    return locations;
  }

  updateMap() {
    this.cleanMap();
    this.googleService.getRouteData(this.getFullRouteLocations(), 'DRIVING', true).then(
      (data: { cargoDistancy: number, cargoEstimatedTime: number, cargoRoute: google.maps.DirectionsResult }) => {

        for (const route in data.cargoRoute.routes) {
          const routeData = data.cargoRoute.routes[route];

          let duration: number = 0;
          let distance: number = 0;
          routeData.legs.forEach((leg) => {
            duration += (leg.duration.value);
            distance += (leg.distance.value);
          });

          const optionRenderer = this.mapComponent.printDirection(
            data.cargoRoute,
            {
              polylineOptions: {
                strokeColor: '#000000',
                strokeOpacity: 0.2
              },
              markerOptions: { opacity: 0 },
            }
          );
          optionRenderer.setRouteIndex(parseInt(route));
        }
        this.renderer = this.mapComponent.printDirection(
          data.cargoRoute,
          {
            polylineOptions: {
              strokeColor: '#02d7dc',
              strokeOpacity: 1,
              zIndex: 1000
            },
            markerOptions: { opacity: 0 },
          }
        );
      }
    )
  }

  public onLocation(e, i: number) {
    this.locationNames[i].setValue(e.address.name);
    this.locations[i].setValue(e);
    this.updateFullLocations(i);
  }

  public updateFullLocations(setCenterAt: number = 0) {
    const locations = this.getFullRouteLocations();
    this.updateMarkers(locations);
    this.mapComponent.map.setCenter(locations[setCenterAt]);
    this.updateMap();
  }

  public updateMarkers(locations) {
    this.mapComponent.removeAllMarkers();
    const origin = locations[0];
    const stops = locations.slice(1, -1);
    const destination = locations[this.locations.length + 1];

    if (origin && origin.lat && origin.lng) {
      this.mapComponent.marker(
        origin.lat,
        origin.lng,
        'origin',
        undefined,
        { url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_origin.svg', scaledSize: new google.maps.Size(36, 36) }
      );
    }

    if (stops.length > 0) {
      let counter = 1;
      for (const stop of stops) {
        if (stop && stop.lat && stop.lng) {
          this.mapComponent.marker(
            stop.lat,
            stop.lng,
            'destination',
            undefined,
            { url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_stop.svg', scaledSize: new google.maps.Size(24, 24) },
            {
              color: '#FFFFFF',
              text: `${counter++}`,
              fontSize: '14px',
              fontWeight: 'bold'
            }
          );
        }
      }
    }

    if (destination && destination.lat && destination.lng) {
      this.mapComponent.marker(
        destination.lat,
        destination.lng,
        'destination',
        undefined,
        { url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_destination.svg', scaledSize: new google.maps.Size(36, 36) }
      );
    }

    if (this.sites && this.sites.length > 0) {
      for (const i in this.sites) {
        const site = this.sites[i];
        this._addSite(site);
      }
    }
  }

  async setCurrentItinerary(itineraryId: string) {
    let completeItinerary: RouteItinerary;
    try {
      completeItinerary = await this.planningRouteService.getItinerary(itineraryId).toPromise();
    } catch (error) {
      this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      return;
    }
    if (!completeItinerary || !completeItinerary.id) return;
    if (completeItinerary.controlPoints && completeItinerary.controlPoints.length)
      completeItinerary.controlPoints.sort((a, b) => a.order - b.order);
    this.currentItinerary = completeItinerary;
    this.itineraryTitle = completeItinerary.name;
    this.itineraryActive = !!completeItinerary.active;
    this.itineraryCode = completeItinerary.code || "";
    this.sites = [];
    completeItinerary.authorizedStops.forEach(point => this.addSite(point as any));
    this.locations = [];
    this.locationNames = [];
    if (completeItinerary.controlPoints && completeItinerary.controlPoints.length) {
      completeItinerary.controlPoints.forEach((controlPoint) => {
        const location = { location: controlPoint.location, address: { name: controlPoint.name } };
        this.appendLocation(location);
      });
    }
    const locations = this.getFullRouteLocations();
    this.updateMarkers(locations);
    this.mapComponent.map.setCenter(locations[0]);
    this.updateMap();
    this.mode = 'itinerary';
  }

  private getCurrentItinerary(checkCurrentItinerary: boolean = false): RouteItinerary {
    const origin = this.originCity;
    const destination = this.destinationCity;
    const route = this.renderer.getDirections().routes[0];

    const controlPoints: RouteControlPoint[] = [];
    const authorizedStops: RouteReferenceAuthorizedPoint[] = [];

    for (const id in this.sites) {
      const site = this.sites[id];
      if (!!site) {
        authorizedStops.push({
          id: site.id,
          fingerprint: {
            userId: this.authService
              .getUserSession()
              .information.document,
            userName: this.authService.getUserSession().information.name,
            date: DateManager.dateToString(new Date()),
          }
        })
      }
    }
    try {
      this.locations.forEach((loc, i) => {
        const location = loc.value;
        const controlPoint: RouteControlPoint = {
          order: i + 1,
          location: location.location,
          name: location.address.name,
          address: location.address.name,
          fingerprint: {
            userId: this.authService
              .getUserSession()
              .information.document,
            userName: this.authService.getUserSession().information.name,
            date: DateManager.dateToString(new Date()),
          }
        };
        controlPoints.push(controlPoint);
      });
    } catch (error) {
      this.snackbarService.openSnackBar("Debe seleccionar las direcciones de todos los puntos de control", undefined, 'alert');
      return null;
    }

    const itinerary: RouteItinerary = {
      active: this.itineraryActive,
      name: `${origin.name} - ${destination.name}`,
      overviewPolylines: this.renderer.getDirections().routes[0].overview_polyline,
      estimatedTime: route.legs[0].duration.value,
      estimatedDistance: route.legs[0].distance.value,
      mustSleep: route.legs[0].duration.value > (14 * 60 * 60),
      originPoint: {
        id: origin.id,
        name: origin.name,
        location: origin.location
      },
      destinationPoint: {
        id: destination.id,
        name: destination.name,
        location: destination.location
      },
      authorizedStops,
      controlPoints
    };
    //Set name
    if (this.itineraryTitle && this.itineraryTitle.trim())
      itinerary.name = this.itineraryTitle.trim();
    else if (checkCurrentItinerary && this.currentItinerary && this.currentItinerary.name)
      itinerary.name = this.currentItinerary.name;

    //Set code
    if (this.itineraryCode && this.itineraryCode.trim())
      itinerary.code = this.itineraryCode.trim();
    else if (checkCurrentItinerary && this.currentItinerary && this.currentItinerary.code)
      itinerary.code = this.currentItinerary.code;

    return itinerary;
  }

  get currentTitle(): string {
    switch (this.mode) {
      case 'RUTE': return 'Ruta';
      case 'itinerary': return 'Itinerario';
      default: return 'Puntos autorizados';
    }
  }

  goBack() {
    if (this.mode == 'RUTE')
      history.back();
    else if (this.mode.includes('point'))
      this.siteButton('itinerary');
    else {
      this.currentItinerary = null;
      this.itineraryTitle = "";
      this.itineraryActive = true;
      this.itineraryCode = "";
      this.siteButton('RUTE');
    }
  }

  /*activateItinerary(itineraryId:string) {
    this.spinner.show();
    this.planningRouteService.selectItinerary(itineraryId).subscribe(
      (result: RouteItinerary) => {
        if (result && result.id) {
          this.snackbarService.openSnackBar(RouteMessages.SUCCESS_SELECT_ITINERARY);
          let currentItineraries = [...this.itineraries];
          this.itineraries = [];
          for (const itinerary of currentItineraries) {
            this.planningRouteService
              .getItinerary((itinerary as any).id)
              .subscribe(
                (itinerary: RouteItinerary) => {
                  this.itineraries.push(itinerary);
                }
              )
          }
        }
        else this.snackbarService.openSnackBar(RouteMessages.ERROR_SELECT_ITINERARY, undefined, 'error');
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(RouteMessages.ERROR_SELECT_ITINERARY, undefined, 'error');
      }
    );
  }*/

  public getDurationLabel(seconds: number): string {
    if (!seconds) return '';
    const durationMsg = [];
    const duration = DateManager.durationFormat(seconds, "seconds");
    if (duration.years > 0) durationMsg.push(`${duration.years} ${duration.years > 1 ? 'años' : 'año'}`);
    if (duration.months > 0) durationMsg.push(`${duration.months} ${duration.months > 1 ? 'meses' : 'mes'}`);
    if (duration.days > 0) durationMsg.push(`${duration.days} d`);
    if (duration.hours > 0) durationMsg.push(`${duration.hours} h`);
    if (duration.minutes > 0) durationMsg.push(`${duration.minutes} min`);

    return durationMsg.join(' ');
  }
  private addAuthorizedPoint(position: LatLngLiteral, name: string, url: string): void {
    this.mapComponent.marker(position.lat, position.lng, "location", name, { url, scaledSize: new google.maps.Size(24, 24) });
  }

  siteButton(site: 'RUTE' | 'itinerary' | 'point1' | 'point2' | 'point3' | 'point4') {
    this.searchControl.setValue('');
    this.mode = site;
    if (['point1', 'point2', 'point3', 'point4'].includes(site))
      this.search();
    else if (site == 'RUTE')
      this.getRoutePlan(this.params.id);
  }

  private search() {
    this.searchResult = [];
    let searchParams = { active: "true", pointType: this.mode.replace('point', '') };
    this.spinner.show();
    this.planningRouteService.getAuthorizedPoints(searchParams).subscribe(
      (points: Array<RouteAuthorizedPoint>) => {
        this.spinner.hide();
        if (!!points && points.length)
          this.searchResult = points;
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  private _addSite(point: RouteAuthorizedPoint) {
    let url = "";
    switch (point.details.pointType.id) {
      case '1': url = "/assets/svg/icons/tl_ico__pin_google_maps_trip_hotel.svg"; break;
      case '2': url = "/assets/svg/icons/tl_ico__pin_google_maps_trip_gas.svg"; break;
      case '3': url = "/assets/svg/icons/tl_ico__pin_google_maps_trip_parking.svg"; break;
      case '4': url = "/assets/svg/icons/tl_ico__pin_google_maps_trip_restaurant.svg"; break;
    }
    if (url) this.addAuthorizedPoint(point.location, point.details.name, url);
  }

  addSite(point: RouteAuthorizedPoint) {
    if (!this.sites) this.sites = [];
    this.sites[point.id] = point;
    this.sites.length++;
    this._addSite(point);
  }

  removeSite(point: RouteAuthorizedPoint) {
    delete this.sites[point.id];
    this.sites.length--;
    this.updateFullLocations(0);
  }

  removeSiteFromCurrentSiteList(index: string) {
    delete this.sites[index];
    this.sites.length--;
    this.updateFullLocations(0);
  }

  createAuthorizedPoint() {
    const config = new MatDialogConfig();
    config.data = { type: this.mode.replace('point', '') };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.LARGE_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.matDialog.open(AuthorizedSitesFormComponent, config).afterClosed().subscribe(
      (result) => result && result.state && this.search()
    );
  }

  createItinerary() {
    this.renderer.setRouteIndex(0);
    const itinerary = this.getCurrentItinerary();
    if (!itinerary) return;
    const code = this.itineraryCode.trim();
    if (code) itinerary.code = code;
    this.spinner.show();
    this.planningRouteService
      .createRouteItinerary(itinerary)
      .subscribe(
        (result: RouteItinerary) => {
          this.spinner.hide();
          if (result && result.id) {
            this.snackbarService.openSnackBar(RouteMessages.SUCCESS_SELECT_ITINERARY);
            this.goBack();
          } else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');

        }, (error) => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        }
      );
  }

  saveFullItinerary() {
    this.renderer.setRouteIndex(0);
    const itinerary = this.getCurrentItinerary(true);
    if (!itinerary) return;
    itinerary['id'] = this.currentItinerary.id;
    itinerary.controlPoints.forEach(point => {
      const lastPoint = this.currentItinerary.controlPoints.find(p => p.location.lat === point.location.lat && p.location.lng === point.location.lng);
      if (lastPoint) point.id = lastPoint.id;
    });
    this.spinner.show();
    const promises = [];
    promises.push(this.updateAuthorizedPoints(itinerary.authorizedStops as RouteAuthorizedPoint[]));
    promises.push(this.deleteUnusedControlPoints(itinerary.controlPoints));
    promises.push(this.planningRouteService.saveItineraryControlPoints(itinerary.controlPoints, itinerary.id).toPromise());
    promises.push(this.planningRouteService.saveItineraryChanges(itinerary).toPromise());
    Promise.all(promises).then(
      (results) => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(RouteMessages.SUCCESS_SELECT_ITINERARY);
        this.goBack();
      }
    ).catch(
      (error) => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    );
  }

  private updateAuthorizedPoints(sites: RouteAuthorizedPoint[]): Promise<boolean> {
    try {
      if (!this.currentItinerary || !this.currentItinerary.id) return Promise.reject(false);
      const promises = [];
      let pointsToAdd = [];
      let pointsToRemove = [];
      if (!this.currentItinerary.authorizedStops || !this.currentItinerary.authorizedStops.length)
        pointsToAdd = sites.map(site => site.id);
      else {
        sites.forEach(site => {
          if (!this.currentItinerary.authorizedStops.find(p => p && p.id === site.id))
            pointsToAdd.push(site.id);
        });
        this.currentItinerary.authorizedStops.forEach(point => {
          if (point && point.id && !sites.find(s => s && s.id === point.id))
            pointsToRemove.push(point.id);
        });
      }
      if (pointsToAdd.length)
        promises.push(this.planningRouteService.addItineraryAuthorizedPoint(this.currentItinerary.id, pointsToAdd).toPromise());
      if (pointsToRemove.length)
        promises.push(this.planningRouteService.removeItineraryAuthorizedPoint(this.currentItinerary.id, pointsToRemove).toPromise());
      if (!promises.length)
        return Promise.resolve(true);
      return Promise.all(promises)
        .then(() => Promise.resolve(true))
        .catch(() => Promise.reject(false));
    } catch (error) {
      return Promise.reject(false);
    }
  }

  private deleteUnusedControlPoints(controlPoints: RouteControlPoint[]): Promise<boolean> {
    try {
      if (!this.currentItinerary || !this.currentItinerary.id) return Promise.reject(false);
      if (!this.currentItinerary.controlPoints || !this.currentItinerary.controlPoints.length) return Promise.resolve(true);
      const pointsToRemove = [];
      for (const point of this.currentItinerary.controlPoints) {
        if (point && point.id && !controlPoints.find(p => p && p.id === point.id))
          pointsToRemove.push(point.id);
      }
      if (!pointsToRemove.length)
        return Promise.resolve(true);
      this.planningRouteService.removeUnusedControlPoint(this.currentItinerary, pointsToRemove).toPromise()
        .then(() => Promise.resolve(true))
        .catch(() => Promise.reject(false));
    } catch (error) {
      return Promise.reject(false);
    }
  }

  get sortedItineraries(): RouteItinerary[] {
    return this.itineraries.sort((a, b) => a.name.localeCompare(b.name));
  }

  get filteredSearchResult(): RouteAuthorizedPoint[] {
    if (!this.searchControl.value || !this.searchControl.value.trim()) return this.searchResult;
    return this.searchResult.filter(point => {
      return point &&
        (point.details && point.details.name && point.details.name.toLowerCase().includes(this.searchControl.value.toLowerCase())) ||
        (point.city && point.city.name && point.city.name.toLowerCase().includes(this.searchControl.value.toLowerCase()));
    });
  }

  get hasEditPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.routes.module, this.permission.routes.editRouteItinerary);
  }
  get hasCreateItineraryPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.routes.module, this.permission.routes.createRouteItinerary);
  }
  get hasCreateSitesPermission(): boolean {
    return this.permissionRole.hasPermission(this.permission.routes.module, this.permission.routes.createRouteSites);
  }

}
