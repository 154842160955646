import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicGuide } from 'src/app/core/interfaces/basicGuide';
import { Contact } from 'src/app/core/interfaces/formContact';
import { TrackGuide } from 'src/app/core/interfaces/trackGuide';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TrackGuideService {

    constructor(
        private _http: HttpClient,
        private _endpointResources: Endpoints,
        private _globalResources: Global
    ) { }

    public getDetailTrackGuide(guideId: string) {
        return this._http.get<TrackGuide>(
            environment.urlServerTeclogi + this._endpointResources.trackguide + guideId
        );
    }

    public getDetailGuide(guideId: string): Observable<BasicGuide> {
        return this._http.get<BasicGuide>(
            environment.urlServerTeclogi + this._endpointResources.getBasicGuide + guideId
        )
    }



}
