export class Endpoints {
  public urlCreateCargo = "cargo";
  public urlCreateRequest = "cargo/request";
  public updateCargo = "cargo/update";
  public updateRequest = "cargo/request/update";
  public urlCargoList = "cargo?";
  public urlCargoListTravelExpenses = "cargo/travel-expenses/filter";
  public urlEnturnamiento = "cargo/takeTurn";
  public urlCargoListToPay = "cargo/list/toPay?";
  public urlCargoListNegotiation = "cargo/negotiation/";
  public urlCargoRequestListNegotiation = "cargo/negotiation/company/list";
  public urlAcceptCargo = "cargo/negotiate?action=Accepted";
  public urlRefuseCargo = "cargo/negotiate?action=Refuse";
  public urlNegotiateCargo = "cargo/negotiate?action=Negotiate";
  public urlNegotiateRequestSubCompany = "cargo/negotiate/company?action=";
  public getVehicleNegotiations = "cargo/negotiations/vehicles";
  public negotiationsVehiclesOffers = "cargo/negotiations/vehicles/offers/";
  public urlCancelCargo = "cargo/cancel";
  public urlRemoveCargo = "cargo/delete?cargoId=";
  public urlValidateRequestCargo = "cargo/request/validate?id=";
  public sendReport = "cargo/send/reports/";
  public billXML = "cargo/rndc/bill";
  public registerPaymentCargo = "cargo/payments/register";
  public deletePaymentCargo = "cargo/payment?"
  public shippingCostModify = "cargo/shippingCost/modify";
  public createModify = "catalog/modification";
  public approvedCargo = "cargo/approval?";
  public cashedCargo = "cargo/cashed";
  public updatecargoFeature = "cargo/cargoFeature/update/";
  public deletedAdditionalCosts = "cargo/additionalcost/deleted";
  public getFileForPayments = "cargo/payments/generate?paymentType=";
  public cargoPDF = "cargo/createPDF";
  public cargoPublishZone = "cargo/zone";
  public rebuildManifest = "cargo/manifest";
  public confirmDriver = "cargo/approval/vehicle?";
  public inspection = "vehicle/inspection";
  public stateVehicle = "vehicle/state";
  public changeCompany = "cargo/changeCompany?";
  public cargoByConsecutive = "cargo?consecutive=";
  public registerLocation = "cargo/register/locationPoint/";
  // public rebuildMinistry = 'cargo/ministry';
  public approvePayBalance = "cargo/update/approveBalance";
  public updateLoadDownload = "cargo/uploadDownload/update/";
  public createRoundedCargo = "cargo/round";
  public getRouteGoogleCargo = "route/google?cargoId=";
  public saveRouteGoogleCargo = "route/saveRouteGoogle";
  public uploadRoute = "route/cargo/upload";
  public updateWeightConsignment = 'cargo/consignment/modify';

  public addObservation = "cargo/observation/add/";

  public thirdParties = "company/thirdParties";
  public thirdPartiesAddress = "company/thirdParties/address";
  public thirdPartiesUpdate = "company/thirdParties/update";

  public createVehicleDriverOwner = "user/validate";
  public validateUser = "user/validateUser";
  public checkValidationUser = "user/check/validate?document=";
  public checkValidationIndividual = "user/validateUser/individual?forceCreation=";
  public checkValidationVehicle = "vehicle/check?";
  public urlDetailCompany = "company/";
  public urlDetailUserByDocument = "user/";
  public urlDetailUserClientByDocument = "user/client?document=";
  public configUser = "user/config?email=";
  public getUserPhone = "user/getUserPhone?phone=";
  public urlDetailUserByEmail = "user/getUserEmail?email=";
  public userUploadDocument = "user/upload/document";
  public userActivateAffiliation = "user/activate/affiliation";
  public updateDocumentsUser = "user/documents";
  public updateContactsUser = "user/contacts";
  public getUserAdditionalCertifications = "additional-certifications";
  public urlUpdateCompany = "company/update/set";
  public urlSetDaysDeactivateVehicle = "company/update/configuration/days";
  public urlSetResume = "company/update/configuration/cvsecurity";
  public updateCompany = "company/update";
  public urlCreateCompany = "company";
  public urlCreateUser = "user";
  public urlCreateUserClient = "user/client"
  public urlRateCargo = "cargo/score?idCargo=";
  public urlDetailCargo = "cargo/";
  public urlDetailCargoByConsecutive = "cargo/consecutive/"
  public urlImageAddressCargo = "cargo/tracking/images?cargoId=";
  public urlExtraImageAddressCargo = "cargo/tracking/extraImages";
  public urlSetStateAddressTracking = "cargo/tracking?action=";
  public urlUpdateAdrressTracking = "cargo/tracking/images/set";
  public urlGetTracking = "cargo/tracking/"
  public urlUpdateUser = "user/updateUser";
  public urlUpdateUId = "user/updateUId";
  public urlUserClientUpdateTerms = "terms/accept"
  public getAllCompanies = "company/companies";
  public getAllTypesGps = "catalog/Gps";
  public getAllTypesGpsLower = "catalog/gps";
  public getGPSProviders = "catalog/gps/providers";
  public createGps = "catalog/create?tableName=Gps";
  public getAllCompaniesChildrens = "company/subCompanies?nit=";
  public deleteCompany = "company";
  public addEmailCompany = "catalog/file?";
  public addEmailToCompany = "company/emailRecipient";
  public createRole = "company/rol/create";
  public updateRole = "company/rol/update";
  public getHistoryCompanies = "company/request/total?";
  public getReportHistoryCompanies = "company/reports/service?";
  public updateAlertsAndBlocks = "company/documents/mandatory/update";
  public updateChargeOwnFleet = "company/update/configuration/charge-own-fleet/";
  public getTrailerList = "vehicle/trailer";
  public updateTrailer = "vehicle/trailer/change?licensePlate=";
  public urlCatalog = "catalog";
  public urlCatalogTrailerConfig = "catalog/trailer/configurations";
  public companyAdditionalCost = "company/additionalCost";
  public urlWhoToPays = "catalog/whoToPay";
  public labelTags = "catalog/private/CargoLabel";
  public addlabelTag = "catalog/private/CargoLabel";
  public updateCargoLabels = "cargo/update/labels?cargoId=";
  public editCargoConsignment = "cargo/consignment/update";
  public compliments = "company/fulfillment/requirement?";
  public getComplimentTypesCatalog = "catalog/fulfillment/requirement";
  public addPrivateComplimentType = "catalog/private/FulfillmentRequirementTypes";
  public vehicleConfiguration = "vehicle/configuration";
  public cargoCompliments = "cargo/fulfillment/requirement";
  public createTrailer = "vehicle/trailer";
  public listTrailer = "vehicle/trailer?";
  public vehicleChangeDriver = "vehicle/change/driver";
  public updateDocumentsVehicle = "vehicle/documents";
  public updateGpsVehicle = "vehicle/gps";
  public bodyWorkType = "BodyWorkType";
  public cargoType = "CargoType";
  public methodPayment = "MethodPayment";
  public containerMeasure = "ContainerMeasure";
  public regimeType = "RegimeType";
  public serviceType = "ServiceType";
  public tripType = "TripType";
  public vehicleType = "vehicleType";
  public vehicleTypeCreation = "VehicleType";
  public disableVehicle = "vehicle/deactivate/";
  public searchMerchandise = "cargo/commodity/search?textSearch=";
  public getCargoInvoicePDF = "cargo/invoice/pdf";
  public associateCostCenter = "company/costcenter/";
  public companyTaxes = 'company/siigo/taxes';
  public companyProductSiigo = 'company/siigo/products';

  public approvedBelowSiceTac = "cargo/approve/below/sicetac?idCargo=";

  public requestAdvance = "cargo/payment/advance/request";
  // public guideState = 'GuideState';

  public calculateDistance = "distance";

  public getGuides = "guide/getGuides?";
  public getGuideFalabella = "guide/getGuides?guideId=";
  public setGuideStateFalabella = "guide/changeGuideState";
  public uploadSimpleGuides = "guide/uploadSimpleGuides";
  public uploadGuides = "guide/uploadGuides";
  public getGuidePDF = "guide/createPDF?guideId=";
  public getMultipleGuidesPDF = "guide/creatPDFMultipleGuides";
  public getMultipleGuides = "guide/multiple";

  // Endpoints Collection Points
  public collectionPointList = "guide/collectionPoint";
  public collectionPointCreate = "guide/collectionPoint/create";
  public collectionPointUpdate = "guide/collectionPoint/update";
  public collectionPointDelete = "guide/collectionPoint/delete";

  // Endpoints Admin Users
  public usersList = "user/list";
  public usersClientList = "user/client";
  public getPDFTruoraUser = "user/pdf?document=";
  public activateUserClient = "user/role/assign?";
  public activateUser = "user/activate/";
  public forceActivationDriver = "user/activate/score?";
  public deactivateUser = "user/deactivate/";
  public deactivateUserClient = "user/client/deactivate/";

  public urlformContact = "email/formContact";
  public roleDetail = "company/rol?rolId=";
  public rolesCompant = "company/rol/list?";

  // Endpoints segimineto de guias
  public trackguide = "guide/getGuideWithHistory?guideId=";
  public getBasicGuide = "guide/external?guideId=";

  // Files
  public exportMainBases = "guide/generate?type=";
  public companyFile = "company/file/register";
  public getCompanyFile = "company/file/list";

  // Reportes
  public getReports = "cargo/reports?";

  public excelToGuide = "guide/excelToGuide";
  public guideStates = "guide/states";
  public massCreationCargo = "cargo/cargoMultiple";

  // Terms and Conditions
  public terms = "terms";
  public updateTermsUser = "user/updateTerms";
  public termsActive = "terms?state=true";
  public getAllPolicies = `terms?state=true&types=["TermsAndConditions","PrivacyPolicies","PersonalDataPolicy"]`;

  // notificaciones
  public getNotifications = "notification";
  public getNotificationCatalog = "catalog/notifications";
  public rolNotification = "roles/notification";

  // Vehicles
  public getVehiclesById = "vehicle?id=";
  public getVehicles = "vehicle?";
  public getVehicle = "vehicle/";
  public updateVehicleFields = "vehicle/update"
  public registerBanckAccount = "vehicle/bankAccount/register";
  public registerBank = "vehicle/bankAccount/registerBank?";
  public approveDocumentsVehicle = "vehicle/bankAccount/approval?";
  public updateAdministrator = "vehicle/administrator/update/";
  public updateGps = "vehicle/gps/";
  public getVehicleWeightById = "catalog/vehicle/class?id=";
  public getVehicleInspectionPDF = "vehicle/PDF?";
  public getInspectionQuestions = "catalog/inspection-questions"
  public approveVehicle = "vehicle/activate?type=thirty&licensePlate=";
  public approveVehicleChecklist =
    "vehicle/activate?type=checkList&licensePlate=";
  public vehicleDocumentTypes = "catalog/ImageType";
  public vehicleDocuments = "vehicle/images";
  /* /inspection/approval?approval=true */

  //catalog
  public getBanck = "catalog/banks";
  public catalogEmailType = "catalog/emailType";

  //help-desk
  public helpDesk = "helpDesk";

  //builling
  public billingRegister = "cargo/billing/register";

  //modules
  public modules = "user/modules";

  //functionalities
  public functionalities = "user/functionality";
  public functionalitiesByIdModule = "user/functionality/list?";

  //city
  public cities = "catalog/cities";
  public urlUpdatecities = "/update/ica";
  public urlUpdateIndependentCity = "catalog/cities/update"
  public updateMainCity = "catalog/cities/update/subcities";

  //city assignica
  public assignica = "catalog/cities/assignica";

  //subcity
  public subcity = "catalog/cities/subcities/";
  public catalogBodywork = "catalog/bodywork";

  //cargo reteica
  public cargoReteica = "cargo/changeReteIca?cityId=";

  //cargo location vehicle
  public getTripAnomaly = "catalog/private/TripAnomaly";
  public createTripAnomaly = "cargo/tripAnomaly?cargoId=";
  public updateTripAnomaly = "cargo/update/locationPoint/"
  // Error RNDC
  public fixOwnerMinistry = "user/owner/ministry";
  public fixVehicleMinistry = "vehicle/ministry";
  public fixTrailerVehicleMinistry = "vehicle/trailer/ministry";
  public fixUserMinistry = "user/ministry";

  // Cost Centers
  public costCenters = "company/costCenter";
  //Payment types
  public paymentTypes = "company/paymentTypes";

  // Additional Cost
  public addAdditionalcost = "cargo/additionalcost/add";
  // Edit toPay and/or observation in additional services
  public editAdditionalCost = "cargo/additionalcost/modify/extraInformation?";
  // Modify bonus and discounts in additional services
  public modifyValuesAdditionalCost = "cargo/additionalcost/modify?";
  // Delete additional cost
  public deleteAdditionalcost = "cargo/additionalcost/delete?";
  // Additional Cost Update
  public updateAdditionalcost = "cargo/update/additionalCosts";

  // Advance percentage
  public modifyAdvancePercentaje = "cargo/update/advance/percentage/";
  public downloadCargoTraceability = "cargo/traceability/";

  // SIG
  public companyDocuments: string = "company/documents";

  public deleteGPS = "/delete?name=";
  //fast payment
  public fastPayment = "cargo/fast/payment/"

  //delete turn
  public deleteTurn = "cargo/takeTurn/delete?"
  public editTurn = "cargo/takeTurn/update"
  //Cartera
  public urlReceivables = "cargo/receivable?"
  public urlReceivablesCreation = "cargo/receivable/create"
  public urlReceivableApproval = "cargo/receivable/approval?"
  public deleteReceivable = "cargo/receivable?id="
  //MONITORING ENDPOINTS
  public logs = "catalog/logs";
  public fingerPrints = "server/requests";

  //RESTART LOAD
  public restartLoad = "cargo/restore?"
  // Planes de ruta
  public listRoutes = 'routeplan';
  public updateCity = 'catalog/cities/update';

  public AuthorizedStopPointsTypes = 'AuthorizedStopPoints';

  public createAuthorizedPoint = 'routeplan/point/authorized';
  public createAuthorizedPointBatch = 'routeplan/point/authorized/batch';
  public updateAuthorizedPoint = 'routeplan/point/authorized/update';
  public getAuthorizedPoints = 'routeplan/point/authorized';
  public activeAuthorizedPoint = 'routeplan/point/authorized/active';
  public deactiveAuthorizedPoint = 'routeplan/point/authorized/deactive';

  public createControlPoint = 'routeplan/point/control';
  public createControlPoints = 'routeplan/point/control/batch';
  public updateControlPoint = 'routeplan/point/control/update';
  public deactivateControlPoint = 'routeplan/point/control/deactive';
  public activateControlPoint = 'routeplan/point/control/activate';

  public createRouteItinerary = 'routeplan/itinerary/create';
  public routeItinerary = 'routeplan/itinerary';
  public routeItinerarySelect = 'routeplan/itinerary/select';
  public setCargoPlanItinerary = "cargo/route-plan";
  public cargoJournalPdf = 'cargo/journal/pdf';
  public cargoGenerate = 'cargo/generate/';
  public createThirdPartyBank = 'vehicle/bankAccount/thirdParty';
  public generateAccountingFiles = 'accounting/files';
  public accountingTypes = 'accounting/types';

  // Términos, condiciones y políticas
  public requestTermsAndPoliciesAcceptance = 'terms/request';
  public getReportPDF = 'cargo/reports/PDF';

  //Drivin
  public getSchemes = 'drivin/schema';
  public getStoredFleets = 'drivin/vehicle/fleet';
  public updateFleet = 'drivin/vehicle?fleetName='
  public capacities = 'drivin/capacityMeasure';
  public scenarios = 'drivin/scenario';
  public getClientsFromExcel = 'drivin/order/excel';
  public optimizeScenario = 'drivin/scenario/';
  public getRouteByScenario = 'drivin/scenarioRoute?scenarioToken=';
  public drivinCreateCargos = 'drivin/createCargo/list';

  public monitorState = 'company/gps/monitor';

  public initialFulfillConsignments = 'cargo/consignment/initialFulfill';
  public registerVehicleInMonitor = 'gps/monitorhub/vehicle';

  public cargoMoveAdvance = 'cargo/payment/advance/transfer';
  public travelExpenses = 'cargo/travel-expenses';
  public travelExpensesDetail = 'travel-expenses-detail';

  public validationRules = 'catalog/validation-rule';
  // Authentication
  public validateToken = "auth/validate-token"
  public customToken = "auth/custom-token"

  //Service request
  public urlCargoServiceRequests = "cargo-requests"
  public urlCargoServiceRequestsDetail = "cargo-requests/consecutive"

  // Maintenance
  public vehicleTypeCatalog = "catalog/VehicleType"
  public maintenance = "vehicle/maintenance"
  public maintenanceRecords = "catalog/MaintenanceRecords"
  public typeMaintenances = "catalog/TypeMaintenance"

  //Second driver
  public assignChangeSecondDriver = "cargo/%s/second-driver"
  public activateSecondDriver = "cargo/%s/second-driver/activate"

  // saas config
  public getSaasConfig = "company/saas/%s/settings"

  public updateShippingCost = "cargo/%s/shipping-cost"

  // Cost center actions
  public individualCostCenter = "company/%s/cost-center"
  public assignCostCenterToService = "cargo/%s/cost-center"
}
