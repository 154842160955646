<div class="modal-body wrapper-modal position-relative">
    <!--Step 1 - Date Load -->
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" style="top: 0.5em"
        (click)="dialogRef.close()"></i>
    <h4 class="mt-3 mb-5 px-4 text-bold text-center">Nueva ruta</h4>
    <div class="px-1 px-sm-4">
        <app-autocomplete-city [options]="originOptions" [inputFormControl]="originControl" [validate]="validate">
        </app-autocomplete-city>
        <app-autocomplete-city [options]="destinationOptions" [inputFormControl]="destinationControl"
            [validate]="validate">
        </app-autocomplete-city>
        <div class="button-container my-3">
            <button mat-raised-button color="primary" class="w-100 bg-color-primary" (click)="createRoute()">
                Crear ruta
            </button>
        </div>
    </div>
</div>