<div class="main_guide_detail position-relative h-100">
  <i *ngIf="isDialog" matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions"
    (click)="dialogRef.close()"></i>

  <div class="track-guide-aplication-detail overflow-scroll">
    <div class="pl-2 pl-md-4 m-3 mx-md-5">
      <div class="row">
        <label class="label-field-2 text-break"> Número de Guía {{guide.guideId}} </label>
      </div>
      <div class="row mb-3">
        <div class="status">
          <div class="flex-left">
            <img src="/assets/svg/icons/icon-barcode-report.svg" alt="...">
            <div style="margin-left: 5px;">
              <div class="status-title">Estado actual</div>
              <div class="status-detail">{{getTitleAlias(guide.state)}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="guide.cargoConsecutive">
        <div class="textbold">
          <i class="fas fa-chevron-right"></i>
          No. de servicio
        </div>
        <div class="text">{{guide.cargoConsecutive}}</div>
      </div>
      <div class="row" *ngIf="guide.creationDate">
        <div class="textbold">
          <i class="fas fa-calendar-week"></i>
          Guía generada
        </div>
        <div class="text">{{guide.creationDate | dateFormat: 'only-date'}}</div>
      </div>
      <div class="row" *ngIf="guide.deadline && !guide.deliveryDate">
        <div class="textbold">
          <i class="fas fa-calendar"></i>
          Entrega estimada
        </div>
        <div class="text">{{guide.deadline}}</div>
      </div>
      <div class="row" *ngIf="guide.deliveryDate">
        <div class="textbold">
          <i class="fas fa-calendar-check"></i>
          Fecha de entrega
        </div>
        <div class="text">{{guide.deliveryDate | dateFormat: 'only-date'}}</div>
      </div>

      <div class="row summary-top">

        <div class="col-12 col-lg-6">
          <div class="summary-title">Origen</div>
          <div class="summary-info">{{guide.originName}}</div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="summary-title">Destino</div>
          <div class="summary-info">
            {{guide?.recipient?.city ? guide?.recipient?.city : guide?.destinationName? guide.destinationName : '-'}}
          </div>
        </div>
      </div>
      <div class="mt-3" *ngIf="guide?.sender?.nameCompany">
        <div class="subtitle p-2 pl-4">Datos del remitente</div>
      </div>
      <div class="container">
        <div class="customer-data">
          <div class="user-info">
            <div class="row" *ngIf="guide.sender?.nameCompany">
              <div class="textbold">
                <i mat-list-icon class="fas fa-user"></i>
                Nombre
              </div>
              <div class="text">{{guide.sender?.nameCompany}}</div>
            </div>
            <div class="row" *ngIf="guide.sender?.phone">
              <div class="textbold">
                <i mat-list-icon class="fas fa-phone-alt"></i>
                Celular
              </div>
              <div class="text">{{guide.sender?.phone}} </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" *ngIf="guide?.recipient?.address">
        <div class="subtitle p-2 pl-4">Datos del destinatario</div>
      </div>
      <div class="container">
        <div class="customer-data">
          <div class="user-info">
            <div class="row" *ngIf="guide.recipient?.name">
              <div class="textbold">
                <i mat-list-icon class="fas fa-user"></i>
                Nombre
              </div>
              <div class="text">{{guide.recipient?.name}}</div>
            </div>

            <div class="row" *ngIf="guide.recipient?.address">
              <div class="textbold">
                <i mat-list-icon class="fas fa-map-marker-alt"></i>
                Dirección
              </div>
              <div class="text">{{guide.recipient?.address}}</div>
            </div>

            <div class="row" *ngIf="guide.recipient?.phone">
              <div class="textbold">
                <i mat-list-icon class="fas fa-phone-alt"></i>
                Celular
              </div>
              <div class="text">{{guide.recipient?.phone}} </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3" *ngIf="guide?.stateHistoryGuide?.length">
        <div class="subtitle p-2 pl-4">Historial de la orden</div>
      </div>
      <div class="mt-5 mx-2 mx-md-5">
        <div class="d-flex flex-row align-items-stretch" *ngFor="let history of guide.stateHistoryGuide">
          <div class="caja">
            <div class="textH">{{history.date| dateFormat:'day'}}</div>
            <div class="textH4">
              {{(history.date | dateFormat:'month-sbt-3')}}
              {{history.date | dateFormat:'year'}}</div>
          </div>
          <div class="pl-2">
            <div class="textH2">{{getTitleAlias(history.name)}}</div>
            <div class="textH3">{{history.date | dateFormat:'time'}}</div>
          </div>
        </div>
      </div>

    </div>
  </div>



</div>