<section class="w-100 main-container complete-height" id="section-complete-height">
    <div class="map-container">
        <app-routes-map #map [show-options]="false" class="google-map"></app-routes-map>
    </div>
    <aside class="tools-container" [ngClass]="{'h-fixed': mode.includes('point')}">
        <div class="tools-header">
            <a (click)="goBack()" role="button" class="cursor-pointer align-self-start">
                <i class="fas fa-arrow-left"></i>
            </a>
            <p class="text-bold m-0 text-center">{{currentTitle}}</p>
            <div class="d-flex flex-wrap justify-content-around align-items-center mt-3" *ngIf="mode.includes('point')">
                <div (click)="siteButton(option.value)" class="authorized-point"
                    [ngClass]="{'selected': mode === option.value}" *ngFor="let option of pointsOptions">
                    <img [src]="'/assets/svg/icons/' + option.icon" alt="" class="mr-2">
                    <small>{{option.name}}</small>
                </div>
            </div>
        </div>
        <div class="tools-body h-75 flex-fill d-flex flex-column">
            <div class="flex-fill h-75">
                <div *ngIf="mode === 'RUTE'" class="d-flex flex-column h-100 overflow-y">
                    <div class="route-info mx-4 mt-4">
                        <div class="d-flex align-items-center bb-gray mb-2 pb-2">
                            <i class="fas fa-map-marker-alt mr-2"></i>
                            <div class="d-flex flex-column">
                                <small>De</small>
                                <b class="text-bold"> {{originCity?.name ? originCity?.name : '...'}}</b>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <i class="fas fa-map-marker-alt mr-2"></i>
                            <div class="d-flex flex-column">
                                <small>A</small>
                                <b class="text-bold"> {{destinationCity?.name ? destinationCity?.name : '...'}}</b>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap bt-purple pt-2">
                            <b class="mr-2">
                                <span class="text-bold">Distancia: </span>
                                <span>{{bestItinerary?.estimatedDistance / 1000 | number:'1.1-1'}} km</span>
                            </b>
                            <b>
                                <span class="text-bold">Duración: </span>
                                <span>{{bestItinerary?.estimatedTime ? getDurationLabel(bestItinerary?.estimatedTime) :
                                    '...'}}</span>
                            </b>
                        </div>
                    </div>
                    <h5 class="text-bold m-3 mt-5">
                        Itinerarios
                    </h5>
                    <div class="itinerary-card-container">
                        <div class="itinerary-card" *ngFor="let itinerary of sortedItineraries; let i = index"
                            [ngClass]="{'active': itinerary.active, 'inactive': !itinerary.active}">
                            <h6 class="text-bold">{{itinerary.name}}</h6>
                            <small *ngIf="itinerary.code" class="mb-0">
                                <b>Código: </b> {{itinerary.code}}
                            </small>
                            <div class="d-flex flex-wrap">
                                <small class="mr-2">
                                    <b>Distancia: </b> {{itinerary.estimatedDistance / 1000 | number:'1.1-1'}} km
                                </small>
                                <small>
                                    <b>Duración: </b> {{getDurationLabel(itinerary.estimatedTime)}}
                                </small>
                            </div>
                            <small>
                                <span *ngIf="itinerary.active">Habilitado</span>
                                <span *ngIf="!itinerary.active">Deshabilitado</span>
                            </small>
                            <i class="fas fa-chevron-right cursor-pointer"
                                (click)="setCurrentItinerary(itinerary.id)"></i>
                        </div>
                    </div>
                </div>
                <div *ngIf="mode == 'itinerary'" class="d-flex flex-column h-100 overflow-y">
                    <label class="custom-slide-toggle text-bold d-flex justify-content-between mt-3 px-3">
                        <span class="custom-slide-toggle__toggle-label">Itinerario {{itineraryActive ? 'habilitado' :
                            'deshabilitado'}}</span>
                        <input type="checkbox" [(ngModel)]="itineraryActive" [ngModelOptions]="{standalone: true}"
                            [disabled]="!hasEditPermission">
                        <span class="slider"></span>
                    </label>
                    <div cdkDropList (cdkDropListDropped)="drop($event)">
                        <div class="route-info m-3 py-1">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-map-marker-alt mr-2"></i>
                                <div class="d-flex flex-column">
                                    <small>De</small>
                                    <b class="text-bold"> {{originCity?.name}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="directions-container">
                            <div *ngFor="let location of locations; let i = index" cdkDrag class="fs-09 ml-6 mr-3 my-1">
                                <app-complete-address [title]="'Punto de control #' + (i+1)" [icon]="i === 0 ? '../../../../../assets/svg/icons/tl_ico__pin_google_maps_origen.svg' :
                        '../../../../../assets/svg/icons/tl_ico__pin_google_maps_destino.svg'"
                                    [actionIcon]="'../../../../../assets/svg/icons/tl_ico__x.svg'"
                                    [draggable]="hasEditPermission" (remove)="deleteLocation(i)"
                                    (location)="onLocation($event, i)" [control]="locationNames[i]"
                                    [deleteButton]="hasEditPermission">
                                    <img src="../../../../assets/svg/icons/tl_ico__draggable.svg" cdkDragHandle
                                        class="position-absolute" height="100%">
                                </app-complete-address>
                            </div>
                            <div (click)="appendLocation()" *ngIf="hasEditPermission"
                                class="cursor-pointer font-secondary-dark mr-3 ml-6 my-1 fs-09">
                                <i class="fas fa-plus-circle mr-2"></i>
                                <b class="text-bold">Agregar punto de control</b>
                            </div>
                        </div>
                        <div class="route-info m-3 py-1">
                            <div class="d-flex align-items-center">
                                <i class="fas fa-map-marker-alt mr-2"></i>
                                <div class="d-flex flex-column">
                                    <small>A</small>
                                    <b class="text-bold"> {{destinationCity?.name}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-form-field appearance="outline" class="fs-09 px-3">
                        <mat-label>Nombre del itinerario<span class="optional"> (Opcional)</span></mat-label>
                        <input type="text" matInput name="itineraryTitle" [(ngModel)]="itineraryTitle"
                            [ngModelOptions]="{standalone: true}" [disabled]="!hasEditPermission">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="fs-09 px-3">
                        <mat-label>Código<span class="optional"> (Opcional)</span></mat-label>
                        <input type="text" matInput name="itineraryCode" [(ngModel)]="itineraryCode"
                            [ngModelOptions]="{standalone: true}" [disabled]="!hasEditPermission">
                    </mat-form-field>
                    <div class="itinerary-card cursor-pointer" (click)="siteButton('point1')">
                        <h6 class="text-bold my-2">Puntos autorizados</h6>
                        <i class="fas fa-chevron-right"></i>
                    </div>
                </div>
                <div *ngIf="mode.includes('point')" class=" d-flex flex-column h-100">
                    <mat-form-field appearance="outline" class="px-3 mt-3 fs-09">
                        <mat-label>
                            Buscar {{
                            mode == 'point1' ? 'hoteles' :
                            mode == 'point2' ? 'gasolinería' :
                            mode == 'point3' ? 'parqueaderos' :
                            mode == 'point4' ? 'restaurantes' : 'puntos autorizados'
                            }} o ciudades
                        </mat-label>
                        <input type="text" matInput [formControl]="searchControl">
                    </mat-form-field>
                    <div class="h-100 d-flex flex-column overflow-y" *ngIf="filteredSearchResult?.length">
                        <div class="d-flex justify-content-between w-100 search-result mb-1"
                            *ngFor="let result of filteredSearchResult; let i = index;">
                            <div class="w-100 site-name pl-0">
                                <b>{{result?.details?.name}}</b><br>
                                <small>{{result.address | titlecase}}</small><br>
                                <small>{{result?.city?.name | titlecase}}</small><br>
                            </div>
                            <div class="d-flex align-items-center">
                                <a role="button" (click)="addSite(result)"
                                    *ngIf="!sites || !sites[result.id] && hasEditPermission">
                                    <b class="text-success"><i class="fas fa-plus-circle"></i></b>
                                </a>
                                <a role="button" (click)="removeSite(result)"
                                    *ngIf="sites && sites[result.id] && hasEditPermission">
                                    <b class="text-danger"><i class="fas fa-times-circle"></i></b>
                                </a>
                                <a role="button" *ngIf="!hasEditPermission && sites && sites[result.id]">
                                    <b class="text-success"><i class="fas fa-check-circle"></i></b>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="h-100 text-center d-flex justify-content-center flex-column align-items-center"
                        *ngIf="!filteredSearchResult?.length">
                        <img src="../../../../assets/svg/icons/tl_ico__caja_vacia.svg">
                        <small class="w-100 mt-3">
                            No se encontraron resultados
                        </small>
                    </div>
                </div>
            </div>
            <div class="actions px-3 my-3">
                <button *ngIf="mode === 'itinerary' && !currentItinerary?.id" mat-raised-button class="bg-color-primary"
                    color="primary" (click)="createItinerary()">
                    Crear itinerario
                </button>
                <button *ngIf="mode === 'itinerary' && currentItinerary?.id && hasEditPermission" mat-raised-button
                    class="bg-color-primary" color="primary" (click)="saveFullItinerary()">
                    Guardar cambios
                </button>
                <button *ngIf="mode.includes('point') && hasCreateSitesPermission" mat-raised-button
                    class="bg-color-primary" color="primary" (click)="createAuthorizedPoint()">
                    Crear punto
                </button>
                <div *ngIf="mode === 'RUTE' && hasCreateItineraryPermission" (click)="siteButton('itinerary')"
                    class="d-flex justify-content-center cursor-pointer font-secondary-dark text-bold">
                    <i class="fas fa-plus-circle mr-2"></i>
                    Agregar Itinerario
                </div>
            </div>
        </div>
    </aside>
</section>