import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { PlanningRoute } from 'src/app/core/interfaces/planning-route';
import { Utils } from 'src/app/core/resources/utils';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { RoutePlanFormComponent } from '../route-plan-form/route-plan-form.component';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Permission } from 'src/app/core/resources/permission';


@Component({
  selector: 'planning-route-app-list',
  templateUrl: './planning-route-list.component.html',
  styleUrls: ['./planning-route-list.component.scss']
})
export class PlanningRouteListComponent implements OnInit {
  public sort_order = 0; //0 Normal, 1 ToLow, 2 ToUp
  public sort_key: 'origin' | 'destination' = 'origin';
  permission = Permission;

  originControl: FormControl = new FormControl('');
  originSub: Subscription;
  originOptions: OptionsAutocomplete = { title: "Ciudad de origen", appearance: "outline", showXMark: true }
  originCity = null;

  destinationControl: FormControl = new FormControl('');
  destinationSub: Subscription;
  destinationOptions: OptionsAutocomplete = { title: "Ciudad de destino", appearance: "outline", showXMark: true }
  destinationCity = null;

  routes: PlanningRoute[] = [];
  original_routes: PlanningRoute[] = [];
  loading = false;

  constructor(
    private _router: Router,
    private _utils: Utils,
    private spinner: NgxSpinnerService,
    private planningRouteService: PlanningRouteService,
    private matDialog: MatDialog
  ) {
    this.filter();
  }



  ngOnInit() {
    this.setSubscription();
  }

  setSubscription() {
    this.originSub = this.originControl.valueChanges.subscribe(city => {
      if ((!city || city === "clean") && this.originCity) {
        this.originCity = null;
        this.filter();
      } else if (city && city.name && (!this.originCity || this.originCity !== city.name)) {
        this.originCity = city.name;
        this.filter();
      }
    })
    this.destinationSub = this.destinationControl.valueChanges.subscribe(city => {
      if ((!city || city === "clean") && this.destinationCity) {
        this.destinationCity = null;
        this.filter();
      } else if (city && city.name && (!this.destinationCity || this.destinationCity !== city.name)) {
        this.destinationCity = city.name;
        this.filter();
      }
    })
  }


  sort(key: 'origin' | 'destination') {
    const getField = (route) => {
      return route[key] && route[key].name ? route[key].name : null;
    }
    const unsorted_routes = this._utils.clone(this.original_routes);
    if (this.sort_key === key)
      this.sort_order = (this.sort_order + 1) % 3;
    else this.sort_order = 1;
    this.sort_key = key;

    switch (this.sort_order) {
      case 0:
        this.routes = unsorted_routes;
        break;
      case 1:
        this.routes = unsorted_routes.sort((a, b) => {
          const va = getField(a);
          const vb = getField(b);
          if (!isNaN(va) && !isNaN(vb))
            return vb - va;
          return `${vb}`.localeCompare(`${va}`);
        });
        break;
      case 2:
        this.routes = unsorted_routes.sort((a, b) => {
          const va = getField(a);
          const vb = getField(b);
          if (!isNaN(va) && !isNaN(vb))
            return va - vb;
          return `${va}`.localeCompare(`${vb}`);
        });
        break;
      default:
        throw new Error('Sort overflow');
    }
  }

  goToForm(id: string) {
    this._router.navigate(['/routes', 'planning', 'form', id]);
  }

  createNewRoute() {
    const config = new MatDialogConfig();
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.EXTRA_SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.matDialog.open(RoutePlanFormComponent, config).afterClosed().subscribe(result => {
      if (result && result.state) this.filter();
    });
  }

  /*remove(id?: string) {
    if (id) {
      alert('Desactivando ruta: ' + id);
    }
  }*/

  filter() {
    this.loading = true;
    this.original_routes = [];
    this.routes = this.original_routes;
    this.planningRouteService.getRoutes(this.originCity, this.destinationCity).subscribe(
      (routes: PlanningRoute[]) => {
        if (routes && routes.length)
          this.original_routes = this.appendCurrentItinerary(routes);
        else
          this.original_routes = [];
        this.routes = this.original_routes;
        this.sort_order = 0;
        this.sort_key = 'origin';
        this.loading = false;

      },
      () => {
        this.sort_order = 0;
        this.sort_key = 'origin';
        this.loading = false;
      }
    )
  }

  appendCurrentItinerary(routes: PlanningRoute[]): PlanningRoute[] {
    for (const i in routes) {
      if (routes[i].itineraries.length) {
        if (routes[i].itineraries.some(itinerary => itinerary.active))
          routes[i].currentItinerary = routes[i].itineraries.findIndex(itinerary => itinerary.active);
        else
          routes[i].currentItinerary = 0;
      }
    }
    return routes;
  }

  public getDurationLabel(seconds: number): string {
    const durationMsg = [];
    const duration = DateManager.durationFormat(seconds, "seconds");
    if (duration.years > 0) durationMsg.push(`${duration.years} ${duration.years > 1 ? 'años' : 'año'}`);
    if (duration.months > 0) durationMsg.push(`${duration.months} ${duration.months > 1 ? 'meses' : 'mes'}`);
    if (duration.days > 0) durationMsg.push(`${duration.days} d`);
    if (duration.hours > 0) durationMsg.push(`${duration.hours} h`);
    if (duration.minutes > 0) durationMsg.push(`${duration.minutes} min`);

    return durationMsg.join(' ');
  }

  ngOnDestroy() {
    if (this.originSub) this.originSub.unsubscribe();
    if (this.destinationSub) this.destinationSub.unsubscribe();
  }
}
