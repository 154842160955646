export enum StatusSecurityValidationType {
    //User
    EPS = 'EPS',
    ARL = 'ARL',
    DRIVING_LICENSES = 'drivingLicenses',
    MANIFEST = 'manifest',
    //Vehicle
    SOAT = 'SOAT',
    RTM = 'RTM',
}

export enum StatusSecurityValidationStatus {
    FOUND = 'found',
    NOT_FOUND = 'not_found',
    NOT_FOUND_TABLES = 'not_found_tables',
    WRONG = 'wrong',
}

export interface StatusSecurityValidation {
    databaseName: string;
    databaseSet: string;
    status: StatusSecurityValidationStatus;
    type: StatusSecurityValidationType;
}